import { useContext } from 'react';
import { CardCreationContext } from '../context/CardCreationContext';

export const useCardCreationContext = () => {
  const context = useContext(CardCreationContext);

  if (!context) throw new Error('Provide card creation context');

  return context;
};
