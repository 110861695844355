import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonType,
  Checkbox,
  IconComponent,
  Separator,
  Toolbar,
  ToolbarTitle,
  Tooltip,
  icons,
} from 'stc-ui-kit';
import { useCallback, useMemo, useRef, useState } from 'react';
import { EntryTab, SortOrder } from '@/types/ui.types';
import { observer } from 'mobx-react';
import { useDataStore } from '@/Providers/StoreProvider';
import { useCardCreationContext } from '../../../hooks/useCardCreationContext';
import { cardsService } from '@/services/cards.service';
import { DocumentScanner } from '@mui/icons-material';
import { getFileGroupByTab } from '../../../utils/getFileGroupByTab';
import { DownloadButton } from './DownloadButton';
import { getFileFormatsByTab } from '../../../utils/getFileFormatsByTab';
import { BackdropLoader } from '@/components/loaders/BackdropLoader';
import { ShowFileInfoButton } from './ShowFileInfoButton';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';
import { showInfoNotification } from '@/utils/notifications';

export const ModelsHeader = observer(() => {
  const { t } = useTranslation();
  const { cardData, deleteFiles } = useCardCreationContext();
  const cardId = cardData?.id || 0;
  const { uiStore } = useDataStore();
  const { havePermissions } = useUserPermissionsContext();
  const { uploadButton, recognizeButton, downloadButton, deleteButton } =
    permissionControl.enrollment.cardCreation.modelsSection;
  const [isFilesSending, setIsFilesSending] = useState(false);

  const openedCard = uiStore.getOpenedCard(cardId);
  const tab =
    (openedCard?.activeTab === 'biometricModels'
      ? openedCard?.activeSideMenuItem
      : openedCard?.activeTab) || 'face';
  const sortOrder = openedCard?.sortOrder.get(tab) || 'DESC';
  const tabEntities = openedCard?.entities.get(tab) || [];
  const selectedEntitiesIds = openedCard?.selectedEntities.get(tab) || [];
  const selectedCount = selectedEntitiesIds.length || 0;
  const selectedEntities = tabEntities.filter((t) => selectedEntitiesIds.includes(t.id));
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onAddFileClick = () => {
    if (!fileInputRef.current) return;
    fileInputRef.current?.click();
  };

  const onFileChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (!files || files.length === 0 || !cardData) return;

      setIsFilesSending(true);

      const responses = await Promise.all(
        [...files].map((file) =>
          cardsService.createMediaFileProcesses({
            cardId: cardData.id,
            fileGroup: getFileGroupByTab(tab),
            file,
          })
        )
      );

      const hasError = responses.some(
        (res) => !!res.errorDescription?.description || !!res.errorDescription?.code
      );

      if (!hasError) {
        let notificationHeader = t('messages.fileUploaded');
        if (tab === 'face') {
          notificationHeader = t('messages.photoUploaded');
        }
        if (tab === 'voice') {
          notificationHeader = t('messages.audioUploaded');
        }

        showInfoNotification({ header: notificationHeader });
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      setIsFilesSending(false);
    },
    [cardData, tab, t]
  );

  const isAddFileDisabled = useMemo(
    () => !cardData?.id || isFilesSending,
    [cardData?.id, isFilesSending]
  );

  const handleCheckboxChange = () => {
    selectedCount > 0
      ? uiStore.setSelectedEntities(cardId, tab, [])
      : uiStore.setSelectedEntities(
          cardId,
          tab,
          tabEntities.map((t) => t.id)
        );
  };

  const handleSortOrderChange = (val: SortOrder) => {
    uiStore.setSortOrder(cardId, tab, val);
  };

  const uploadIconByTab: Record<EntryTab, IconComponent> = {
    face: icons.IconAddPhoto,
    voice: icons.IconAddAudio,
    documents: icons.IconReportAdd,
    attachedFiles: icons.IconReportAdd,
  };

  const titleByTab: Record<EntryTab, string> = {
    face: t('cards.face'),
    voice: t('cards.voice'),
    documents: t('cards.documents'),
    attachedFiles: t('attachedFiles'),
  };

  return (
    <>
      <Toolbar data-testid=''>
        <ToolbarTitle>{titleByTab[tab]}</ToolbarTitle>
        <Separator vertical maxSize />
        {havePermissions(uploadButton) && (
          <>
            <div>
              <Button icon={uploadIconByTab[tab]} onClick={onAddFileClick}>
                {t('upload')}
              </Button>
              <input
                ref={fileInputRef}
                type='file'
                onChange={onFileChange}
                accept={getFileFormatsByTab(tab)}
                multiple
                disabled={isAddFileDisabled}
                style={{ display: 'none' }}
              />
            </div>

            <Separator vertical />
          </>
        )}

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {tab === 'face' && (
            <span style={{ paddingRight: '7px' }}>
              <Checkbox
                data-testid=''
                onChange={handleCheckboxChange}
                isChecked={!!selectedCount}
              />
            </span>
          )}

          <span style={{ whiteSpace: 'nowrap' }}>{`${t('selected')}: ${selectedCount}/${
            tabEntities.length
          }`}</span>
        </div>

        <Separator vertical />

        {(tab === 'face' || tab === 'documents') && havePermissions(recognizeButton) && (
          <Button icon={() => <DocumentScanner />} isDisabled type={ButtonType.Icon} tooltip={''} />
        )}

        {havePermissions(downloadButton) && <DownloadButton selectedEntities={selectedEntities} />}

        {havePermissions(deleteButton) && (
          <Button
            icon={icons.IconDelete}
            onClick={() => deleteFiles()}
            isDisabled={!selectedCount}
            type={ButtonType.Icon}
            tooltip={t('delete')}
          />
        )}

        <Separator vertical />

        <Tooltip tooltip={t('gridTable.sortDesc')}>
          <Button
            icon={icons.IconSortUp}
            type={ButtonType.Icon}
            onClick={() => handleSortOrderChange('DESC')}
            isActive={sortOrder === 'DESC'}
          />
        </Tooltip>

        <Tooltip tooltip={t('gridTable.sortAsc')}>
          <Button
            icon={icons.IconSortDown}
            type={ButtonType.Icon}
            onClick={() => handleSortOrderChange('ASC')}
            isActive={sortOrder === 'ASC'}
          />
        </Tooltip>

        {(tab === 'face' || tab === 'voice') && (
          <ShowFileInfoButton
            tab={tab}
            fileId={selectedEntitiesIds[0]}
            cardId={cardId}
            selectedCount={selectedCount}
            modelId={tab === 'face' ? cardData?.faceModel?.id : cardData?.voiceModel?.id}
          />
        )}
      </Toolbar>
      <BackdropLoader open={isFilesSending} />
    </>
  );
});
