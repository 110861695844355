import { ICreateRoleDto, IPrivilegeSection, IRoleWithPrivs } from '@/types/role';
import rootApiService from './root.api.service';
import { CallAPIType, METHODS } from '@/types';
import { showInfoNotification } from '@/utils/notifications';
import { t } from 'i18next';
class RolesService {
  getRoles = async () => {
    const APIOptions: CallAPIType = {
      path: 'user/roles',
      method: METHODS.GET,
    };

    const apiResponse = await rootApiService.callAPI<IRoleWithPrivs[]>(APIOptions);
    const res = apiResponse.res;
    if (res) {
      return res;
    } else {
      return [];
    }
  };

  getModules = async () => {
    const APIOptions: CallAPIType = {
      path: 'user/roles/modules',
      method: METHODS.GET,
    };

    const apiResponse = await rootApiService.callAPI<IPrivilegeSection[]>(APIOptions);
    const res = apiResponse.res;
    if (res) {
      return res;
    } else {
      return [];
    }
  };

  createRole = async (role: ICreateRoleDto) => {
    const APIOptions: CallAPIType = {
      path: 'user/roles/create',
      method: METHODS.POST,
      body: role,
    };

    const apiResponse = await rootApiService.callAPI<IRoleWithPrivs>(APIOptions);

    if (apiResponse.res) {
      showInfoNotification({ header: t('messages.roleCreatedSuccessful') });
    }

    return apiResponse.res;
  };

  updateRole = async (role: IRoleWithPrivs) => {
    const APIOptions: CallAPIType = {
      path: 'user/roles/update',
      method: METHODS.PUT,
      body: role,
    };

    const apiResponse = await rootApiService.callAPI<IRoleWithPrivs>(APIOptions);

    if (apiResponse.res) {
      showInfoNotification({ header: t('messages.roleUpdatedSuccessful') });
    }

    return apiResponse.res;
  };

  deleteRole = async (roleId: number) => {
    const APIOptions: CallAPIType = {
      path: `user/roles/delete?role_id=${roleId}`,
      method: METHODS.PUT,
    };

    await rootApiService.callAPI(APIOptions);
  };
}

export default new RolesService();
