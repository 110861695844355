import { createSvgIcon } from '@mui/material';

export const AdministrationRolesIcon = createSvgIcon(
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      // eslint-disable-next-line max-len
      d='M7 0C5.89543 0 5 0.895431 5 2V6C5 7.10457 5.89543 8 7 8H9V11H1V12V13V16H0V20H4V16H3V13H9V16H8V20H12V16H11V13H17V16H16V20H20V16H19V13V12V11H11V8H13C14.1046 8 15 7.10457 15 6V2C15 0.895431 14.1046 0 13 0H7Z'
    />
  </svg>,
  'administration_roles'
);
