import { Box, Stack, IconButton } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonType,
  Counter,
  Icon,
  SelectedRowsCounter,
  Separator,
  Tooltip,
  icons,
} from 'stc-ui-kit';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { FileDialog } from '@/components/FileDialog/FileDialog';
import { GridStore } from '@/stores/grid.store';
import { ROUTES } from '@/types';
import { ICardsGridItem } from '@/types/card';
import { observer } from 'mobx-react';
import { DeleteCardsButton } from './DeleteCardsButton';
import { ExportCardsButton } from './ExportCardsButton';
import { FillingCabinetSearchInput } from './SearchInput';
import { cardsService } from '@/services/cards.service';
import { useNavigate } from 'react-router-dom';
import { createCardEditLink } from '@/modules/multipass/utils/cardCreationLinks';
import { GalleryIcon } from '@/icons/GalleryIcon';
import { Void } from '@/components/common/Void';
import { OpenSideFilterButton } from '@/modules/multipass/FilingCabinet/components/HeaderFilingCabinet/OpenSideFilterButton';
import { HeaderQuickFilters } from './HeaderQuickFilters';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';

interface Props {
  store: GridStore<ICardsGridItem>;
}

export const HeaderFilingCabinet: FC<Props> = observer(({ store }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { havePermissions } = useUserPermissionsContext();
  const { createEntryButton, addButton, deleteButton, exportButton } =
    permissionControl.enrollment.fillingCabinet;

  const count = store.data.length;
  const selectedCount = store.checkedItems.length;

  const createEntry = async () => {
    const { res } = await cardsService.createCard({
      entryTemplateId: null,
      data: {},
      fileProcessId: null,
      modelId: null,
    });

    if (res) {
      navigate(createCardEditLink({ id: res.id, from: ROUTES.MULTIPASS_FILLING_CABINET }));
    }
  };

  return (
    <Stack direction='column' flex='1'>
      <Stack
        direction='row'
        alignItems='center'
        display='flex'
        paddingRight='16px'
        gap='16px'
        flex={1}
      >
        <Box>
          <span>{t('cards.title')}</span>
          <Counter count={count} data-testid='' />
        </Box>

        <FillingCabinetSearchInput />

        <Separator vertical />

        <SelectedRowsCounter rowsCount={selectedCount} />

        <Separator vertical />

        {havePermissions(addButton) && (
          <FileDialog isDisabled onFileChoosen={(params) => console.log(params)} />
        )}

        <Separator vertical />

        {havePermissions(createEntryButton) && (
          <Button type={ButtonType.Text} onClick={createEntry} icon={icons.IconSummaryAdd}>
            {t('multipass.createEntry')}
          </Button>
        )}

        <Separator vertical />

        <IconButton disabled>
          <Tooltip tooltip={t('gallery')}>
            <GalleryIcon
              sx={{
                mt: '6px',
                'svg path': {
                  fill: `${appColors.darkLightGrey} !important`,
                },
              }}
            />
          </Tooltip>
        </IconButton>

        <Tooltip tooltip={t('edit')}>
          <Button
            onClick={() =>
              navigate(
                createCardEditLink({
                  id: store.checkedItems.map((card) => card.id)[0],
                  from: ROUTES.MULTIPASS_FILLING_CABINET,
                })
              )
            }
            type={ButtonType.Icon}
            icon={icons.IconEdit}
            isDisabled={store.checkedItems.length !== 1}
          />
        </Tooltip>

        {havePermissions(exportButton) && <ExportCardsButton store={store} />}

        {havePermissions(deleteButton) && <DeleteCardsButton store={store} />}

        <Separator vertical />

        <Tooltip tooltip={t('list')}>
          <Button type={ButtonType.Icon} icon={icons.IconRows} isDisabled />
        </Tooltip>

        <Tooltip tooltip={t('tile')}>
          <Button type={ButtonType.Icon} icon={icons.IconLayout} isDisabled />
        </Tooltip>

        <Tooltip tooltip={t('refresh')}>
          <Icon icon={icons.IconReload} isClickable onClick={() => store.reload()} />
        </Tooltip>
      </Stack>

      <Box marginLeft={'-16px'}>
        <Separator horizontal />
      </Box>

      <Stack display='flex' alignItems='center' flexDirection='row' gap='16px'>
        <HeaderQuickFilters />

        <Void />

        <OpenSideFilterButton />
      </Stack>
    </Stack>
  );
});
