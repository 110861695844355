import { CheckboxGroupField } from 'stc-ui-kit';
import { cardExportStatuses, exportStatusId } from '../constants';
import { t } from 'i18next';

export function renderStatusFilterField() {
  const options = cardExportStatuses.map((status) => ({
    id: status,
    label: t(`cards.exportStatus.${status}`),
  }));

  return (
    <CheckboxGroupField
      data-testid=''
      id={exportStatusId}
      items={options}
      label={t('multipass.status')}
    />
  );
}
