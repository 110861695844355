import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { borderColors } from '@/styles/theme/colors';

export interface IHeaderTitleProps {
  text: string;
}

export const HeaderTitle: FC<IHeaderTitleProps> = (props) => {
  const { text } = props;

  return (
    <>
      <Box
        bgcolor='white'
        display="flex"
        alignItems='center'
        height={'48px'}
        padding={2}
        borderBottom={1}
        borderColor={borderColors['--steal-200']}
      >
        <Typography
          variant="subtitle2"
          display="block"
          sx={{
            marginLeft: '4px',
            marginRight: '16px'
          }}
        >
          {text}
        </Typography>
      </Box>
    </>
  );
};