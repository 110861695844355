import { ReactComponent as AddToProcessingSvg } from '@/assets/icons/add-to-processing.svg';
import { ReactComponent as DictorSpeechBadSvg } from '@/assets/icons/dictor-speech-bad.svg';
import { ReactComponent as DictorSpeechGoodSvg } from '@/assets/icons/dictor-speech-good.svg';
import { ReactComponent as DictorSpeechNormalSvg } from '@/assets/icons/dictor-speech-normal.svg';
import { ReactComponent as RemoveFromProcessingSvg } from '@/assets/icons/remove-from-processing.svg';
import React, { useCallback, useMemo } from 'react';

import { SvgIcon } from '@/components/SvgIcon/SvgIcon';
import { filesStore } from '@/modules/multipass/stores/Files';
import { ModelQuality, DictorStatus, IDictor } from '@/types/dictor';
import { IFileModelView } from '@/types/file';
import { formatMsToTime } from '@/utils/date/formatMsToTime';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { ResourceKey } from 'i18next';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styles from './DictorCard.module.scss';
import { createCardCreationLink } from '@/modules/multipass/utils/cardCreationLinks';
import { getModelQuality } from '@/modules/multipass/utils/getModelQuality';

interface Props {
  fileId: number;
  info: IFileModelView<IDictor>;
  isSelected: boolean;
  onSelect: () => void;
}

const dictorSpeechIcons: Record<
  ModelQuality,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >
> = {
  GOOD: DictorSpeechGoodSvg,
  NORMAL: DictorSpeechNormalSvg,
  BAD: DictorSpeechBadSvg,
};

const statusToToken: Record<DictorStatus, ResourceKey> = {
  entryCreated: 'multipass.entryCreated',
  excluded: 'multipass.excluded',
  new: 'new',
};

export const DictorInfoCard: React.FC<Props> = observer(
  ({ info, isSelected, onSelect, fileId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onExclude = useCallback(async () => {
      try {
        await filesStore.updateDictorExclude(info.id, !info.isExcluded);
      } catch (error) {
        console.error(error);
      }
    }, [info.id, info.isExcluded]);

    const status: DictorStatus = useMemo(
      () => (info.isExcluded ? 'excluded' : +info.cardsCount === 0 ? 'new' : 'entryCreated'),
      [info.cardsCount, info.isExcluded]
    );

    const onCreateEntryClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      navigate(
        createCardCreationLink({
          dictor: info.data,
          modelId: info.id,
          fileProcessId: fileId,
        })
      );
    };

    return (
      <Box
        className={clsx(styles.wrapper, {
          [styles.wrapperSelected]: isSelected,
        })}
        onClick={onSelect}
        sx={{ opacity: info.isExcluded ? '0.6' : undefined }}
      >
        <Box className={styles.header}>
          <Box display='flex' alignItems='center'>
            <SvgIcon
              IconComponent={dictorSpeechIcons[getModelQuality(info.modelQuality)]}
              color='white'
            />
            <span className={styles.dictorName}>
              {t('speaker')} {info.data.speakerNumber}
            </span>
          </Box>
          <Box sx={{ cursor: 'pointer' }}>
            <Tooltip title={t(info.isExcluded ? 'include' : 'exclude')}>
              <IconButton onClick={onExclude}>
                <SvgIcon
                  IconComponent={!info.isExcluded ? RemoveFromProcessingSvg : AddToProcessingSvg}
                  color='bgPrimary'
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box flex={1}>
          <Box className={styles.speechDuration}>
            {t('multipass.pureSpeechDuration')}:{' '}
            <span>
              {info.type === 'audio' && formatMsToTime(Math.ceil(info.data.speechTimeSec) * 1000)}
            </span>
          </Box>
        </Box>
        <Box
          display='flex'
          justifyContent={status !== 'new' ? 'flex-end' : 'space-between'}
          alignItems='center'
        >
          {status === 'new' && (
            <Button variant='text' onClick={onCreateEntryClick}>
              {t('multipass.createEntry')}
            </Button>
          )}
          <span className={styles.cardCreationInfo}>{t(statusToToken[status])}</span>
        </Box>
      </Box>
    );
  }
);
