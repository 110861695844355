import { convertDateToShort } from '@/hooks/DateConverter';
import { ECardExportStatus, ICard } from '@/types/card';
import { CardTemplate } from '@/types/cardTemplate';
import { getCardAliasFromTemplate } from './getCardAliasFromTemplate';
import { t } from 'i18next';

export const getCardAliasWithCreationDate = (
  cardData: ICard | null,
  template: CardTemplate | null | undefined
) => {
  if (!template || !cardData) return '';

  const createdDate = convertDateToShort(cardData?.createdOnDateTime);
  const aliasPattern = template.data?.alias?.pattern;

  if (!aliasPattern) return '';

  const cardAlias = getCardAliasFromTemplate(template, cardData);

  const getText = () => {
    if (!createdDate) {
      return t('multipass.newEntry');
    }

    if (cardData.exportStatus === ECardExportStatus.NEW) {
      return `${t('multipass.newEntryFrom')} ${createdDate}`;
    }

    return `${cardAlias} ${t('multipass.from')} ${createdDate}`;
  };

  return getText();
};
