import { useDataStore } from '@/Providers/StoreProvider';
import { makeStyles } from '@/hooks/makeStyles';
import { CardPhoto } from '@/types/card';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType, Checkbox, ContextMenu, ListItemType, ListItems } from 'stc-ui-kit';
import { useCardCreationContext } from '../../../hooks/useCardCreationContext';
import { Box } from '@mui/material';
import { ModelQualityIconComponent } from '@/modules/multipass/components/icons/ModelQualityIconComponent';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { ReactComponent as ZoomImageIcon } from 'assets/icons/zoomImage.svg';
import { SvgIcon as CustomSvgIcon } from 'components/SvgIcon/SvgIcon';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';
import { useImageErrorPlaceholder } from '@/hooks/useImageErrorPlaceholder';

const useStyles = makeStyles()(() => ({
  zoomImageBtn: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px',
  },
}));

export const PhotoWrapper = observer(
  ({
    photo,
    onFullScreenOpen,
    index,
    isMain = false,
  }: {
    photo: CardPhoto;
    onFullScreenOpen: (index: number) => void;
    index: number;
    isMain?: boolean;
  }) => {
    const { uiStore } = useDataStore();
    const { havePermissions } = useUserPermissionsContext();
    const { setAsMainButton, createNewEntryButton, deleteButton } =
      permissionControl.enrollment.cardCreation.modelsSection;
    const { classes } = useStyles();
    const { t } = useTranslation();
    const { cardData, setCardModel, deleteFiles, createNewEntry } = useCardCreationContext();
    const { onError } = useImageErrorPlaceholder();

    const cardId = cardData?.id || 0;
    const openedCard = uiStore.getOpenedCard(cardId);
    const selectedPhotos = openedCard?.selectedEntities.get('face') || [];
    const isChecked = selectedPhotos.includes(photo.id) || false;
    const mainPhotoQuality = isMain
      ? photo.faceData?.find((d) => d.modelId === cardData?.faceModel?.id)?.quality
      : null;

    const handleCheckboxChange = () => {
      const updatedEntities = isChecked
        ? selectedPhotos.filter((id) => id !== photo.id)
        : [...selectedPhotos, photo.id];
      uiStore.setSelectedEntities(cardId, 'face', updatedEntities);
    };

    const handleFullScreen = () => {
      onFullScreenOpen(index);
    };

    const handleCreateNewEntry = () => {
      createNewEntry({
        modelId: photo.faceData[0].modelId,
        fileProcessId: photo.fileProcessId,
      });
    };

    const contextMenuItems: ListItems = [
      [
        {
          id: 'setAsMain',
          name: t('multipass.setAsMain'),
          onClick: () => setCardModel(photo.faceData[0].modelId),
          type: ListItemType.Simple,
          isDisabled: isMain || photo.faceData.length !== 1 || !havePermissions(setAsMainButton),
        },
        {
          id: 'createNewEntry',
          name: t('multipass.createNewEntry'),
          onClick: handleCreateNewEntry,
          type: ListItemType.Simple,
          isDisabled: photo.faceData.length !== 1 || !havePermissions(createNewEntryButton),
        },
        {
          id: 'openFullSize',
          name: t('multipass.openFullSize'),
          onClick: handleFullScreen,
          type: ListItemType.Simple,
        },
        // скрыто временно
        // {
        //   id: 'openInNewTab',
        //   name: t('multipass.openInNewTab'),
        //   onClick: () => console.log('click'),
        //   type: ListItemType.Simple,
        // },
        {
          id: 'delete',
          name: t('delete'),
          onClick: () => {
            void deleteFiles([photo.id], photo.fileGroup);
          },
          type: ListItemType.Simple,
          isDisabled: !havePermissions(deleteButton),
        },
      ],
    ];

    return (
      <ContextMenu items={contextMenuItems}>
        <Box
          position='relative'
          width={'240px'}
          height={'240px'}
          onDoubleClick={handleFullScreen}
          sx={{ background: appColors.black }}
        >
          <Box
            position='absolute'
            top='0'
            padding='8px'
            display='flex'
            justifyContent='space-between'
            width='100%'
            zIndex='3'
          >
            <Checkbox isChecked={isChecked} onChange={handleCheckboxChange} data-testid='' />
            <Box padding='8px'>
              {photo.faceData?.length === 1 ? (
                <ModelQualityIconComponent quality={photo.faceData?.[0]?.quality} type='img' />
              ) : (
                mainPhotoQuality && (
                  <ModelQualityIconComponent quality={mainPhotoQuality} type='img' />
                )
              )}
            </Box>
          </Box>

          <img
            src={photo.path}
            alt=''
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
              border: isMain
                ? `4px solid ${appColors.bgPrimary}`
                : `1px solid ${appColors.steal200}`,
              borderRadius: '4px',
            }}
            onError={onError}
          />

          <Button
            icon={() => <CustomSvgIcon IconComponent={ZoomImageIcon} color={appColors.grey800} />}
            className={classes.zoomImageBtn}
            type={ButtonType.Icon}
            onClick={handleFullScreen}
          />
        </Box>
      </ContextMenu>
    );
  }
);
