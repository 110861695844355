import { useCallback, useEffect, useState } from 'react';
import { CardFormData, ICard, ICardsGridItem } from '@/types/card';
import { cardsService } from '@/services/cards.service';
import { Box, Stack, Typography } from '@mui/material';
import { CardCreationContextProvider } from '../../context/CardCreationContext';
import { CardTemplate } from '@/types/cardTemplate';
import { FormReturn, Icon, icons, IconSize, useForm } from 'stc-ui-kit';
import { useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';
import { CardQuickViewContent } from './CardQuickViewContent';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { GridStore } from '@/stores/grid.store';
import { observer } from 'mobx-react';
import { useSelectionModel } from '@/hooks/grid/useSelectionModel';
import { useDataStore } from '@/Providers/StoreProvider';
import { formatTemplateValues } from '../../utils/formatTemplateValues';
import { useCardSseMessage } from '../../hooks/useCardSseMessage';
import { useCardFieldErrors } from '../../hooks/useCardFieldErrors';

interface Props {
  store: GridStore<ICardsGridItem>;
}

export const CardQuickView: React.FC<Props> = observer(({ store }) => {
  const { t } = useTranslation();
  const cardId = store.checkedItems.length === 1 ? store.checkedItems[0].id : null;
  const [cardData, setCardData] = useState<ICard | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<null | CardTemplate>(null);
  const { showFieldErrorNotification } = useCardFieldErrors({ template: selectedTemplate });
  const [error, setError] = useState('');
  const { templatesStore } = useDataStore();

  const { selectMany } = useSelectionModel(store);

  const reloadGrid = useCallback(async () => {
    const checked = store.checkedItems;
    await store.reload();
    selectMany(checked);
  }, [selectMany, store]);

  const { form } = useForm({
    defaultValues: {},
  });

  const { reset } = form;

  useCardSseMessage({ cardData, setCardData, reset });

  const fetchCardData = useCallback(
    async (keepDirty = false) => {
      if (!cardId) return;

      setLoading(true);
      try {
        const { res: card } = await cardsService.getById(cardId);
        if (!card) {
          setError(t('multipass.errorWhenLoadingCard'));

          return;
        }

        setCardData(card);

        const template = await templatesStore.getTemplateById(card.entryTemplateId);
        if (!template) {
          setError(t('multipass.errorWhenLoadingTemplates'));

          return;
        }

        setSelectedTemplate(template);

        if (!keepDirty) {
          const formattedValues = formatTemplateValues(template.data, card.data);
          reset(formattedValues);
        }
      } catch (error) {
        setError(t('multipass.errorWhenLoadingCard'));
      } finally {
        setLoading(false);
      }
    },
    [cardId, reset, t, templatesStore]
  );

  useEffect(() => {
    void fetchCardData();
  }, [fetchCardData]);

  const refetchCardData = useCallback(
    async (keepDirty = false) => {
      await fetchCardData(keepDirty);
      await reloadGrid();
    },
    [fetchCardData, reloadGrid]
  );

  const onSubmit = async (values: CardFormData) => {
    if (!cardId || !selectedTemplate) return;

    try {
      const { res, errorDescription } = await cardsService.editCard({
        id: cardId,
        entity: {
          entryTemplateId: selectedTemplate.id,
          data: values,
        },
      });
      if (errorDescription) {
        showFieldErrorNotification(errorDescription);
      }
      if (res) {
        setCardData(res);
        reset(formatTemplateValues(selectedTemplate.data, res.data));
        await reloadGrid();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const EmptyPlaceHolder = () => {
    return (
      <Stack
        direction={'column'}
        height={'100%'}
        spacing={2}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Icon icon={icons.IconClick} iconSize={IconSize.Original} />
        <Typography fontSize={'14px'} width={'50%'} textAlign={'center'}>
          {t('multipass.toDisplayEntrySelectOneObject')}
        </Typography>
      </Stack>
    );
  };

  return (
    <CardCreationContextProvider
      formHooks={form as FormReturn<FieldValues>}
      selectedTemplate={selectedTemplate}
      cardData={cardData}
      refetchCardData={refetchCardData}
    >
      <Box
        flexGrow={0}
        flexShrink={0}
        my={2}
        ml={2}
        width={408}
        border={`1px solid ${appColors.darkThemeBorder}`}
        borderRadius={'4px'}
        sx={{ backgroundColor: appColors.darkThemeBackground }}
      >
        {!cardId && <EmptyPlaceHolder />}

        {!!cardId && error && (
          <Typography fontWeight='700' margin='16px' color={appColors.red}>
            {error}
          </Typography>
        )}

        {!!cardId && !error && (
          <CardQuickViewContent loading={loading} form={form} onSubmit={onSubmit} />
        )}
      </Box>
    </CardCreationContextProvider>
  );
});
