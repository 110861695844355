import { Grid } from '@mui/material';

interface InputWrapperProps {
  children: React.ReactNode;
}

export const InputWrapper = ({ children }: InputWrapperProps) => {
  return (
    <Grid item xs={4}>
      {children}
    </Grid>
  );
};
