import { FileInfo } from '@/components/FileInfo/FileInfo';
import { IFileLink, IMediaData } from '@/types/file';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType, icons, Overlay, Separator, Tooltip } from 'stc-ui-kit';
import { useCardCreationContext } from '../../../hooks/useCardCreationContext';
import filesApiService from '@/services/files.service';
import { makeStyles } from '@/hooks/makeStyles';
import { ReactComponent as AudioInfoIcon } from 'assets/icons/audioInfo.svg';
import { SvgIcon } from '@/components/SvgIcon/SvgIcon';

const useStyles = makeStyles()(() => ({
  container: {
    height: '100%',
    marginLeft: 'auto',
  },
}));

export function ShowFileInfoButton({
  tab,
  fileId,
  cardId,
  selectedCount,
  modelId,
}: {
  tab: 'face' | 'voice';
  fileId: number;
  cardId: number;
  selectedCount: number;
  modelId: number | undefined;
}) {
  const [fileInfo, setFileInfo] = useState<IFileLink | null>(null);
  const [additionalAudioInfo, setAdditionalAudioInfo] = useState<IMediaData | null>(null);
  const [isFileInfoOpen, setIsFileInfoOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const { fileLinks } = useCardCreationContext();
  const { classes } = useStyles();

  const fileLink = fileLinks.find((fl) => fl.id === fileId);
  const isModelInFile =
    !!modelId && !!fileLink?.fileModelLinks?.find((fl) => fl.fileModel.id === modelId);

  useEffect(() => {
    setIsFileInfoOpen(false);
  }, [tab]);

  useEffect(() => {
    if (selectedCount !== 1) {
      setIsFileInfoOpen(false);
    }
  }, [selectedCount]);

  useEffect(() => {
    void getFileInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId]);

  const getFileInfo = async () => {
    if (!fileLink) return;

    setFileInfo(fileLink);

    if (tab === 'voice') {
      if (!fileLink.fileModelLinks?.length) return;

      const fileModelId = isModelInFile ? modelId : fileLink.fileModelLinks[0].fileModel.id;

      const audioFileInfo = await filesApiService.getMediaData(fileModelId, fileLink.file?.id);

      setAdditionalAudioInfo(audioFileInfo);
    }
  };

  return (
    <Overlay
      isOpen={isFileInfoOpen}
      isCloseOnClickOutside={false}
      placement='bottom'
      fallbackPlacements={['bottom']}
      data-testid='file-info'
      className={classes.container}
      content={
        <FileInfo
          additionalAudioInfo={additionalAudioInfo}
          cardId={cardId}
          fileInfo={fileInfo}
          setFileInfo={setFileInfo}
          tab={tab}
          onClose={() => setIsFileInfoOpen(false)}
          isModelInFile={isModelInFile}
        />
      }
    >
      <Separator vertical maxSize />

      <Tooltip tooltip={tab === 'face' ? t('multipass.infoPhoto') : t('multipass.infoAudio')}>
        <Button
          onClick={() => setIsFileInfoOpen(true)}
          icon={
            tab === 'face'
              ? icons.IconImageInfo
              : () => (
                  <SvgIcon
                    IconComponent={AudioInfoIcon}
                    color={(() => {
                      if (selectedCount !== 1) return 'hsla(0, 0%, 100%, .322)';
                      if (selectedCount === 1) return 'black';
                    })()}
                    size={{ w: 24, h: 24 }}
                  />
                )
          }
          type={ButtonType.Toggle}
          isDisabled={selectedCount !== 1}
          isActive={selectedCount === 1}
        />
      </Tooltip>
    </Overlay>
  );
}
