import { use, ResourceLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { ru } from './translations/ru';
import { Language } from '@/types';
import { en } from './translations/en';

const resources: Record<Language, ResourceLanguage> = {
  en: { translation: en },
  ru: { translation: ru },
};

void use(initReactI18next).init({
  fallbackLng: 'en',
  resources,
});
