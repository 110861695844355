import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Typography,
  Grid,
  SvgIcon,
  Button,
  CircularProgress,
  Box,
  Stack,
  Tooltip,
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { IStorageFile } from './types';

interface DirectoryProps {
  items: IStorageFile[];
  selectedItems: IStorageFile[];
  onDirectoryDoubleClick: (absPath: string) => void;
  onFileClick: (file: IStorageFile) => void;
  loading: boolean;
}

const Directory: React.FC<DirectoryProps> = ({
  items,
  onDirectoryDoubleClick,
  onFileClick,
  selectedItems,
  loading,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number[]>([]);
  const ref = useRef<HTMLSpanElement>(null);

  const audioFiltred = useMemo(() => {
    // const audioRegex = /\.(mp3|wav|ogg|g729|m4a|bmp|jpeg|jpg|png|pbm,pgm|ppm|sr|ras|tiff|tif)$/i;
    const audioRegex = /\.(mp3|wav|ogg|g729|m4a)$/i;

    return items.filter((file) => file.type === 'DIR' || audioRegex.test(file.fileName));
  }, [items]);

  useEffect(() => {
    setSelectedIndex(selectedItems.map((si) => audioFiltred.indexOf(si)));
  }, [audioFiltred, selectedItems]);

  const onSelectfile = useCallback(
    (item: IStorageFile) => {
      onFileClick(item);
    },
    [onFileClick]
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={3} minHeight={60}>
        {audioFiltred.map((item, index) => (
          <Grid item xs={3} key={item.path}>
            <Tooltip
              title={item.fileName}
              children={
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  height={'70px'}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  onClick={() => onSelectfile(item)}
                  onDoubleClick={() =>
                    item.type === 'DIR' ? onDirectoryDoubleClick(item.path) : undefined
                  }
                  sx={{
                    backgroundColor: selectedIndex.includes(index) ? appColors.steal200 : undefined,
                    '&:hover': {
                      backgroundColor: appColors.steal200,
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Button
                    variant='text'
                    color={item.type === 'DIR' ? 'warning' : 'primary'}
                    sx={{
                      padding: '10px',
                      pointerEvents: 'none',
                    }}
                  >
                    <SvgIcon
                      component={item.type === 'DIR' ? FolderIcon : DescriptionIcon}
                      sx={{ fontSize: 50 }}
                    />
                  </Button>
                  <Typography
                    variant='subtitle1'
                    align='left'
                    textOverflow={'ellipsis'}
                    maxWidth={'120px'}
                    ref={ref}
                    overflow={'hidden'}
                    whiteSpace={'nowrap'}
                    sx={{
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {item.fileName}
                  </Typography>
                </Stack>
              }
            ></Tooltip>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Directory;
