import { useTranslation } from 'react-i18next';
import imageInvalidFormatPlaceholderRu from '@/assets/icons/imageInvalidFormatPlaceholderRu.svg';
import imageInvalidFormatPlaceholderEn from '@/assets/icons/imageInvalidFormatPlaceholderEn.svg';
import imageLoadErrorPlaceholderRu from '@/assets/icons/imageLoadErrorPlaceholderRu.svg';
import imageLoadErrorPlaceholderEn from '@/assets/icons/imageLoadErrorPlaceholderEn.svg';
import { loadingErrorPath } from '@/modules/multipass/constants';

export const useImageErrorPlaceholder = () => {
  const { i18n } = useTranslation();

  const getPlaceholder = (currentSrc: string) => {
    if (currentSrc.includes(loadingErrorPath)) {
      return i18n.language === 'en' ? imageLoadErrorPlaceholderEn : imageLoadErrorPlaceholderRu;
    }

    return i18n.language === 'en'
      ? imageInvalidFormatPlaceholderEn
      : imageInvalidFormatPlaceholderRu;
  };

  const onError: React.ReactEventHandler<HTMLImageElement> = ({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.src = getPlaceholder(currentTarget.src);
    currentTarget.style.objectFit = 'none';
  };

  return { onError };
};
