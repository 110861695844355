import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow, { TableRowProps } from '@mui/material/TableRow';

import { makeStyles } from '@/hooks/makeStyles';

import { DateCell } from '@/components/queryPage/ExportModal/DateCell';
import { observer } from 'mobx-react';
import { DictorCell } from './components/DictorCell';
import { Paper, Tooltip, Typography } from '@mui/material';
import { backgroundColors, textColors } from '@/styles/theme/colors';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { EFileProcessStatus, IFileLink } from '@/types/file';
import { filesStore } from '@/modules/multipass/stores/Files';
import { gridHeaderFontStyles } from '@/components/Grid/StyledGrid';
import { toJS } from 'mobx';
import { appColors } from '@/Providers/ThemeProvider/colors';

type TClasses = Record<
  'tableContainer' | 'tableHead' | 'tableRow' | 'tableCellHead' | 'tableCellBody',
  string
>;

const useStyles = makeStyles()(() => ({
  tableContainer: {
    '&.MuiTableHead-root': {
      minWidth: 629,
      minHeight: 908,
      borderRadius: 4,
      border: 1,
    },
  },
  tableHead: {
    '&.MuiTableHead-root': {
      backgroundColor: '#FFFFFF',
      '& .MuiTypography-root': {
        ...gridHeaderFontStyles,
        paddingTop: '16px',
        paddingBottom: '16px',
      },
    },
  },
  tableRow: {
    borderBottom: `1px solid ${appColors.steal200}`,
    '&.Mui-selected': {
      backgroundColor: `${appColors.steal200}`,
    },
    '&.Mui-selected:hover': {
      backgroundColor: `${appColors.steal200}`,
    },
    '& td': {
      border: 'none',
    },
  },
  tableCellHead: {
    '&.MuiTableCell-root': {
      fontSize: 13,
      fontWeight: 600,
      padding: '8px 16px',
    },
  },
  tableCellBody: {
    '&.MuiTableCell-root': {
      fontSize: 14,
      color: 'inherit',
    },
  },
}));

interface IFixedheader {
  classes: TClasses;
  t: TFunction<'en', undefined>;
}

function FixedHeaderContent({ classes, t }: IFixedheader) {
  return (
    <TableRow>
      <TableCell className={classes.tableCellHead} style={{ width: '200px' }}>
        <Typography variant='subtitle2'>{t('multipass.fileName')}</Typography>
      </TableCell>
      <TableCell className={classes.tableCellHead} style={{ width: '105px' }}>
        <Typography variant='subtitle2'>{t('multipass.createdTime')}</Typography>
      </TableCell>

      <TableCell className={classes.tableCellHead}>
        <Typography variant='subtitle2'>{t('multipass.nameLoad')}</Typography>
      </TableCell>
      <TableCell className={classes.tableCellHead}>
        <Typography variant='subtitle2'>{t('multipass.status')}</Typography>
      </TableCell>
    </TableRow>
  );
}

interface IRowContent {
  _index: number;
  row: IFileLink;
  classes: TClasses;
}

const RowContent = observer(({ _index, row, classes }: IRowContent) => {
  const { listFiles } = filesStore;
  const observableRow = listFiles[_index];
  const fileNameFull = useMemo(
    () => (observableRow ? decodeURIComponent(observableRow.name) : undefined),
    [observableRow]
  );
  const fileNameShortened = useMemo(() => {
    return fileNameFull && fileNameFull.length > 23
      ? `${fileNameFull.slice(0, 23)}…`
      : fileNameFull;
  }, [fileNameFull]);

  if (!observableRow) {
    return null;
  }

  return (
    <>
      <TableCell className={classes.tableCellBody}>
        <Tooltip title={fileNameFull} arrow>
          <Typography variant='body1' gutterBottom color={'inherit'}>
            {fileNameShortened}
          </Typography>
        </Tooltip>
      </TableCell>
      <DateCell dateTime={observableRow.createdTime ?? ''} textColor='inherit' />
      <TableCell className={classes.tableCellBody}>
        <Typography variant='body1' gutterBottom color={'inherit'}>
          {observableRow.creator?.username || ''}
        </Typography>
      </TableCell>
      <TableCell className={classes.tableCellBody}>
        <DictorCell
          status={observableRow.status}
          modelcount={observableRow.modelsCount || 0}
          cardCount={observableRow.cardsCount}
        />
      </TableCell>
    </>
  );
});

const ObservRow = observer(
  ({
    item,
    props,
    classes,
  }: {
    item: IFileLink;
    classes: Record<string, string>;
    props: TableRowProps;
  }) => {
    const { selectedFile, setSelectedFile } = filesStore;
    const { status } = item;

    return (
      <TableRow
        {...props}
        sx={{
          background:
            status === EFileProcessStatus.INPROGRESS ? backgroundColors['--light-grey'] : undefined,
          color: status === EFileProcessStatus.INPROGRESS ? textColors['--light-grey'] : undefined,
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.04)', //'#e5ebf0',
          },
          '&.MuiTableRow-root.Mui-selected:hover': {
            backgroundColor: '#bdc4c9',
          },
        }}
        key={item.id}
        className={classes.tableRow}
        onClick={() => {
          setSelectedFile(item);
        }}
        selected={selectedFile?.id === item.id}
      />
    );
  }
);

const Scroller = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <TableContainer
      component={Paper}
      {...props}
      ref={ref}
      sx={{
        '& thead, th': {
          border: 'none',
          boxShadow: `0px 1px ${appColors.steal200}`,
        },
      }}
    />
  );
});

interface IFilesTable {
  changeRange: (range: number[]) => void;
}

export const FilesTable = observer((props: IFilesTable) => {
  const { changeRange } = props;
  const { t } = useTranslation();

  const { listFiles } = filesStore;
  const { classes } = useStyles();

  const VirtuosoTableComponents: TableComponents<IFileLink> = {
    Scroller,
    Table: (props) => <Table {...props} />,
    // TableHead: (props) => <TableHead {...props} className={classes.tableHead} />,
    TableRow: ({ item: _item, ...props }) => {
      return <ObservRow item={_item} classes={classes} props={props} />;
    },
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };

  return (
    // TODO заменить на таблицу из кита, снести либу react-virtuoso, потому что она больше нигде не используется
    <TableVirtuoso
      rangeChanged={({ startIndex, endIndex }) => {
        const range: number[] = [startIndex, endIndex];
        changeRange(range);
      }}
      data={toJS(listFiles)}
      components={VirtuosoTableComponents}
      fixedHeaderContent={() => <FixedHeaderContent classes={classes} t={t} />}
      itemContent={(_index, row) => <RowContent row={row} _index={_index} classes={classes} />}
    />
  );
});
