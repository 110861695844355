import { setAuthHeader } from '@/services/root.api.service';

export const getFileFromServer = async (
  filePath: string
): Promise<{ file: File; blob: Blob } | null> => {
  const apiCallHeaders = new Headers();
  setAuthHeader(apiCallHeaders);
  try {
    const response = await fetch(filePath, {
      headers: apiCallHeaders,
    });

    if (response.ok) {
      const blob = await response.blob();

      const filename = filePath.substring(filePath.lastIndexOf('/') + 1);

      const file = new File([blob], filename, { type: blob.type });

      return { file, blob };
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error while uploading file from server:', error);

    return null;
  }
};
