import { IDataGridStoreState } from 'types/grid';

import { IPaginator, LOAD_ALL_LIMIT } from './paginator';

import { GridStore } from '@/stores/grid.store';

export class OffsetPaginator<T> implements IPaginator<T> {
  gridStore;

  constructor(gridStore: GridStore<T>) {
    this.gridStore = gridStore;
    this.gridStore.currentPage = Math.floor(
      this.gridStore.state.offset / this.gridStore.state.limit
    );
  }

  setCurrentPage(page: number) {
    this.gridStore.currentPage = page;
    this.gridStore.state.offset = page * this.gridStore.rowsOnPage;
  }

  resetCurrentPage() {
    this.setCurrentPage(0);
  }

  async loadData(filters: IDataGridStoreState<T>) {
    const dataProvider = this.gridStore.dataProvider;

    return await dataProvider(filters);
  }

  async loadAllData() {
    const data = await this.gridStore.dataProvider({
      ...this.gridStore.state,
      offset: 0,
      limit: LOAD_ALL_LIMIT,
    });

    return data.data;
  }

  getLastPage() {
    //
  }

  getFirstPage() {
    this.resetCurrentPage();
    void this.gridStore.reload();
  }

  get hasNextPage() {
    return this.gridStore.hasNextPage;
  }

  get hasPrevPage() {
    return this.gridStore.state.offset !== 0;
  }
}
