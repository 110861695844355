import { CardPhoto, ICard } from '@/types/card';
import { useCallback, useRef, useState } from 'react';
import { getServerMediaFilePath } from '@/services/root.api.service';
import { getFileFromServer } from '@/utils/files/getFileFromServer';
import { fetchCardPhotos } from '../utils/fetchCardPhotos';
import { IFileLink } from '@/types/file';
import { Model } from '@/types/model';
import { cardsService } from '@/services/cards.service';
import { sortObjectsByCreatedTime } from '../utils/sortObjectsByCreatedTime';
import { SortOrder } from '@/types/ui.types';

export const useCardPhotos = ({ cardData }: { cardData: ICard | null }) => {
  const [cardPhotos, setCardPhotos] = useState<CardPhoto[]>([]);
  const [fullScreenPhotos, setFullScreenPhotos] = useState<CardPhoto[]>([]);
  const [isPhotosLoading, setIsPhotosLoading] = useState(false);
  const [additionalPhoto, setAdditionalPhoto] = useState<CardPhoto | null>(null);
  const [additionalPhotoFull, setAdditionalPhotoFull] = useState<CardPhoto | null>(null);

  const mainPhotoModel = useRef<Model | null | undefined>(null);
  mainPhotoModel.current = cardData?.faceModel;

  const fetchAdditionalPhoto = useCallback(
    async (photos: CardPhoto[]) => {
      const additionalPhotoId =
        !photos.some((ph) => ph.isMain) && mainPhotoModel.current
          ? mainPhotoModel.current?.fileModelLinks[0]?.cardMediaFileLinkId
          : null;

      if (!additionalPhotoId) {
        setAdditionalPhoto(null);
        setAdditionalPhotoFull(null);

        return;
      }

      if (cardData?.id) {
        const { res } = await cardsService.getMediaFileProcesses({
          cardId: cardData.id,
          ids: [additionalPhotoId],
        });
        if (res) {
          const photos = await fetchCardPhotos({ imageFiles: res });

          const photoNormalizedUri = photos[0]?.faceData.find(
            (fd) => fd.modelId === mainPhotoModel.current?.id
          )?.normalizedImageUri;
          if (!photoNormalizedUri) return;
          const photoNormalized = await getFileFromServer(
            getServerMediaFilePath(photoNormalizedUri)
          );

          setAdditionalPhoto({
            ...photos[0],
            isMain: true,
            path: photoNormalized?.blob ? URL.createObjectURL(photoNormalized.blob) : '',
          });
          setAdditionalPhotoFull({ ...photos[0], isMain: true });
        }
      }
    },
    [cardData?.id]
  );

  const loadAndProcessPhotos = useCallback(
    async (imageFiles: IFileLink[]): Promise<CardPhoto[]> => {
      setIsPhotosLoading(true);
      const photos = await fetchCardPhotos({ imageFiles });

      if (!mainPhotoModel.current) {
        setCardPhotos(photos);
        setFullScreenPhotos(photos);
        setAdditionalPhoto(null);
        setAdditionalPhotoFull(null);
        setIsPhotosLoading(false);

        return photos;
      }

      const mainPhotoFull = photos.find((p) =>
        p.faceData.some((d) => d.modelId === mainPhotoModel.current?.id)
      );

      if (mainPhotoFull) {
        mainPhotoFull.isMain = true;
      }

      const photosWithoutMain = photos.filter((p) => p !== mainPhotoFull);

      const mainPhotoNormalizedUri = mainPhotoFull?.faceData.find(
        (fd) => fd.modelId === mainPhotoModel.current?.id
      )?.normalizedImageUri;

      if (!mainPhotoNormalizedUri) {
        await fetchAdditionalPhoto(photosWithoutMain);
        setCardPhotos(photosWithoutMain);
        setFullScreenPhotos(photosWithoutMain);
        setIsPhotosLoading(false);

        return photosWithoutMain;
      }

      setAdditionalPhoto(null);
      setAdditionalPhotoFull(null);

      const mainPhotoNormalized = await getFileFromServer(
        getServerMediaFilePath(mainPhotoNormalizedUri)
      );

      const mainPhoto: CardPhoto = {
        ...mainPhotoFull,
        path: mainPhotoNormalized?.blob ? URL.createObjectURL(mainPhotoNormalized.blob) : '',
      };

      const updatedPhotos = [mainPhoto, ...photosWithoutMain];
      setCardPhotos(updatedPhotos);
      setFullScreenPhotos(
        mainPhotoFull ? [mainPhotoFull, ...photosWithoutMain] : photosWithoutMain
      );

      setIsPhotosLoading(false);

      return mainPhotoFull ? [mainPhotoFull, ...photosWithoutMain] : photosWithoutMain;
    },
    [mainPhotoModel, fetchAdditionalPhoto]
  );

  const sortPhotos = (sortOrder: SortOrder) => {
    setCardPhotos((prev) => {
      const hasMain = prev[0]?.isMain;

      return hasMain
        ? [prev[0], ...sortObjectsByCreatedTime(prev.slice(1), sortOrder)]
        : [...sortObjectsByCreatedTime(prev, sortOrder)];
    });

    setFullScreenPhotos((prev) => {
      const hasMain = prev[0]?.isMain;

      return hasMain
        ? [prev[0], ...sortObjectsByCreatedTime(prev.slice(1), sortOrder)]
        : [...sortObjectsByCreatedTime(prev, sortOrder)];
    });
  };

  return {
    photos: additionalPhoto ? [additionalPhoto, ...cardPhotos] : cardPhotos,
    fullScreenPhotos: additionalPhotoFull
      ? [additionalPhotoFull, ...fullScreenPhotos]
      : fullScreenPhotos,
    loadAndProcessPhotos,
    sortPhotos,
    isPhotosLoading,
  };
};
