import { ICard } from '@/types/card';
import { CardTemplate } from '@/types/cardTemplate';

export const getCardAliasFromTemplate = (
  template: CardTemplate | null | undefined,
  cardData: ICard | null
) => {
  if (!template || !cardData) return '';

  const aliasPattern = template.data?.alias?.pattern;

  if (!aliasPattern) return '';

  return aliasPattern.replaceAll(/\{(.*?)\}/g, (_, key) => {
    const cardValue = cardData?.data[key];
    if (typeof cardValue !== 'string' || !cardValue) return '';

    return cardValue;
  });
};
