import rootApiService from './root.api.service';

import {
  CallAPIType,
  ErrorType,
  IAuthProps,
  IRole,
  IUser,
  IUserToBeCreated,
  IUserToBeUpdated,
  IUserToChangePassword,
  METHODS,
  SnackbarActionType,
  TosterOptionsType,
} from '@/types';
import {
  IAuthResponse,
  ICreateUserResponse,
  ILogoutResponse,
  IUserLimitsResponse,
} from '@/apiTypes';
import { RootStore } from '@/Providers/StoreProvider/root.store';
import { ApiError } from '@/utils/ApiError';
import { EntityApi } from './entityApi.service';
import { User } from '@/types/user';
import { DataProvider } from '@/types/grid';
import { showInfoNotification } from '@/utils/notifications/showInfoNotification';
import { t } from 'i18next';

const usersApi = new EntityApi('user');

class UserService {
  logIn = async (rootStore: RootStore, user: IAuthProps): Promise<IUser | ErrorType | null> => {
    const {
      userStore: { setUserData },
      templatesStore: { reloadScheme },
    } = rootStore;
    const { username, password } = user;
    const body = {
      username,
      password,
    };

    const APIOptions: CallAPIType = {
      path: 'user/auth',
      method: METHODS.POST,
      body,
    };

    const apiResponse = await rootApiService.callAPI<IAuthResponse>(APIOptions);
    const res = apiResponse.res;

    if (!res) return apiResponse.errorDescription || null;

    const userData: IUser = {
      id: res.id,
      username: res.username,
      roles: res.roles,
      privileges: res.privileges,
      auth_type: res.auth_type,
      t4f_token: res.t4f_token,
      auth: true,
      newPasswordRequired: res.newPasswordRequired,
      firstname: res.firstname,
      lastname: res.lastname,
      middlename: res.middlename,
    };

    setUserData(userData);
    void reloadScheme();

    return userData;
  };

  logOut = async () => {
    const APIOptions: CallAPIType = {
      path: 'user/logout',
      method: METHODS.POST,
    };

    await rootApiService.callAPI<ILogoutResponse>(APIOptions);
  };

  signUp = async (user: IAuthProps, snackbarAction: SnackbarActionType) => {
    const tosterOptions = {
      snackbarAction,
      message: 'Ошибка создания пользователя:',
    };
    const { username, password } = user;
    const body = {
      username,
      password,
    };

    const APIOptions: CallAPIType = {
      path: 'user',
      tosterOptions,
      method: METHODS.POST,
      body,
    };

    const apiResponse = await rootApiService.callAPI<ICreateUserResponse>(APIOptions);
    const res = apiResponse.res;

    if (res) {
      snackbarAction('Пользователь успешно создан', { variant: 'success' });
    } else {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }
  };

  getUserLimits = async (tosterOptions: TosterOptionsType) => {
    const APIOptions: CallAPIType = {
      path: 'user/limitations',
      tosterOptions: tosterOptions,
      method: METHODS.GET,
    };

    const apiResponse = await rootApiService.callAPI<IUserLimitsResponse>(APIOptions);

    return apiResponse.res;
  };

  getUserCount = async () => {
    const APIOptions: CallAPIType = {
      path: 'user/count',
      method: METHODS.GET,
    };

    const apiResponse = await rootApiService.callAPI<number>(APIOptions);

    return apiResponse.res;
  };

  getAllUsers = async () => {
    return await usersApi.getAllEntities<User>();
  };

  getGroupUsers = async (groupId: number) => {
    const fields = [
      {
        fieldId: 'department',
        targetValues: [groupId],
        predicate: 'EQ',
      },
    ];

    return await rootApiService.callAPI<User[]>({
      path: 'user/filter',
      method: METHODS.POST,
      body: { fields },
    });
  };

  dataProvider: DataProvider<User> = (state, allUsers?: User[]) => {
    return usersApi.getFiltersData({
      filters: state,
      localData: allUsers,
    });
  };

  createUser = async (user: IUserToBeCreated) => {
    const { username, password, firstname, middlename, lastname, description, roles, department } =
      user;

    const body = {
      username,
      password,
      firstname,
      middlename,
      lastname,
      description,
      roles,
      department,
    };

    const APIOptions: CallAPIType = {
      path: 'user',
      method: METHODS.POST,
      body,
    };

    const apiResponse = await rootApiService.callAPI<ICreateUserResponse>(APIOptions);
    const res = apiResponse.res;

    if (!res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    showInfoNotification({ header: t('messages.userCreatedSuccessful') });
  };

  updateUser = async (user: IUserToBeUpdated) => {
    const { id, username, firstname, lastname, middlename, description, roles, department } = user;

    const body = {
      id,
      username,
      firstname,
      lastname,
      middlename: middlename,
      description,
      roles,
      department,
    };

    const APIOptions: CallAPIType = {
      path: 'user/update',
      method: METHODS.PUT,
      body,
    };

    const apiResponse = await rootApiService.callAPI(APIOptions);

    if (apiResponse.statusCode !== 200) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    showInfoNotification({ header: t('messages.userUpdatedSuccessful') });
  };

  enableUser = async (userId: number) => {
    const APIOptions: CallAPIType = {
      path: `user/enable/${userId}`,
      method: METHODS.PUT,
    };

    const apiResponse = await rootApiService.callAPI(APIOptions);

    if (apiResponse.statusCode === 200) {
      showInfoNotification({ header: t('messages.usersUnblocked') });
    } else {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }
  };

  disableUser = async (userId: number) => {
    const APIOptions: CallAPIType = {
      path: `user/disable/${userId}`,
      method: METHODS.PUT,
    };

    const apiResponse = await rootApiService.callAPI(APIOptions);

    if (apiResponse.statusCode === 200) {
      showInfoNotification({ header: t('messages.usersBlocked') });
    } else {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }
  };

  changePassword = async (user: IUserToChangePassword) => {
    const { id, newPassword, repeatPassword } = user;

    const body = {
      id,
      newPassword,
      repeatPassword,
    };

    const APIOptions: CallAPIType = {
      path: 'user/password',
      method: METHODS.PUT,
      body,
    };

    const apiResponse = await rootApiService.callAPI(APIOptions);

    if (apiResponse.statusCode === 200) {
      showInfoNotification({ header: t('messages.userChangePasswordSuccessful') });
    } else {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }
  };

  getRoles = async () => {
    const APIOptions: CallAPIType = {
      path: 'user/roles',
      method: METHODS.GET,
    };

    const apiResponse = await rootApiService.callAPI<IRole[]>(APIOptions);
    const res = apiResponse.res;

    if (res) {
      return res;
    } else {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }
  };

  getBioscannerToken = async () => {
    const APIOptions: CallAPIType = {
      path: 'user/device/token',
      method: METHODS.GET,
    };

    const apiResponse = await rootApiService.callAPI<{ token: string }>(APIOptions);
    const res = apiResponse.res;

    if (res) {
      return res;
    } else {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }
  };

  generateBioscannerToken = async () => {
    const APIOptions: CallAPIType = {
      path: 'user/device/token',
      method: METHODS.POST,
    };

    const apiResponse = await rootApiService.callAPI<{ token: string }>(APIOptions);
    const res = apiResponse.res;

    if (res) {
      return res;
    } else {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }
  };
}

export default new UserService();
