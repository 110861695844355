import { CustomTypeFieldDescriptor, FieldDescriptor } from '@/types/cardTemplate';
import { ConditionTextField } from 'stc-ui-kit';
import { ListStringFieldConditions } from '../constants';

export const renderListStringFilterField = (
  field: FieldDescriptor | CustomTypeFieldDescriptor,
  label: string,
  placeholder: string
) => {
  return (
    <ConditionTextField
      key={field.id}
      id={field.id}
      label={label}
      conditions={ListStringFieldConditions}
      isClearable
      placeholder={placeholder}
      data-testid=''
    />
  );
};
