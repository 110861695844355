import { ErrorType, FormFieldError, Language } from '@/types';
import { CardTemplate } from '@/types/cardTemplate';
import { showErrorNotification } from '@/utils/notifications';
import { useTranslation } from 'react-i18next';

interface UseCardFieldErrorsProps {
  template: CardTemplate | null;
}

export const useCardFieldErrors = ({ template }: UseCardFieldErrorsProps) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as Language;

  const getErrorDetails = (error: ErrorType) => {
    if (!template) return '';
    const details = error.details;
    if (details && typeof details === 'object' && 'errors' in details) {
      const errors = (details as { errors: unknown }).errors;
      if (
        Array.isArray(errors) &&
        errors.every(
          (error) =>
            typeof error === 'object' &&
            error !== null &&
            'key' in error &&
            'errors' in error &&
            typeof (error as FormFieldError).key === 'string' &&
            Array.isArray((error as FormFieldError).errors) &&
            (error as FormFieldError).errors.every((e) => typeof e === 'string')
        )
      ) {
        const result = errors.map((error: FormFieldError) => {
          let fieldData = template.data.fields.find((field) => field.id === error.key);
          if (!fieldData) {
            fieldData = template.data.customTypes
              ?.flatMap((c) => c.fields)
              .find((field) => field.id === error.key.split('.').pop());
          }
          if (!fieldData) return '';
          const name = fieldData.names?.find((n) => n.locale === language)?.value || fieldData.name;
          const description = error.errors
            .map((e) => t(`fieldError.${e}`, 'Unknown error'))
            .join(', ');
          const errorString = t('fieldError.error', { field: name }) + description;

          return errorString;
        });

        return result.join('; ');
      }
    }

    return '';
  };

  const showFieldErrorNotification = (errorDescription: ErrorType) => {
    const message = getErrorDetails(errorDescription);
    showErrorNotification({ header: errorDescription.description, message: message });
  };

  return { showFieldErrorNotification };
};
