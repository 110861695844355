import { FC } from 'react';

import { Box, Typography } from '@mui/material';

export interface IMissingDataProps {
  icon: React.ReactNode;
  title: string;
  text: string;
}

export const MissingData: FC<IMissingDataProps> = (props) => {
  const { icon, title, text } = props;

  return (
    <Box
      width={'auto'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      height={'calc(100% - 48px)'}
      padding={2}
    >
      <Box
        display='flex'
        alignItems='center'
        flexDirection='column'
      >
        {icon}
        <Typography variant='subtitle1' sx={{ lineHeight: '18.75px', fontSize: '16px', fontWeight: '500', marginTop: '16px' }}>
          {title}
        </Typography>
        <Typography variant='subtitle2' sx={{ lineHeight: '18.75px', fontSize: '16px', fontWeight: '400', marginTop: '8px' }}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};