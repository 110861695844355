import { CardPhoto } from '@/types/card';
import { useState, useMemo, useCallback } from 'react';
import { FullScreenPhotoCarousel } from '../components';

type Params = {
  photos: CardPhoto[];
};

export const useFullScreenCardCarousel = (params?: Params) => {
  const { photos } = params || { photos: null };

  const [isOpened, setIsOpened] = useState(false);
  const [slideIndex, setSlideIndex] = useState<number>(0);

  const openFullScreenCarousel = useCallback((selectedSlideIndex?: number) => {
    setIsOpened(true);
    setSlideIndex(selectedSlideIndex || 0);
  }, []);

  const closeFullScreenCarousel = useCallback(() => {
    setIsOpened(false);
    setSlideIndex(0);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [loadedPhotos, setLoadedPhotos] = useState<CardPhoto[]>([]);

  const loadAndShowPhotos = useCallback(
    async ({
      fetcher,
      selectedSlideIndex,
    }: {
      fetcher: () => Promise<CardPhoto[]>;
      selectedSlideIndex?: number;
    }) => {
      try {
        openFullScreenCarousel(selectedSlideIndex);
        setIsLoading(true);
        const res = await fetcher();
        setLoadedPhotos(res);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [openFullScreenCarousel]
  );

  const carouselRender = useMemo(() => {
    if (!isOpened) return null;

    return (
      <FullScreenPhotoCarousel
        handleClose={closeFullScreenCarousel}
        photos={photos || loadedPhotos}
        isLoading={isLoading}
        slideIndex={slideIndex}
      />
    );
  }, [closeFullScreenCarousel, isLoading, isOpened, loadedPhotos, photos, slideIndex]);

  return { carouselRender, openFullScreenCarousel, loadAndShowPhotos, closeFullScreenCarousel };
};
