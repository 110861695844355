import React, { useEffect, useState } from 'react';
import { Box, capitalize, Stack, Dialog } from '@mui/material';

import { makeStyles } from '@/hooks/makeStyles';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { PrivilegeCode } from '@/types/role';
import { useTranslation } from 'react-i18next';
import { EditRolesPrivsAccordion } from './EditRolesPrivsAccordion';
import { prepareRoleName } from '@/utils/prepareRoleName';
import { Button, Separator, TextView } from 'stc-ui-kit';
import { useRolesContext } from '../../context/RolesContext';
import rolesService from '@/services/roles.service';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';

const FOOTER_HEIGHT = 68;

const useStyles = makeStyles()(() => ({
  modalContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '80vw',
    maxWidth: 1600,
    height: '80vh',
    margin: '25px',
    background: appColors.darkThemeBackground,
    borderRadius: '4px',
    overflow: 'auto',
    border: `1px solid ${appColors.darkThemeBorder}`,
  },
  roleListContainer: {
    width: '320px',
    minWidth: '200px',
    overflow: 'auto',
  },
  roleListItem: {
    cursor: 'pointer',
    padding: '4px 16px',
    height: '42px',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px',
    height: `${FOOTER_HEIGHT}px`,
    flexShrink: 0,
  },
  headerText: {
    fontSize: '16px',
    fontWeight: 700,
  },
  simpleText: {
    fontSize: '14px',
    fontWeight: 500,
  },
}));

interface Props {
  isOpened: boolean;
  onClose: () => void;
  initialRoleId: number;
}

export const EditRolesPrivsModal: React.FC<Props> = ({ isOpened, onClose, initialRoleId }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { roles, sections, fetchData } = useRolesContext();
  const { havePermissions } = useUserPermissionsContext();
  const [selectedRoleId, setSelectedRoleId] = useState(initialRoleId);
  const [selectedPrivileges, setSelectedPrivileges] = useState<Set<PrivilegeCode>>(new Set([]));

  useEffect(() => {
    setSelectedPrivileges(new Set(roles.find((r) => r.id === selectedRoleId)?.privileges || []));
  }, [selectedRoleId, roles, sections]);

  const onSubmit = async () => {
    const roleName = roles.find((r) => r.id === selectedRoleId)?.roleName || '';

    const updatedRole = await rolesService.updateRole({
      id: selectedRoleId,
      roleName,
      privileges: [...selectedPrivileges],
    });

    if (updatedRole) {
      await fetchData();
    }

    onClose();
  };

  return (
    <Dialog open={isOpened} onClose={onClose} PaperProps={{ className: classes.modalContent }}>
      <Stack direction={'row'} flexGrow={1} maxHeight={`calc(100% - ${FOOTER_HEIGHT + 1}px)`}>
        <Box className={classes.roleListContainer}>
          <div
            style={{
              height: '56px',
              padding: '12px 16px',
            }}
          >
            <TextView text={capitalize(t('roles'))} className={classes.headerText} data-testid='' />
          </div>

          <Separator horizontal />

          {roles.map((role) => (
            <div
              key={role.id}
              className={classes.roleListItem}
              style={{
                backgroundColor: selectedRoleId === role.id ? appColors.cyan : 'inherit',
              }}
              onClick={() => setSelectedRoleId(role.id)}
            >
              <TextView
                text={prepareRoleName(role.roleName)}
                className={classes.simpleText}
                data-testid=''
              />
            </div>
          ))}
        </Box>
        <Separator vertical maxSize />

        <EditRolesPrivsAccordion
          selectedPrivileges={selectedPrivileges}
          setSelectedPrivileges={setSelectedPrivileges}
          onClose={onClose}
        />
      </Stack>
      <Separator horizontal />

      <Stack spacing={2} direction={'row'} className={classes.footer}>
        {havePermissions(permissionControl.administration.roles.saveRolesManagmentButton) && (
          <Button onClick={onSubmit}>{t('save')}</Button>
        )}
        <Button onClick={onClose}>{t('cancel')}</Button>
      </Stack>
    </Dialog>
  );
};
