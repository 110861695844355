import { SideNav } from '@/components/SideNav/SideNav';
import { capitalize } from '@mui/material';

import { ROUTES } from '@/types';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { SidebarStaticTab } from 'stc-ui-kit/components/Sidebar/types';
import { useMemo } from 'react';
import { icons, Layout, LayoutDirection, LayoutPadding } from 'stc-ui-kit';

export const Administration = () => {
  const { t } = useTranslation();

  const staticTabs: SidebarStaticTab[] = useMemo(() => {
    return [
      {
        route: ROUTES.ADMINISTRATION_USERS,
        title: t('users'),
        icon: icons.IconPerson,
      },

      {
        route: ROUTES.ADMINISTRATION_GROUPS,
        title: capitalize(t('groups.groups')),
        icon: icons.IconCommunity,
      },
      {
        route: ROUTES.ADMINISTRATION_ROLES,
        title: capitalize(t('roles')),
        icon: icons.IconRoles,
      },
    ];
  }, [t]);

  return (
    <Layout direction={LayoutDirection.Horizontal} padding={LayoutPadding.None}>
      <SideNav staticTabs={staticTabs} />
      <Outlet />
    </Layout>
  );
};
