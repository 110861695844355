import { TableFilterButton } from 'stc-ui-kit';
import { observer } from 'mobx-react';
import { useDataStore } from '@/Providers/StoreProvider';

export const OpenSideFilterButton = observer(() => {
  const { templatesStore } = useDataStore();
  const { sideFilterStore: store } = templatesStore;

  return (
    <TableFilterButton
      isActive={store.isSideFilterActive}
      isApplied={store.isSideFilterApplied}
      showFilterPanel={() => store.setSideFilterActivity(true)}
      closeFilterPanel={() => store.setSideFilterActivity(false)}
    />
  );
});
