import { PropsWithChildren, createContext } from 'react';

interface ContextValues extends PropsWithChildren {
  onAddMainPhoto?: () => Promise<void> | void;
  onDeletePhoto?: () => Promise<void> | void;
}

export const CarouselPhotosContext = createContext<ContextValues | null>(null);

export const CarouselPhotosContextProvider = ({
  children,
  onAddMainPhoto,
  onDeletePhoto,
}: ContextValues) => {
  return (
    <CarouselPhotosContext.Provider value={{ onAddMainPhoto, onDeletePhoto }}>
      {children}
    </CarouselPhotosContext.Provider>
  );
};
