import { Box } from '@mui/material';

import { RightPanelWrapper } from '@/components/RightPanelWrapper';

import { HeaderLoadFile } from './components/HeaderLoadFile';

import { HeaderTitle } from './components/HeaderTitle';
import { MissingData } from './components/MissingData';

import { FilesTable } from './components/FilesTable';

import { Paper } from '@/styles/Paper';

import { DetailEntriesIcon } from '@/icons/DetailEntriesIcon';
import { EntriesIcon } from '@/icons/EntriesIcon';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import { FileProcessingContent } from './components/FileProcessing/FileProcessingContent';
import { useLoadFilesData } from './hooks/useLoadFilesData';
import { filesStore } from '../stores/Files';

export const LoadFile = observer(() => {
  const { t } = useTranslation();

  const { setVisibleRange } = useLoadFilesData();

  return (
    <RightPanelWrapper header={<HeaderLoadFile />}>
      <Box display={'flex'} gap={'16px'} height={'100%'}>
        <Paper sx={{ width: '552px', flexShrink: 0 }}>
          {!filesStore.listFiles?.length && (
            <>
              <HeaderTitle text={t('multipass.fileList')} />
              <MissingData
                icon={<EntriesIcon sx={{ fontSize: '96px' }} />}
                title={t('multipass.missingDataTitle')}
                text={t('multipass.missingDataText')}
              />
            </>
          )}
          {!!filesStore.listFiles?.length && <FilesTable changeRange={setVisibleRange} />}
        </Paper>
        {filesStore.selectedFile ? (
          <FileProcessingContent />
        ) : (
          <Paper>
            <HeaderTitle text={t('multipass.fileDetails')} />
            <MissingData
              icon={<DetailEntriesIcon sx={{ fontSize: '96px' }} />}
              title={t('multipass.fileDetails')}
              text={t('multipass.selectFileTitle')}
            />
          </Paper>
        )}
      </Box>
    </RightPanelWrapper>
  );
});
