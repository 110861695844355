import { Box } from '@mui/material';
import { Tooltip } from 'stc-ui-kit';

export const getFileNameFromPath = (path: string | undefined) => {
  if (!path) return '';

  const fileName = decodeURIComponent(path.split('/').pop() || '');
  const name = fileName?.split('.')[0];
  const ext = fileName?.split('.')[1];

  return (
    <Tooltip tooltip={fileName}>
      <Box display={'flex'} flexWrap={'nowrap'}>
        <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{name}</span>
        <span style={{ flexShrink: 0 }}>{ext ? `.${ext}` : ''}</span>
      </Box>
    </Tooltip>
  );
};
