import { useTemplateFieldContext } from '../../../hooks/useTemplateFieldContext';
import { RadioGroupField, RadioGroupFieldStyle } from 'stc-ui-kit';

export function RadioGroupTemplateField() {
  const { fieldData, fieldName, language, templateData, customTypeFieldId, readonly } =
    useTemplateFieldContext();

  const dictionary = templateData?.dictionaries?.find((d) => d.id === fieldData.mapping);

  if (!dictionary) return null;

  const options = dictionary.items.map((item) => {
    const label = item.names.find((n) => n.locale === language)?.value || item.name;

    return {
      id: item.id,
      label,
    };
  });

  if (!dictionary) return null;

  return (
    <RadioGroupField
      data-testid=''
      isRequired={fieldData.required}
      isDisabled={readonly}
      id={customTypeFieldId || fieldData.id}
      label={fieldName}
      items={options}
      className='radioGroup'
      style={RadioGroupFieldStyle.FilledWithBackground}
    />
  );
}
