import React, { FC } from 'react';
import { makeStyles } from '@/hooks/makeStyles';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { PrivilegeCode } from '@/types/role';
import { Button, ButtonStyle, ButtonType, icons, Separator } from 'stc-ui-kit';

export type Props = {
  onClose: () => void;
};

const useStyles = makeStyles()(() => ({
  wrapper: {
    position: 'absolute',
    right: 0,
    top: '57px',
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(80vh - 128px)',
    width: '360px',
    borderLeft: `1px solid ${appColors.darkThemeBorder}`,
    background: appColors.darkThemeBackground,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    position: 'sticky',
    top: 0,
  },
  referenceList: {
    width: '100%',
    overflowY: 'auto',
    flexGrow: 1,
  },
  referenceBlock: {
    padding: '24px 16px',
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${appColors.darkThemeBorder}`,
    },
  },
  dot: {
    height: '4px',
    width: '4px',
    backgroundColor: appColors.cyan,
  },
}));

export const PrivsReference: FC<Props> = ({ onClose }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const privilegeCodesArray = Object.values(PrivilegeCode);

  return (
    <Stack className={classes.wrapper}>
      <div className={classes.header}>
        <Typography fontSize={'16px'} fontWeight={700}>
          {t('privileges.reference')}
        </Typography>
        <Button
          icon={icons.IconClose24}
          type={ButtonType.Icon}
          onClick={onClose}
          buttonStyle={ButtonStyle.Secondary}
        />
      </div>

      <Separator horizontal />

      <div className={classes.referenceList}>
        {privilegeCodesArray.map((p) => {
          const actions: string = t(`privileges.${p}.actions`, '');
          const actionsList = actions.split(' | ');

          return (
            <div key={p} className={classes.referenceBlock}>
              <Typography fontSize={'14px'} fontWeight={700}>
                {t(`privileges.${p}.title`, '')}
              </Typography>

              <Typography fontSize={'14px'}>{t(`privileges.${p}.description`, '')}</Typography>

              {actionsList.map((action, index) => (
                <Typography
                  key={index}
                  gap={'16px'}
                  display={'flex'}
                  flexDirection={'row'}
                  fontSize={'14px'}
                  alignItems={'center'}
                >
                  <div className={classes.dot} />
                  {action}
                </Typography>
              ))}
            </div>
          );
        })}
      </div>
    </Stack>
  );
};
