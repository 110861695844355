import { ArrowBack, ArrowForward, Close } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  IconButton,
  Popover,
  Tooltip,
} from '@mui/material';
import { ReactNode, useRef, useState } from 'react';

interface PagesProps {
  pages: Page[];
  closeDlg: () => void;
  open: boolean;
  containerRef: HTMLElement | null;
}

export function Pages(props: PagesProps) {
  const { pages, closeDlg, open, containerRef } = props;
  const [pageIdx, setPageIdx] = useState(0);
  const totalIdx = pages.length - 1;
  const increaseDisabled = !(pageIdx + 1 <= totalIdx);
  const decreaseDisabled = !(pageIdx > 0);

  const increase = () => {
    setPageIdx((idx) => idx + 1);
  };
  const decrease = () => {
    setPageIdx((idx) => idx - 1);
  };

  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      anchorEl={containerRef}
      open={open}
      onClose={closeDlg}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{ padding: '16px ' }}
    >
      <Box sx={{ padding: '16px 16px 16px 16px' }}>
        <IconButton
          aria-label='close'
          onClick={closeDlg}
          sx={{
            position: 'absolute',
            right: 2,
            top: 2,
          }}
        >
          <Close />
        </IconButton>
        <DialogTitle fontSize='14px' id='alert-dialog-title'>
          {pages[pageIdx]?.text}
        </DialogTitle>
        <DialogActions>
          <Box
            mr='auto'
            sx={{ padding: '4px 16px', border: '1px solid #454A59E0', borderRadius: '5px' }}
            fontWeight={700}
            color='black'
            fontSize={'14px'}
          >
            {pageIdx + 1} / {pages.length}
          </Box>
          <IconButton />
          <Button color='primary' variant='text' disabled={decreaseDisabled} onClick={decrease}>
            <ArrowBack />
          </Button>
          <Button disabled={increaseDisabled} variant='text' color='primary' onClick={increase}>
            <ArrowForward />
          </Button>
        </DialogActions>
      </Box>
    </Popover>
  );
}

interface Page {
  text: string;
}
interface FAQProps {
  text: string;
  icon: ReactNode;
  pages: Page[];
}

export function FAQ(props: FAQProps) {
  const { text, icon, pages } = props;
  const [open, setOpen] = useState(false);
  const containerRef = useRef<HTMLElement | null>(null);

  const openDlg = () => {
    setOpen(true);
  };

  const closeDlg = () => {
    setOpen(false);
  };
  console.log('OPEN: ', open);

  return (
    <Tooltip title={text}>
      <>
        <Box
          ml='24px'
          mr='15px'
          display='flex'
          alignItems='center'
          onClick={openDlg}
          sx={{ cursor: 'pointer' }}
          ref={containerRef}
        >
          {icon}
          <Box fontSize='17px'>?</Box>
        </Box>
        <Pages containerRef={containerRef.current} open={open} closeDlg={closeDlg} pages={pages} />
      </>
    </Tooltip>
  );
}
