import { PrivilegeCode } from '@/types/role';
import { Box, Stack, Typography } from '@mui/material';
import React, { Dispatch, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonStyle,
  ButtonType,
  HierarchyAccordion,
  HierarchyAccordionExpandedState,
  HierarchyAccordionItem,
  icons,
  SearchQueryForm,
  Separator,
  TextFieldValue,
  useForm,
} from 'stc-ui-kit';
import { useRolesContext } from '../../context/RolesContext';
import { PrivsReference } from './PrivsReference';
import { makeStyles } from '@/hooks/makeStyles';

interface Props {
  selectedPrivileges: Set<PrivilegeCode>;
  setSelectedPrivileges: Dispatch<React.SetStateAction<Set<PrivilegeCode>>>;
  onClose: () => void;
}

const useStyles = makeStyles()(() => ({
  editRolesAccordion: {
    '[class*="AccordionItem-module__isGroupItem"]': {
      paddingBlock: '4px',
      background: '#2E343B',
    },
  },
}));

export const EditRolesPrivsAccordion: React.FC<Props> = ({
  selectedPrivileges,
  setSelectedPrivileges,
  onClose,
}) => {
  const { t } = useTranslation();
  const { sections } = useRolesContext();
  const [accordionItemsState, setAccordionItemsState] = useState(
    'all-collapsed' as HierarchyAccordionExpandedState
  );
  const [isPrivsReferenceOpen, setIsPrivsReferenceOpen] = useState(false);
  const { classes } = useStyles();

  const nodes: HierarchyAccordionItem[] = sections.map((section) => ({
    id: section.name,
    isSelectable: false,
    title: t(`privileges.${section.name}`, ''),
    children: section.privilegeGroups.map((group) => ({
      id: group.name,
      isSelectable: false,
      title: t(`privileges.${group.name}`, ''),
      isCheckable: true,
      children: group.privileges.map((priv) => ({
        id: priv,
        title: t(`privileges.${priv}.title`, ''),
        isCheckable: true,
        isSelectable: false,
      })),
    })),
  }));

  const { form: searchQuery } = useForm<{ searchQuery: TextFieldValue }>({
    defaultValues: {
      searchQuery: '',
    },
  });

  const onChangeSelectedSome = (ids: string[], isSelected: boolean) => {
    setSelectedPrivileges((prev) => {
      for (const itemId of ids) {
        isSelected ? prev.add(itemId as PrivilegeCode) : prev.delete(itemId as PrivilegeCode);
      }

      return new Set(prev);
    });
  };

  const onSelectToggle = (itemId: string, isSelected: boolean) => {
    setSelectedPrivileges((prev) => {
      isSelected ? prev.add(itemId as PrivilegeCode) : prev.delete(itemId as PrivilegeCode);

      return new Set(prev);
    });
  };

  return (
    <Box flex={1} fontSize={'14px'} maxHeight={'100%'} overflow={'auto'}>
      <Stack
        display={'flex'}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        height={'56px'}
        padding={'0px 16px'}
      >
        <Typography fontSize={'16px'} fontWeight={700}>
          {t('managingRoles.managingPrivs')}
        </Typography>

        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <Button
            isDisabled={accordionItemsState === ('all-expanded' as HierarchyAccordionExpandedState)}
            onClick={() =>
              setAccordionItemsState('all-expanded' as HierarchyAccordionExpandedState)
            }
            type={ButtonType.Text}
          >
            {t('managingRoles.expandAll')}
          </Button>

          <Button
            isDisabled={
              accordionItemsState === ('all-collapsed' as HierarchyAccordionExpandedState)
            }
            onClick={() =>
              setAccordionItemsState('all-collapsed' as HierarchyAccordionExpandedState)
            }
            type={ButtonType.Text}
          >
            {t('managingRoles.collapseAll')}
          </Button>

          <Box width={'320px'}>
            <SearchQueryForm
              placeholder={t('managingRoles.searchPlaceholder')}
              data-testid=''
              form={searchQuery}
            />
          </Box>

          <Button
            onClick={() => setIsPrivsReferenceOpen(true)}
            icon={icons.IconInfo}
            type={ButtonType.Toggle}
            isActive={isPrivsReferenceOpen}
          />
          <Button
            icon={icons.IconClose24}
            type={ButtonType.Icon}
            onClick={onClose}
            buttonStyle={ButtonStyle.Secondary}
          />
        </Stack>
      </Stack>

      <Separator horizontal />

      <HierarchyAccordion
        nodes={nodes}
        data-testid=''
        isCheckboxesVisible
        isToolbarVisible={false}
        searchQuery={searchQuery.watch().searchQuery}
        expandedState={accordionItemsState}
        onExpandedStateChange={(newAccordionItemsState) =>
          setAccordionItemsState(newAccordionItemsState)
        }
        selectedIds={selectedPrivileges}
        onSelectItem={onSelectToggle}
        onChangeSelectedSome={onChangeSelectedSome}
        className={classes.editRolesAccordion}
      />

      {isPrivsReferenceOpen && <PrivsReference onClose={() => setIsPrivsReferenceOpen(false)} />}
    </Box>
  );
};
