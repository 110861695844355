import { appColors } from '@/Providers/ThemeProvider/colors';
import { Box, SvgIcon, SxProps } from '@mui/material';
import React from 'react';
import { modelQualityToColor } from '../../utils/modelQualityToColor';
import { getModelQuality } from '../../utils/getModelQuality';
import { ModelPhotoQualityIcon } from '../../icons/ModelPhotoQuality';
import { FileType } from '@/types/file';

interface Props {
  quality: number;
  size?: number;
  type: FileType;
  wrapperSx?: SxProps;
}

export const ModelQualityIconComponent: React.FC<Props> = ({
  type,
  quality,
  size = 24,
  wrapperSx,
}) => {
  const icon = type === 'audio' ? null : <ModelPhotoQualityIcon />;

  return (
    <Box
      sx={{
        borderRadius: '50%',
        border: `2px solid ${appColors.white}`,
        background: modelQualityToColor[getModelQuality(quality)],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px',
        width: `${size}px`,
        height: `${size}px`,
        ...wrapperSx,
      }}
    >
      <SvgIcon sx={{ width: '100%' }}>{icon}</SvgIcon>
    </Box>
  );
};
