import { createSvgIcon } from '@mui/material';

export const ModelPhotoQualityIcon = createSvgIcon(
  <svg viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      // eslint-disable-next-line max-len
      d='M0 0H4V2H2V4H0V2V0ZM10 0H14V2V4H12V2H10V0ZM10 12V14H14V12V10H12V12H10ZM4 14H0V12V10H2V12H4V14ZM7 2C4.79086 2 3 3.79086 3 6V7.79058C3 9.78712 4.02852 11.6428 5.72158 12.701L5.90722 12.817C6.57582 13.2349 7.42418 13.2349 8.09278 12.817L8.27842 12.701C9.97148 11.6428 11 9.78712 11 7.79058V6C11 3.79086 9.20914 2 7 2Z'
      fill='white'
    />
  </svg>,
  ''
);
