import { TableCell, TableCellBaseProps, Typography } from '@mui/material';
import { useDateConvertToShort, useTimeConverter } from '@/hooks/DateConverter';
import { ElementType } from 'react';

export const DateCell = ({
  dateTime,
  textColor,
  component,
}: {
  dateTime?: string;
  textColor?: string;
  component?: ElementType<TableCellBaseProps, keyof JSX.IntrinsicElements>;
}) => {
  const date = useDateConvertToShort(dateTime);
  const time = useTimeConverter(dateTime);

  return (
    <TableCell sx={{ color: textColor, padding: 0, border: 'none' }} component={component}>
      <Typography fontSize={'14px'} lineHeight={1}>
        {date}
      </Typography>

      <Typography
        lineHeight={1}
        fontSize={'12px'}
        sx={{ color: textColor ? textColor : '#FFFFFF8F' }}
      >
        {time}
      </Typography>
    </TableCell>
  );
};
