import { RightPanelWrapper } from '@/components/RightPanelWrapper';
import { HeaderRoles } from './components/HeaderRoles';
import { RolesAccordion } from './components/RolesAccordion';
import { RolesContextProvider } from './context/RolesContext';

export const Roles = () => {
  return (
    <RolesContextProvider>
      <RightPanelWrapper header={<HeaderRoles />}>
        <RolesAccordion />
      </RightPanelWrapper>
    </RolesContextProvider>
  );
};
