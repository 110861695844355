import { useRef } from 'react';
import { NotificationType, showNotification } from 'stc-ui-kit';

const AUTO_CLOSE_TIMEOUT = 5000;

export const useNonDuplicatedNotifications = () => {
  const notificationKeys = useRef(new Set());

  const showErrorNotification = ({ header, message }: { header: string; message: string }) => {
    const key = header || message;

    if (notificationKeys.current.has(key)) return;

    notificationKeys.current.add(key);

    showNotification({
      dataTestId: '',
      header: header,
      message: message,
      type: NotificationType.Error,
    });

    setTimeout(() => {
      notificationKeys.current.delete(key);
    }, AUTO_CLOSE_TIMEOUT);
  };

  return { showErrorNotification };
};
