import { IApiJsonFieldFilter } from '@/types';
import { GridColDef } from '@mui/x-data-grid';

export enum EDataGridStoreSortMode {
  ASC = 'ASC',
  DSC = 'DESC',
}

export enum DataGridStorePredicates {
  Between = 0,
  InFilter = 1,
  Contains = 2,
  NotContains = 3,
  StartsWith = 4,
  NotStartsWith = 5,
  EndsWith = 6,
  NotEndsWith = 7,
  Equals = 8,
  NotEquals = 9,
  Less = 10,
  LessOrEqual = 11,
  Greater = 12,
  GreaterOrEqual = 13,
  IsNull = 14,
  IsNotNull = 15,
  In = 16,
  NotIn = 17,
}

export type EDataGridStorePredicates = keyof typeof DataGridStorePredicates;

export interface IGridColumnFilter<T> {
  key: keyof T;
  predicate: DataGridStorePredicates;
  targetValues: (string | null | boolean)[];
  ignoreCase?: boolean;
  score?: string;
  jsonFields?: IApiJsonFieldFilter[];
}

export interface IGridFilter<T> {
  fieldId: keyof T;
  targetValues?: (string | null | boolean)[];
  predicate: DataGridStorePredicates;
}

export type PaginatonType = 'cursor' | 'offset';

export interface IDataGridStoreState<T> {
  offset: number;
  after: T | null;
  before: T | null;
  limit: number;
  sort: EDataGridStoreSortMode;
  sortField: keyof T;
  paginationType: PaginatonType;
  columnFilterMap: IGridColumnFilter<T>[];
  quickFilters?: IGridFilter<T>[];
  searchString?: string;
}

export interface IDataGridStoreData<T> {
  data: T[];
  hasNextPage?: boolean;
}

export type DataProviderResult<T> = Promise<IDataGridStoreData<T>>;
export type DataProvider<T> = (
  state: IDataGridStoreState<T>,
  localData?: T[]
) => DataProviderResult<T>;

export interface IDataGridStore<T> {
  data: T[];
  isPending: boolean;
  error?: string;
  state: IDataGridStoreState<T>;
  checkedItems: T[];
  rowsOnPage: number;
  currentPage: number;
  dataProvider: DataProvider<T>;
  hasNextPage: boolean;

  setCheckedItems: (items: T[]) => void;
  reload: () => Promise<void>;
  loadData: (state: IDataGridStoreState<T>) => Promise<void>;

  resetChecked: () => void;

  resetSort: () => void;

  setRowsOnPage: (number: number) => void;
  setCurrentPageAndLoadData: (number: number) => void;

  setDataProvider: (provider: DataProvider<T>) => void;
}

export interface IGridColDef<T extends object> extends GridColDef {
  field: Extract<keyof T, string> | 'edit' | 'delete';
}
