import { useTemplateFieldContext } from '../../../hooks/useTemplateFieldContext';
import { ChipsField } from 'stc-ui-kit';

export function ListStringTemplateField() {
  const { fieldData, fieldName, language, customTypeFieldId, readonly } = useTemplateFieldContext();

  const placeholder =
    fieldData.placeholders?.find((p) => p.locale === language)?.value || fieldData.placeholder;

  return (
    <ChipsField
      id={customTypeFieldId || fieldData.id}
      //TODO validation
      isRequired={fieldData.required}
      placeholder={placeholder}
      label={fieldName}
      isEditable
      //TODO not working currently
      isAllowDuplicate={false}
      //TODO validation (validationRegexp is not working currently)
      // validationRegexp={fieldData.validator ? new RegExp(fieldData.validator) : undefined}
      // validationRegexp={
      //   /^(([0-9A-Za-zА-Яа-я]{1}[-0-9A-Za-zА-Яа-я_\.]{0,62})[a-zA-Zа-яА-Я0-9]{1})@(([-A-Za-zА-Яа-я0-9]{1,63}\.){1,}[-A-Za-zА-Яа-я0-9]{2,63})$/
      // }
      // validationRegexp={/^\S+@\S+\.\S+$/}
      data-testid=''
      isReadOnly={readonly}
    />
  );
}
