import { HeaderLeftIconTextWrapper } from '@/components/HeaderLeftIconTextWrapper';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonType,
  ConfirmationType,
  Separator,
  Tooltip,
  icons,
  useConfirmationModal,
} from 'stc-ui-kit';
import { Stack } from '@mui/material';
import { cardsService } from '@/services/cards.service';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/types';
import { CopyIdButton } from '@/components/common/ui/CopyIdButton';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';
import { useCardCreationContext } from '../hooks/useCardCreationContext';
import { getCardAliasWithCreationDate } from '@/utils/getCardAliasWithCreationDate';
import { showInfoNotification } from '@/utils/notifications';

export const CardCreationHeader = () => {
  const { t } = useTranslation();
  const { showConfirmationModal } = useConfirmationModal();
  const { havePermissions } = useUserPermissionsContext();
  const navigate = useNavigate();
  const { selectedTemplate, cardData, formHooks } = useCardCreationContext();

  const cardId = cardData?.id || null;

  const handleDelete = async () => {
    if (!cardId) return;
    try {
      const confirmationType = await showConfirmationModal({
        title: t('cards.deleteCard'),
        text: t('cards.areYouShureDirDeleteOne'),
        primaryButtonText: t('delete'),
        cancelButtonText: t('cancel'),
      });

      if (confirmationType !== ConfirmationType.PrimaryAction) return;

      const { errorDescription } = await cardsService.deleteCards([cardId]);

      /**
       * все эти манипуляции с формой нужны для того,
       * чтобы при удалении карточки не появлялась модалка про несохраненные изменения
       */
      const formDefaultValues = formHooks.formState.defaultValues;
      const formCurrentValues = formHooks.getValues();
      formHooks.reset(formCurrentValues);

      if (errorDescription) {
        formHooks.reset(formDefaultValues);
        formHooks.reset(formCurrentValues, { keepDirty: true, keepDefaultValues: true });

        return;
      }

      navigate(ROUTES.MULTIPASS_FILLING_CABINET);

      showInfoNotification({ header: t('cards.succesDeletedСard') });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <HeaderLeftIconTextWrapper
        icon={icons.IconSummaryAdd}
        text={getCardAliasWithCreationDate(cardData, selectedTemplate)}
      />

      {cardId && (
        <Stack direction={'row'} spacing={'16px'}>
          <Separator vertical />
          <CopyIdButton id={cardId} />
          <Separator vertical />
          {havePermissions(permissionControl.enrollment.cardCreation.deleteButton) && (
            <Tooltip tooltip={t('delete')}>
              <Button icon={icons.IconDelete} onClick={handleDelete} type={ButtonType.Icon} />
            </Tooltip>
          )}
        </Stack>
      )}
    </>
  );
};
