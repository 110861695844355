import { intervalToDuration } from 'date-fns';

const formatDurationUnit = (unit: number) => String(unit).padStart(2, '0');

export const formatMsToTime = (ms: number): string => {
  const {
    days = 0,
    hours: initialHours = 0,
    minutes = 0,
    seconds = 0,
  } = intervalToDuration({
    start: 0,
    end: ms,
  });

  const hours = initialHours + days * 24;

  return `${formatDurationUnit(hours)}:${formatDurationUnit(minutes)}:${formatDurationUnit(
    seconds || 0
  )}`;
};
