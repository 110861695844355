import { ITableItem } from '@/types/file';
import styles from './VoiceModels.module.scss';
import { TableRow } from 'stc-ui-kit';
import { useState } from 'react';
import { VoiceModelsPlayer } from './VoiceModelsPlayer';
import { VoiceModelsTable } from './VoiceModelsTable';
import { useCardCreationContext } from '../../../hooks/useCardCreationContext';

export function VoiceModels() {
  const { fileLinks } = useCardCreationContext();
  const [audioIdForPlayer, setAudioIdForPlayer] = useState<number | null>(null);

  const onRowOpen = (item: TableRow<ITableItem>) => {
    if (Number(item.id) === audioIdForPlayer) {
      setAudioIdForPlayer(null);

      return;
    }
    setAudioIdForPlayer(Number(item.id));
  };

  const playerAudio = fileLinks.find((fl) => fl.id === audioIdForPlayer);

  return (
    <div className={styles.voiceModels}>
      <VoiceModelsTable onRowOpen={onRowOpen} />
      {!!playerAudio?.fileModelLinks && (
        <VoiceModelsPlayer
          selectedProcess={playerAudio}
          onClose={() => setAudioIdForPlayer(null)}
        />
      )}
    </div>
  );
}
