import { createContext, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import styles from './Groups.module.scss';
import { Separator } from 'stc-ui-kit';
import { observer } from 'mobx-react';
import { GroupsBlock } from './GroupsBlock';
import { UsersBlock } from './UsersBlock';
import { IGroup } from '@/types/group';
import groupService from '@/services/group.service';
import { User } from '@/types/user';
import userService from '@/services/user.service';
import { useDataStore } from '@/Providers/StoreProvider';
import { SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_EXPANDED_WIDTH } from '@/components/SideNav/SideNav';

interface IGroupsPageContext {
  groups: IGroup[];
  fetchGroups: () => Promise<void>;
  groupUsers: User[];
  fetchGroupUsers: () => Promise<void>;
  selectedGroupsIds: string[];
  setSelectedGroupsIds: Dispatch<SetStateAction<string[]>>;
  isGroupsLoading: boolean;
  isUsersLoading: boolean;
}

export const GroupsPageContext = createContext<IGroupsPageContext>({
  groups: [],
  fetchGroups: () => new Promise((res) => res()),
  groupUsers: [],
  fetchGroupUsers: () => new Promise((res) => res()),
  selectedGroupsIds: [],
  setSelectedGroupsIds: () => null,
  isGroupsLoading: false,
  isUsersLoading: false,
});

const Groups = observer(() => {
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [groupUsers, setGroupUsers] = useState<User[]>([]);
  const [isGroupsLoading, setIsGroupsLoading] = useState(false);
  const [isUsersLoading, setIsUsersLoading] = useState(false);

  const [selectedGroupsIds, setSelectedGroupsIds] = useState<string[]>([]);
  const selectedGroupId = selectedGroupsIds[0];
  const selectedGroup = groups.find((g) => g.id.toString() === selectedGroupId);

  const { constantsStore } = useDataStore();

  const fetchGroups = useCallback(async () => {
    setIsGroupsLoading(true);
    try {
      const groups = await groupService.getAll();
      setGroups(groups?.sort((a, b) => a.groupName.localeCompare(b.groupName)) || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsGroupsLoading(false);
    }
  }, []);

  const fetchGroupUsers = useCallback(async () => {
    if (!selectedGroupId) return;

    setIsUsersLoading(true);
    try {
      const data = await userService.getGroupUsers(Number(selectedGroupId));
      setGroupUsers(data.res || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUsersLoading(false);
    }
  }, [selectedGroupId]);

  useEffect(() => {
    void fetchGroups();
  }, [fetchGroups]);

  useEffect(() => {
    void fetchGroupUsers();
  }, [fetchGroupUsers]);

  useEffect(() => {
    if (!selectedGroupsIds.length && groups?.length) {
      setSelectedGroupsIds([groups[0].id.toString()]);
    }
  }, [selectedGroupsIds, groups]);

  return (
    <GroupsPageContext.Provider
      value={{
        fetchGroups,
        fetchGroupUsers,
        groups,
        groupUsers,
        selectedGroupsIds,
        setSelectedGroupsIds,
        isGroupsLoading,
        isUsersLoading,
      }}
    >
      <div
        className={styles.groupsPage}
        style={{
          maxWidth: constantsStore.isSideNavCollapsed
            ? `calc(100% - ${SIDE_NAV_COLLAPSED_WIDTH}px)`
            : `calc(100% - ${SIDE_NAV_EXPANDED_WIDTH}px)`,
        }}
      >
        <GroupsBlock />

        <Separator vertical className={styles.blocksSeparator} />

        {!!selectedGroup && <UsersBlock />}
      </div>
    </GroupsPageContext.Provider>
  );
});

export { Groups };
