import { useContext } from 'react';
import { TemplateFieldContext } from '../components/CardForm/TemplateFieldRenderer';

export const useTemplateFieldContext = () => {
  const context = useContext(TemplateFieldContext);

  if (!context) throw new Error('Provide template field context');

  return context;
};
