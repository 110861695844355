export const allowedCardImageFormats = ['image/bmp', 'image/png', 'image/jpeg', 'image/jpg'];
export const allowedCardAudioFormats = [
  'audio/wav',
  'audio/mp3',
  'audio/x-ape',
  'audio/flac',
  'audio/x-ms-wma',
  'audio/aac',
  'audio/wsd',
];

export const allowedCardDocFormats = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'application/rtf',
  'application/pdf',
  'application/vnd.oasis.opendocument.text',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.oasis.opendocument.spreadsheet',
  'image/jpeg',
  'image/tiff',
  'image/png',
  'image/bmp',
  'image/x-canon-crw',
  'image/x-portable-bitmap',
  'image/x-portable-graymap',
  'image/x-portable-pixmap',
  'image/x-sr2',
  'image/x-cmu-raster',
  'application/vnd.oasis.opendocument.graphics',
  'image/x-cmu-raster',
  'image/x-portable-bitmap',
  'image/x-portable-graymap',
  'image/x-portable-pixmap',
  'image/x-sun-raster',
  'application/vnd.oasis.opendocument.graphics',
];

const allowedVideoFormats = [
  'video/x-msvideo',
  'video/mpeg',
  'video/mp4',
  'video/x-matroska',
  'video/quicktime',
  'video/x-ms-wmv',
  'video/3gpp',
  'video/x-flv',
  'video/x-ms-asf',
];

export const allowedCardAttachedFilesFormats = [
  ...allowedCardDocFormats,
  ...allowedCardAudioFormats,
  ...allowedVideoFormats,
];

export const loadingErrorPath = 'LOADING_ERROR_PATH';
