import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Checkbox,
  Typography,
  Stack,
} from '@mui/material';
import { Button, ButtonStyle, ButtonType, icons } from 'stc-ui-kit';
import { useState, Fragment, useCallback } from 'react';
import Directory from './Directory';
import { CollapsableSection } from './CollapsableSection';
import { useTranslation } from 'react-i18next';
import { BreadCrumbsSection } from './BreadCrumbsSection';
import { useFileDialog } from './hooks/useFileDialog';
import { IStorageFile } from './types';

interface IFileDialog {
  isDisabled?: boolean;
  onFileChoosen: (params: {
    selectedFiles: IStorageFile[];
    prepareFiles: boolean;
    dontUseForPersonsEntries: boolean;
  }) => void;
}

export const FileDialog = (props: IFileDialog) => {
  const { onFileChoosen, isDisabled } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const {
    currentPath,
    items,
    onFileClick,
    selectedFiles,
    isCollapseDirectory,
    opnCloseDirectory,
    loading,
    loadDirectory,
    resetState,
  } = useFileDialog();

  const [needPrepareFiles, setNeedPrepareFiles] = useState(true);
  const [dontUseForPersonsEntries, setDontUseForPersonsEntries] = useState(false);

  const handleClickOpen = async () => {
    setOpen(true);
    await loadDirectory(currentPath);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    resetState();
  }, [resetState]);

  const handleOpenFile = useCallback(() => {
    if (selectedFiles.length === 0) return;

    onFileChoosen({
      selectedFiles,
      prepareFiles: needPrepareFiles,
      dontUseForPersonsEntries,
    });

    handleClose();
  }, [dontUseForPersonsEntries, handleClose, needPrepareFiles, onFileChoosen, selectedFiles]);

  const dialogTextStyle: React.CSSProperties = {
    fontSize: '14px',
    color: 'black',
  };

  return (
    <Fragment>
      <Button
        isDisabled={isDisabled}
        type={ButtonType.Text}
        onClick={handleClickOpen}
        icon={icons.IconAdd}
      >
        {t('add')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          sx: {
            width: '100%',
            height: '100%',
            maxWidth: '55.8vw',
            maxHeight: '55.8vh',
            minWidth: '32vw',
          },
        }}
      >
        <DialogTitle id='alert-dialog-title' sx={{ background: 'rgba(190, 206, 218, 0.24)' }}>
          {t('FileDialog.title')}
        </DialogTitle>
        <Divider variant='fullWidth' />

        <DialogContent sx={{ paddingLeft: '8px' }}>
          <BreadCrumbsSection currentPath={currentPath} onDirectoryClick={loadDirectory} />
          <Divider variant='fullWidth' />

          <CollapsableSection
            title={t('FileDialog.folderSection')}
            count={items.filter((i) => i.type === 'DIR').length}
            isCollapse={isCollapseDirectory}
            openClose={opnCloseDirectory}
          >
            <Directory
              loading={loading}
              selectedItems={selectedFiles}
              items={items}
              onFileClick={onFileClick}
              onDirectoryDoubleClick={loadDirectory}
            />
          </CollapsableSection>
          {/* <CollapsableSection
            title={t('FileDialog.deviceSection')}
            count={0}
            isCollapse={isCollapseIDevices}
            openClose={opnCloseDevices}
          ></CollapsableSection> */}
        </DialogContent>
        <DialogActions sx={{ background: 'rgba(190, 206, 218, 0.24)', padding: 0 }}>
          <Stack
            direction={'row'}
            width={'100%'}
            justifyContent={'space-between'}
            gap='16px'
            padding='16px'
          >
            <Stack direction={'row'} gap='5px'>
              <Stack direction={'row'} alignItems={'center'} component='label'>
                <Checkbox
                  checked={dontUseForPersonsEntries}
                  onChange={(v) => setDontUseForPersonsEntries(v.target.checked)}
                  color='default'
                />
                <Typography sx={dialogTextStyle}>
                  {t('FileDialog.dontUseForPersonCreation')}
                </Typography>
              </Stack>

              <Stack direction={'row'} alignItems={'center'} component='label'>
                <Checkbox
                  checked={needPrepareFiles}
                  onChange={(v) => setNeedPrepareFiles(v.target.checked)}
                  color='default'
                />
                <Typography sx={dialogTextStyle}>
                  {t('FileDialog.prepareFilesForUploading')}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={'row'} gap={2}>
              <Button
                isDisabled={
                  selectedFiles.length === 0 || (!needPrepareFiles && dontUseForPersonsEntries)
                }
                onClick={handleOpenFile}
              >
                {t('add')}
              </Button>
              <Button buttonStyle={ButtonStyle.Secondary} onClick={handleClose}>
                {t('FileDialog.cancel')}
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
