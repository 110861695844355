import { Backdrop } from '@mui/material';
import { Loader } from 'stc-ui-kit';

export function BackdropLoader({ open }: { open: boolean }) {
  return (
    <Backdrop sx={{ zIndex: 9999 }} open={open}>
      <div
        style={{
          height: 220,
          width: 220,
        }}
      >
        <Loader data-testid='' />
      </div>
    </Backdrop>
  );
}
