import { getServerMediaFilePath } from '@/services/root.api.service';
import { TabEntity } from '@/types/ui.types';
import { FileDownloadOutlined } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType, Tooltip } from 'stc-ui-kit';

interface Props {
  selectedEntities: TabEntity[];
}

export const DownloadButton: React.FC<Props> = ({ selectedEntities }) => {
  const { t } = useTranslation();

  const downloadFiles = (files: { url: string; filename: string }[]) => {
    files.forEach(({ url, filename }) => {
      const link = document.createElement('a');
      link.href = getServerMediaFilePath(url, true);
      link.download = decodeURIComponent(filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const onDownload = () => {
    downloadFiles(
      selectedEntities.map((t) => ({
        url: t.path,
        filename: t.name,
      }))
    );
  };

  return (
    <Tooltip tooltip={t('download')}>
      <Button
        icon={() => <FileDownloadOutlined />}
        onClick={onDownload}
        isDisabled={selectedEntities.length === 0}
        type={ButtonType.Icon}
      />
    </Tooltip>
  );
};
