import { IRoleWithPrivs, IRoleWithPrivilegesInGroups, IPrivilegeSection } from '@/types/role';

export function mergeRolesAndPrivilegeSections(
  roles: IRoleWithPrivs[],
  sections: IPrivilegeSection[]
): IRoleWithPrivilegesInGroups[] {
  return roles.map((role) => ({
    id: role.id,
    roleName: role.roleName,
    privilegeGroups: sections.flatMap((section) =>
      section.privilegeGroups
        .map((group) => ({
          name: group.name,
          privileges: group.privileges.filter((privilege) => role.privileges.includes(privilege)),
        }))
        .filter((group) => group.privileges.length > 0)
    ),
  }));
}
