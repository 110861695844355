import { FC, useCallback, useEffect, useMemo } from 'react';
import { TableFilterPanel, useForm } from 'stc-ui-kit';
import { observer } from 'mobx-react';
import { FieldValues } from 'react-hook-form';
import { Box } from '@mui/material';
import { useDataStore } from '@/Providers/StoreProvider';
import { useTranslation } from 'react-i18next';
import { Language } from '@/types';
import {
  renderDateFilterField,
  renderDictFilterField,
  renderHotListFilterField,
  renderListDictFilterField,
  renderListStringFilterField,
  renderStatusFilterField,
  renderStringFilterField,
} from './filterFields';
import './styles.scss';
import { exportStatusId } from './constants';
import { ECardExportStatus } from '@/types/card';

export const SideFilter: FC = observer(() => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as Language;
  const { templatesStore, uiStore } = useDataStore();
  const { sideFilterStore } = templatesStore;
  const { filingCabinetFilters, setExportStatusQuickFilter } = uiStore;
  const { quickFilters } = filingCabinetFilters;
  const statusFilterValue = useMemo(
    () =>
      (quickFilters.find((f) => f.fieldId === exportStatusId)?.targetValues as string[]) || null,
    [quickFilters]
  );

  const {
    setSideFilterActivity,
    applyFilter,
    resetFilter,
    isSideFilterApplied,
    appliedFilter,
    sideFilterDefaultFieldValues,
    sideFilterFields,
  } = sideFilterStore;

  const defaultFormValues = isSideFilterApplied
    ? { ...appliedFilter, [exportStatusId]: statusFilterValue }
    : { ...sideFilterDefaultFieldValues, [exportStatusId]: statusFilterValue };

  const { form, clearFormState } = useForm<FieldValues>({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    form.setValue(exportStatusId, statusFilterValue);
  }, [form, statusFilterValue]);

  const handleClose = useCallback(() => {
    setSideFilterActivity(false);
  }, [setSideFilterActivity]);

  const handleApply = useCallback(() => {
    const formValues = form.getValues();

    setExportStatusQuickFilter(formValues[exportStatusId] as ECardExportStatus[] | null);

    // console.log(JSON.parse(JSON.stringify(updatedQuickFilters)));

    delete formValues[exportStatusId];
    applyFilter(formValues);
    console.log(formValues);
  }, [applyFilter, form, setExportStatusQuickFilter]);

  const handleReset = useCallback(() => {
    form.reset({ ...sideFilterDefaultFieldValues, [exportStatusId]: null });

    setExportStatusQuickFilter(null);

    // const updatedQuickFilters = quickFilters.filter((f) => f.fieldId !== exportStatusId);
    // setQuickFilters(updatedQuickFilters);

    clearFormState();
    resetFilter();
  }, [resetFilter, form, clearFormState, sideFilterDefaultFieldValues, setExportStatusQuickFilter]);

  const filterPanelProps = {
    onClose: handleClose,
    appliedFilterForm: form,
    currentFilterForm: form,
    onApply: handleApply,
    onReset: handleReset,
  };

  return (
    <TableFilterPanel {...filterPanelProps} data-testid={''}>
      <Box p={2} gap={2} display={'flex'} flexDirection={'column'}>
        {renderHotListFilterField()}
        {renderStatusFilterField()}
        <>
          {sideFilterFields.map((filterField) => {
            const label =
              filterField.field.names?.find((p) => p.locale === language)?.value ??
              filterField.field.name;

            switch (filterField.field.type) {
              case 'Date':
                return renderDateFilterField(filterField.field, label, form);

              case 'Dict':
                return filterField.field.view === 'Radio'
                  ? renderDictFilterField(filterField.field, label, language)
                  : renderListDictFilterField(
                      filterField.field,
                      label,
                      t('sideFilterSelectPlaceholder'),
                      language
                    );

              case 'ListDict':
                return renderListDictFilterField(
                  filterField.field,
                  label,
                  t('sideFilterSelectPlaceholder'),
                  language
                );

              case 'ListString':
                return renderListStringFilterField(
                  filterField.field,
                  label,
                  t('sideFilterStringPlaceholder')
                );

              case 'String':
                return renderStringFilterField(
                  filterField.field,
                  label,
                  t('sideFilterStringPlaceholder')
                );

              case 'DateTime':
              case 'Int':
              case 'ListObject':
              default:
                return null;
            }
          })}
        </>
      </Box>
    </TableFilterPanel>
  );
});
