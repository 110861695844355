import { appColors } from '@/Providers/ThemeProvider/colors';
import { QuestionAnswer } from '@mui/icons-material';
import { MenuItem, Select, Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import styles from './SelectSampleType.module.scss';
//скорее всего, будет приходить с бэка, поэтому плейсхолдер
const SAMPLE_TYPES_PLACEHOLDER = [
  'Задержание',
  'Митинг',
  'Допрос при обыске',
  'Разговор с психологом',
  'Допрос Плохой полицейский',
  'Допрос Хороший полицейский',
];

interface SelectSampleTypeProps {}

export const SelectSampleType = observer((props: SelectSampleTypeProps) => {
  return (
    <Box ml='auto'>
      <Select
        variant='standard'
        defaultValue={SAMPLE_TYPES_PLACEHOLDER[0]}
        startAdornment={<QuestionAnswer style={{ color: appColors.bgPrimary }} />}
        inputProps={{ className: styles.selectInputComponent }}
      >
        {SAMPLE_TYPES_PLACEHOLDER.map((st) => (
          <MenuItem value={st}>{st}</MenuItem>
        ))}
      </Select>
    </Box>
  );
});
