import { filesStore } from '@/modules/multipass/stores/Files';
import { getPlayerFilePathForModel } from '@/modules/multipass/utils/getPlayerFilePathForModel';
import { IFileModelView } from '@/types/file';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { DictorInfoCard } from './DictorInfoCard';
import { IDictor } from '@/types/dictor';
import { WmsPlayer } from '@/components/WmsPlayer';

interface Props {
  selectedFileId: number;
}

export const DictorsContent: React.FC<Props> = observer(({ selectedFileId }) => {
  const [selectedDictor, setSelectedDictor] = useState<null | IFileModelView<IDictor>>(null);
  const [isPlayerClosed, setIsPlayerClosed] = useState(false);

  const fileUrl = selectedDictor ? getPlayerFilePathForModel(selectedDictor.data) : '';

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      flex={1}
      height='100%'
    >
      <Box display='flex' gap='16px' flexWrap='wrap' mb='20px' padding='16px' overflow='auto'>
        {[...(filesStore.dictorsInfo || [])]
          .sort((a, b) => +a.isExcluded - +b.isExcluded)
          .map((d) => (
            <DictorInfoCard
              key={d.id}
              info={d}
              isSelected={d.id === selectedDictor?.id}
              onSelect={() => {
                setSelectedDictor(d);
                setIsPlayerClosed(false);
              }}
              fileId={selectedFileId}
            />
          ))}
      </Box>
      {selectedDictor && !isPlayerClosed && <WmsPlayer fileName={fileUrl} />}
    </Box>
  );
});
