import { appColors } from '@/Providers/ThemeProvider/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, SvgIcon } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCardCreationContext } from '../../CardCreation/hooks/useCardCreationContext';
import { CarouselPhotosContext } from '../../context/CarouselPhotosContext';
import { CardPhoto } from '@/types/card';
import { Separator } from 'stc-ui-kit';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';

type CardPhotoType = 'oneFace' | 'multipleFaces' | 'noFace';

interface Props {
  photo: CardPhoto;
  onCarouselClose: () => void;
}

export const PhotoContextMenu: React.FC<Props> = ({ photo, onCarouselClose }) => {
  const menuContext = useContext(CarouselPhotosContext);
  const { cardData, setCardModel, deleteFiles, createNewEntry } = useCardCreationContext();

  const [buttonEl, setButtonEl] = useState<Element | null>(null);
  const { t } = useTranslation();

  const { havePermissions } = useUserPermissionsContext();
  const { deleteButton, createNewEntryButton, setAsMainButton } =
    permissionControl.enrollment.cardCreation.modelsSection;

  const photoType = useMemo<CardPhotoType>(() => {
    let result: CardPhotoType = 'noFace';

    if (photo.faceData.length === 1) {
      result = 'oneFace';
    }

    if (photo.faceData.length > 1) {
      result = 'multipleFaces';
    }

    return result;
  }, [photo.faceData]);

  const isMain = photo.faceData.some((d) => d.modelId === cardData?.faceModel?.id);

  const onMenuOpen = (buttonElement: HTMLButtonElement) => {
    setButtonEl(buttonElement);
  };

  const onMenuClose = () => {
    setButtonEl(null);
  };

  const onDelete = async () => {
    try {
      onMenuClose();
      await deleteFiles([photo.id], photo.fileGroup);
      await menuContext?.onDeletePhoto?.();
    } catch (e) {
      console.log(e);
    }
  };

  const onCreateNewEntry = () => {
    try {
      createNewEntry({
        modelId: photo.faceData[0].modelId,
        fileProcessId: photo.fileProcessId,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSetAsMain = async () => {
    try {
      await setCardModel(photo.faceData[0].modelId);
      onMenuClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <IconButton onClick={(e) => onMenuOpen(e.currentTarget)} sx={{ padding: 0, zIndex: 200 }}>
        <SvgIcon sx={{ color: appColors.white }}>
          <MoreVertIcon />
        </SvgIcon>
      </IconButton>

      <Menu
        anchorEl={buttonEl}
        open={!!buttonEl}
        onClose={(e: MouseEvent | KeyboardEvent) => {
          e.stopPropagation();

          onMenuClose();
        }}
        slotProps={{
          paper: {
            sx: {
              background: appColors.darkThemeBackground,
              border: `1px solid ${appColors.darkThemeBorder}`,
            },
          },
        }}
      >
        {photoType !== 'noFace' && (
          <MenuItem
            disabled={photoType !== 'oneFace' || isMain || !havePermissions(setAsMainButton)}
            onClick={handleSetAsMain}
            sx={{ paddingRight: '64px' }}
          >
            {t('multipass.setAsMain')}{' '}
          </MenuItem>
        )}
        {photoType !== 'noFace' && (
          <MenuItem
            disabled={photoType !== 'oneFace' || !havePermissions(createNewEntryButton)}
            onClick={onCreateNewEntry}
          >
            {t('multipass.createNewEntry')}
          </MenuItem>
        )}

        <MenuItem onClick={onCarouselClose}>{t('multipass.closeFullscreen')}</MenuItem>

        {/* скрыто временно */}
        {/* <MenuItem>{t('multipass.openInNewTab')}</MenuItem> */}

        <Separator horizontal />

        <MenuItem onClick={onDelete} disabled={!havePermissions(deleteButton)}>
          {t('delete')}
        </MenuItem>
      </Menu>
    </>
  );
};
