import bind from '@chbrown/bind';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { EFileProcessStatus, IFileModelView, IFileLink } from '@/types/file';
import filesService from '@/services/files.service';
import { IStorageFile } from '@/components/FileDialog/types';
import { IDictor } from '@/types/dictor';

class FilesStore {
  constructor() {
    makeObservable(this);
  }

  @observable listFiles: IFileLink[] = [];
  @observable selectedFile: IFileLink | null = null;
  @observable loading = false;
  @observable dictorsInfo: IFileModelView<IDictor>[] | null = null;

  @action setListFiles(files: IFileLink[]): void {
    this.listFiles = files;
  }

  @action setLoading(loading: boolean) {
    this.loading = loading;
  }

  @bind
  async init() {
    const data = await filesService.getAllFiles('600', '0');
    if (data) {
      this.setListFiles(data);
    }
  }

  @bind
  async updateByIds(ids: string[]) {
    const data = await filesService.getFilesByIds(ids.map((id) => +id));
    if (data !== null) {
      this.replaceDataById(data);
    }
  }

  replaceDataById(updates: IFileLink[]) {
    const updatedData = this.listFiles;

    updates.forEach((update) => {
      const index = updatedData.findIndex((item) => item.id === update.id);
      if (index !== -1) {
        runInAction(() => (updatedData[index] = { ...update }));
      }

      if (this.selectedFile && this.selectedFile.id === update.id) {
        runInAction(() => (this.selectedFile = { ...update }));
      }
    });
  }

  @bind
  async addFile({
    selectedFiles,
    dontUseForPersonsEntries,
    prepareFiles,
  }: {
    selectedFiles: IStorageFile[];
    prepareFiles: boolean;
    dontUseForPersonsEntries: boolean;
  }) {
    this.setLoading(true);
    await filesService.addFile(
      selectedFiles.map((f) => ({
        path: f.path,
        type: f.type,
        processingParameters: {
          buildModels: !dontUseForPersonsEntries,
          sliceOnly: prepareFiles,
        },
      }))
    );
    await this.init();
    this.setLoading(false);
  }

  @bind
  async updateStatus() {
    this.setLoading(true);
    if (this.selectedFile) {
      const newStatus =
        this.selectedFile.status === EFileProcessStatus.COMPLETED
          ? EFileProcessStatus.VERIFIED
          : EFileProcessStatus.COMPLETED;
      await filesService.update({
        id: this.selectedFile.id.toString(),
        status: newStatus,
      });
      runInAction(() => {
        if (this.selectedFile) {
          this.selectedFile = { ...this.selectedFile, status: newStatus };
        }
      });
      await this.init();
    }
    this.setLoading(false);
  }

  @bind
  setSelectedFile(file: IFileLink | null): void {
    runInAction(() => {
      if (this.selectedFile?.id === file?.id) {
        this.selectedFile = null;
      } else {
        this.selectedFile = file;
      }
    });
  }

  @bind
  async deleteSelectedFile() {
    if (!this.selectedFile) return false;

    const res = await filesService.delFileById(this.selectedFile.id);

    if (!res) return false;

    runInAction(() => {
      this.listFiles = this.listFiles?.filter((files) => files.id !== this.selectedFile?.id);
      this.selectedFile = null;
    });

    return true;
  }

  async loadDictorsInfo() {
    try {
      if (!this.selectedFile?.id) return;

      const dictors = await filesService.getModelsByFileId<IDictor>(
        this.selectedFile.id.toString()
      );
      if (dictors) {
        runInAction(() => {
          this.dictorsInfo = [...dictors];
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async updateDictorExclude(id: number, isExcluded: boolean) {
    await filesService.updateModel({ id, isExcluded });
    const dictor = this.dictorsInfo?.find((d) => d.id === id);
    if (!dictor) return;
    runInAction(() => {
      dictor.isExcluded = isExcluded;
    });
  }

  @bind
  clear() {
    this.listFiles = [];
    this.selectedFile = null;
    this.loading = false;
    this.dictorsInfo = null;
  }
}

export const filesStore = new FilesStore();
