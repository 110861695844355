import { GridStore } from '@/stores/grid.store';
import { useCallback } from 'react';

interface ISelectionModel<T> {
  deselectAll: () => void;
  deselectOne: (item: T) => void;
  selectAll: () => void;
  selectMany: (items: T[]) => void;
  selected: T[];
}

export const useSelectionModel = <T>(store: GridStore<T>): ISelectionModel<T> => {
  const deselectOne = useCallback(
    (item: T): void => {
      const c = store.checkedItems.filter((i) => i !== item);
      store.setCheckedItems(c);
    },
    [store]
  );

  const selectAll = useCallback((): void => {
    store.setCheckedItems([...store.data]);
  }, [store]);

  const deselectAll = useCallback(() => {
    store.resetChecked();
  }, [store]);

  const selectMany = useCallback(
    (items: T[]): void => {
      store.setCheckedItems(items);
    },
    [store]
  );

  const selected = store.checkedItems;

  return {
    deselectAll,
    deselectOne,
    selectAll,
    selectMany,
    selected,
  };
};
