import { Box, Breadcrumbs, Link, Tooltip, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useMemo } from 'react';

interface IBreadcrumbsSection {
  currentPath: string;
  onDirectoryClick: (absPath: string) => void;
}

interface ICrumbElement {
  isLast: boolean;
  folder: string;
  path: string;
  onDirectoryClick: (absPath: string) => void;
  tooltip: string;
}

const CrumbElement = (props: ICrumbElement) => {
  const { isLast, folder, path, onDirectoryClick, tooltip } = props;

  return (
    <Tooltip
      title={tooltip}
      sx={{
        display: 'inline-block',
        maxWidth: '200px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
      children={
        isLast ? (
          <Typography color='textPrimary' key={path}>
            {folder}
          </Typography>
        ) : (
          <Link
            color='inherit'
            href='#'
            underline='hover'
            onClick={() => onDirectoryClick(path)}
            key={path}
          >
            {folder}
          </Link>
        )
      }
    />
  );
};

export const BreadCrumbsSection = (props: IBreadcrumbsSection) => {
  const { currentPath, onDirectoryClick } = props;

  const crumbsArray = useMemo(
    () => currentPath.substring(currentPath[0] === '/' ? 1 : 0).split('/'),
    [currentPath]
  );

  return (
    <Box sx={{ marginBottom: '15px', paddingLeft: '16px' }}>
      <Breadcrumbs
        aria-label='breadcrumb'
        separator={<NavigateNextIcon fontSize='small' />}
        maxItems={4}
      >
        <CrumbElement
          key={'home'}
          folder={'~'}
          tooltip='Root'
          isLast={currentPath.length === 0}
          path={''}
          onDirectoryClick={onDirectoryClick}
        />
        {crumbsArray.map((folder, index, array) => {
          const path = array.slice(0, index + 1).join('/');
          const isLast = index === array.length - 1;

          return (
            <CrumbElement
              key={path}
              folder={folder}
              tooltip={folder}
              isLast={isLast}
              path={path}
              onDirectoryClick={onDirectoryClick}
            />
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};
