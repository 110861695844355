import { prepareRoleName } from '@/utils/prepareRoleName';
import { IRoleWithPrivilegesInGroups, PrivilegeCode } from '@/types/role';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Modal, MultiSelectField, TextField, useForm } from 'stc-ui-kit';
import { useRolesContext } from '../../context/RolesContext';
import rolesService from '@/services/roles.service';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';
import { useValidation } from '@/hooks/useValidation';

interface Props {
  onClose: () => void;
  initialRoleData: IRoleWithPrivilegesInGroups | null;
}

interface CreateRoleFormValues {
  name: string;
  privileges: PrivilegeCode[];
}

export const CreateRoleModal: React.FC<Props> = ({ onClose, initialRoleData }) => {
  const { t } = useTranslation();
  const { sections, fetchData } = useRolesContext();
  const { havePermissions } = useUserPermissionsContext();
  const { createConfirmButton, editConfirmButton } = permissionControl.administration.roles;
  const { validateString } = useValidation();

  const { form } = useForm<CreateRoleFormValues>({
    values: {
      name: initialRoleData ? prepareRoleName(initialRoleData.roleName) : '',
      privileges: initialRoleData?.privilegeGroups.flatMap((group) => group.privileges) || [],
    },
  });

  const {
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = form;

  const onSubmit = async (values: CreateRoleFormValues) => {
    let responce;
    if (initialRoleData) {
      responce = await rolesService.updateRole({
        ...initialRoleData,
        roleName: values.name,
        privileges: values.privileges,
      });
    } else {
      responce = await rolesService.createRole({
        roleName: values.name.trim(),
        privileges: values.privileges,
      });
    }

    if (responce) {
      await fetchData();
      handleClose();
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Form data-testid='' form={form}>
      <Modal
        onClose={handleClose}
        onConfirm={handleSubmit(onSubmit)}
        title={initialRoleData ? t('managingRoles.roleEdit') : t('managingRoles.roleCreation')}
        data-testid=''
        buttonConfirmText={initialRoleData ? t('save') : t('create')}
        isConfirmButtonHidden={
          initialRoleData
            ? !havePermissions(editConfirmButton)
            : !havePermissions(createConfirmButton)
        }
        buttonCloseText={t('cancel')}
        isConfirmDisabled={
          Object.keys(errors).length > 0 || !isDirty || !form.watch().privileges.length
        }
      >
        <Box display='flex' flexDirection='column' gap='16px' component='form'>
          <TextField
            placeholder={t('managingRoles.enterRoleName')}
            id='name'
            label={t('managingRoles.roleName')}
            data-testid=''
            isRequired
            validate={validateString({ isRequired: true, min: 3, max: 50 })}
          />

          <MultiSelectField
            id='privileges'
            data-testid=''
            label={t('managingRoles.privileges')}
            isRequired
            placeholder={t('managingRoles.choosePrivileges')}
            options={sections
              .flatMap((section) => section.privilegeGroups)
              .flatMap((group) => group.privileges)
              .map((p) => ({
                id: p,
                label: t(`privileges.${p}.title`, ''),
              }))}
          />
        </Box>
      </Modal>
    </Form>
  );
};
