import { TextFieldValue } from 'stc-ui-kit';
import * as yup from 'yup';

export const withErrorHandlingValidation =
  (schema: yup.StringSchema<string | null | undefined, yup.AnyObject, undefined, ''>) =>
  (value: TextFieldValue): string | boolean => {
    try {
      const v = schema;

      v.validateSync(value);

      return true;
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        return error.message;
      }

      return true;
    }
  };
