import { TDirType } from '@/components/FileDialog/types';
import { Model } from './model';

export type FileType = 'audio' | 'img';

export enum EFileProcessStatus {
  INPROGRESS = 'IN_PROGRESS',
  VERIFIED = 'VERIFIED',
  NEW = 'NEW',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  DUPLICATE = 'DUPLICATE',
  NOMODEL = 'NO_MODEL',
}

export interface IMedialFile {
  id: number;
  path: string;
  isExternal: boolean;
  size: number;
}

interface IFileModelLink {
  id: number;
  isSelected: boolean | null;
  fileModel: Model;
}

type User = {
  id: number;
  isEnabled: boolean;
  firstname: string;
  lastname: string;
  middlename: string | null;
  username: string;
};

interface IMetadataItem {
  data: {
    channelCount: number;
    durationSec: number;
    sampleCount: number;
    sampleFormatName: string;
    sampleRate: number;
    totalTimeSpeech: number;
  };
  type: string;
}

export interface IFileLink {
  id: number;
  comment: string | null;
  fileGroup: FileGroupType;
  fileProcessId: number;
  name: string;
  originalName: string | null;
  creator: User;
  createdTime: string;
  editor: User | null;
  editedTime: string | null;
  type: FileType;
  status: EFileProcessStatus;
  modelsCount: number | null;
  cardsCount: number;
  fileData: Record<string, string>;
  file: IMedialFile;
  fileModelLinks: IFileModelLink[] | null;
  path: string;
  fileProcessMetadata?: { metadataItems: IMetadataItem[] };
}

export interface ITableItem extends Omit<IFileLink, 'id'> {
  id: string;
}

export type FileGroupType = 'FACE' | 'VOICE' | 'DOC' | null;

export interface IProcessPathRequest {
  path: string;
  type: TDirType;
  processingParameters: {
    sliceOnly: boolean;
    buildModels: boolean;
  };
}

export interface IFileProcessUpdate {
  id: string;
  status: EFileProcessStatus;
}
export interface IModelUpdate {
  id: number;
  isExcluded: boolean;
}

export interface IFileModelView<T> {
  id: number;
  path: string;
  fileHash: string;
  isExcluded: boolean;
  cardsCount: string;
  modelQuality: number;
  data: T;
  sourcePath: string;
  type: 'audio' | 'img';
}

export interface IMediaData {
  snr?: number;
  rtRms?: number;
  ranges?: number[];
  rtAver?: number;
  quality?: number;
  rtCount?: number;
  algorithm?: string;
  timeSpeech?: number;
  clippingRanges?: number;
  timeTonalNoise?: number;
  lowestSpeechFreqHz?: number;
  highestSpeechFreqHz?: number;
  maxUnclippedInterval?: number;
}

export interface IFileProcessResponse {
  errors: {
    description: string;
  }[];
}
