import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCardCreationContext } from '../hooks/useCardCreationContext';
import { Stack } from '@mui/material';
import { CardDataSection } from './CardDataSection';
import { makeStyles } from '@/hooks/makeStyles';
import { CardModelsSection } from './CardModelsSection';
import { CardGroupBox, ResizableContainer, ResizableContainerDirection } from 'stc-ui-kit';
import TemplateLayoutRenderer from './CardForm/TemplateLayoutRenderer';
import { appColors } from '@/Providers/ThemeProvider/colors';

const useStyles = makeStyles()(() => ({
  container: {
    '*:not([class*="VirtualizedList-module__virtualizedList"])': {
      scrollbarWidth: 'none!important' as 'none',
    },
  },
  details: {
    width: '100%',
    overflow: 'auto',
    padding: '8px',
  },
  wrapper: {
    '[class*="CardGroupBox-module__group"]': {
      backgroundColor: appColors.darkThemeBackground,
    },
  },
}));

export const CardCreationMain: React.FC = () => {
  const { t } = useTranslation();
  const { selectedTemplate, hideAvatar } = useCardCreationContext();
  const { classes } = useStyles();
  const [isVisible, setIsVisible] = useState(true);

  return (
    <Stack
      direction='row'
      position={'relative'}
      height={'100%'}
      width={'100%'}
      className={classes.container}
    >
      {!hideAvatar && <CardDataSection />}

      <Stack
        direction={'column'}
        spacing={2}
        height={'100%'}
        flexGrow={1}
        maxWidth={hideAvatar ? '100%' : 'calc(100% - 424px)'}
        className={classes.wrapper}
      >
        <CardGroupBox
          title={t('multipass.generalInfo')}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        >
          {selectedTemplate?.data && (
            <ResizableContainer
              resizeDirection={ResizableContainerDirection.Bottom}
              defaultHeight={300}
              minHeight={100}
              isFlexGrow
              getInitialHeight={() => 300}
              saveInitialHeight={() => null}
            >
              <div className={classes.details}>
                <TemplateLayoutRenderer templateData={selectedTemplate.data} />
              </div>
            </ResizableContainer>
          )}
        </CardGroupBox>

        <CardModelsSection />
      </Stack>
    </Stack>
  );
};
