import { DefaultSortState, GridStore } from '@/stores/grid.store';
import { useEffect, useRef } from 'react';
import { DataProvider, IDataGridStoreState } from 'types/grid';

export const useGridStore = <T>({
  dataProvider,
  initialState,
  defaultSortState,
  reloadOnInit = true,
  withInfiniteScroll = false,
}: {
  dataProvider: DataProvider<T>;
  initialState?: Partial<IDataGridStoreState<T>>;
  defaultSortState?: DefaultSortState<T>;
  reloadOnInit?: boolean;
  withInfiniteScroll?: boolean;
}) => {
  const storeRef = useRef(
    new GridStore<T>(dataProvider, initialState, defaultSortState, withInfiniteScroll)
  );

  useEffect(() => {
    if (reloadOnInit) {
      void storeRef.current.reload();
    }
  }, [reloadOnInit]);

  return { store: storeRef.current };
};
