import { TextField, TextFieldValue } from 'stc-ui-kit';
import { useTemplateFieldContext } from '../../../hooks/useTemplateFieldContext';
import * as yup from 'yup';

export const StringTemplateField = () => {
  const { fieldData, fieldName, language, validationErrorMessage, customTypeFieldId, readonly } =
    useTemplateFieldContext();

  const placeholder =
    fieldData.placeholders?.find((p) => p.locale === language)?.value || fieldData.placeholder;

  //TODO validation
  // const validate = (val: TextFieldValue) => {
  //   if (!fieldData.validator) return true;

  //   try {
  //     const schema = yup
  //       .string()
  //       .nullable()
  //       .matches(new RegExp(fieldData.validator), validationErrorMessage);

  //     schema.validateSync(val);

  //     return true;
  //   } catch (error) {
  //     if (error instanceof yup.ValidationError) {
  //       return error.message;
  //     }

  //     return true;
  //   }
  // };

  return (
    <TextField
      id={customTypeFieldId || fieldData.id}
      //TODO validation
      isRequired={fieldData.required}
      isClearable
      placeholder={placeholder}
      label={fieldName}
      data-testid=''
      isSpaceAllowed
      height={fieldData.view === 'Textarea' ? 118 : undefined}
      isReadOnly={readonly}
      //TODO not working. check after uikit update
      // rows={fieldData.view === 'Textarea' ? 3 : 1}
      //TODO validation
      // validate={validate}
    />
  );
};
