import { StcGridTable } from '@/components/Grid/StcGridTable';
import { SvgIcon } from '@/components/SvgIcon/SvgIcon';
import { GridStore } from '@/stores/grid.store';
import { User } from '@/types/user';
import { Box, CircularProgress } from '@mui/material';
import { ReactComponent as BlockSvg } from 'assets/icons/block.svg';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'stc-ui-kit';
import { prepareRoleName } from '@/utils/prepareRoleName';
import getFullName from '@/utils/getFullName';

interface Props {
  store: GridStore<User>;
  loading: boolean;
}

export const UsersGrid: React.FC<Props> = observer(({ store, loading }) => {
  const { t } = useTranslation();

  return (
    <Box flex='1' display='flex' flexDirection='column'>
      {loading ? (
        <Box alignItems='center' display='flex' justifyContent='center' height={'100%'}>
          <CircularProgress />
        </Box>
      ) : (
        <StcGridTable
          store={store}
          columns={[
            {
              id: 'username',
              title: t('login'),
              isVisible: true,
              width: 180,
              renderRowCell: (row) => row.username,
            },

            {
              id: 'name',
              width: 370,
              isVisible: true,
              title: t('name'),
              renderRowCell: (row) => getFullName(row),
            },
            {
              id: 'roles',
              title: t('role'),
              width: 240,
              isVisible: true,
              renderRowCell: (row) => {
                const roles = row.roles;

                return roles.length > 1 ? (
                  <Tooltip tooltip={roles.map((role) => prepareRoleName(role.roleName)).join(', ')}>
                    <span>
                      {roles.length} {t('roles')}
                    </span>
                  </Tooltip>
                ) : (
                  <span>{roles[0] ? prepareRoleName(roles[0].roleName) : ''} </span>
                );
              },
            },
            {
              id: 'groups',
              title: t('groups.groups'),
              renderRowCell: (row) => row?.department?.groupName || '',
              isVisible: true,
              width: 100,
            },
            {
              id: 'enabled',
              title: t('isBanned'),
              renderRowCell: (row) => {
                return row.isEnabled ? '' : <SvgIcon IconComponent={BlockSvg} color='red' />;
              },
              isVisible: true,
              width: 150,
            },
            {
              id: 'description',
              title: t('commentary'),
              isVisible: true,
              renderRowCell: (row) => row.description,
              width: 500,
            },
          ]}
        />
      )}
    </Box>
  );
});
