import { PhotosCarousel } from '@/modules/multipass/components';
import { Box, Stack, Typography } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { Button, ButtonType, Form, FormReturn, Separator } from 'stc-ui-kit';
import { useCardCreationContext } from '../../hooks/useCardCreationContext';
import { Loader } from '@/components/common/Loader';
import { useTranslation } from 'react-i18next';
import { CopyIdButton } from '@/components/common/ui/CopyIdButton';
import { FaceData } from '@/types/model';
import TemplateLayoutRenderer from '../CardForm/TemplateLayoutRenderer';
import { CardFormData } from '@/types/card';
import { WmsPlayer } from '@/components/WmsPlayer';
import { useEffect, useMemo, useState } from 'react';
import CardModelsAvatars from '@/components/FileDialog/CardModelsAvatars';
import { getCardAliasWithCreationDate } from '@/utils/getCardAliasWithCreationDate';

interface Props {
  loading: boolean;
  form: FormReturn<FieldValues>;
  onSubmit: (values: CardFormData) => Promise<void>;
}

export const CardQuickViewContent: React.FC<Props> = ({ loading, form, onSubmit }) => {
  const { t } = useTranslation();
  const { isFilesLoading, cardData, selectedTemplate, fileLinks } = useCardCreationContext();

  // set to true to avoid blinking when going from 'many selected' to 'one selected'
  const [isLoading, setIsLoading] = useState(true);

  // for combined isLoading state (to avoid blinking)
  useEffect(() => {
    const isAnythingLoading = loading || isFilesLoading;
    if (isAnythingLoading) {
      setIsLoading(true);

      return;
    }

    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 100);

    return () => {
      clearTimeout(delay);
    };
  }, [loading, isFilesLoading]);

  const {
    formState: { errors, isDirty, isSubmitting },
  } = form;
  const isSubmitDisabled = isSubmitting || !isDirty || Object.keys(errors).length > 0;

  const photoNormalizedPath = (cardData?.faceModel?.data as FaceData)?.files.find(
    (f) => f.type === 'FACE_NORMALIZED'
  )?.data.uri;

  const fieldsToDisplay = selectedTemplate?.data.fields.filter((f) => f.required);

  const audioPath = cardData?.audioPath || fileLinks[0]?.path;

  const playerComponentWithAudio = useMemo(() => {
    return <WmsPlayer fileName={audioPath} isCompact />;
  }, [audioPath]);

  const playerComponentWithoutAudio = useMemo(() => {
    return <WmsPlayer fileName={null} isCompact />;
  }, []);

  return isLoading ? (
    <Loader text='' />
  ) : (
    <Stack display='flex' height={'100%'} position={'relative'}>
      <Box display='flex' alignItems='center' justifyContent={'space-between'} padding={'16px'}>
        <Typography
          variant='subtitle2'
          textOverflow={'ellipsis'}
          overflow={'hidden'}
          whiteSpace={'nowrap'}
          marginRight={'10px'}
        >
          {getCardAliasWithCreationDate(cardData, selectedTemplate)}
        </Typography>
        <CopyIdButton id={cardData?.id} />
      </Box>

      <Separator horizontal />

      <Stack spacing={2} padding={'16px'} overflow={'auto'}>
        <Stack sx={{ minHeight: '376px' }} position={'relative'}>
          <PhotosCarousel withLoader />
        </Stack>

        {/* нужны два разных компонента, потому что иначе плеер не меняет отображение 
        при переходе от "есть аудио" к "нет аудио" */}
        {!!audioPath && playerComponentWithAudio}
        {!audioPath && playerComponentWithoutAudio}

        <Stack spacing={1}>
          <Typography fontSize={'12px'}>{t('cards.biometricModels')}</Typography>
          <CardModelsAvatars
            photoPath={photoNormalizedPath || ''}
            faceModelQuality={cardData?.faceModel?.quality}
            voiceModelQuality={cardData?.voiceModel?.quality}
          />
        </Stack>

        <Form form={form} data-testid=''>
          {fieldsToDisplay && selectedTemplate?.data && (
            <TemplateLayoutRenderer
              templateData={{ ...selectedTemplate.data, fields: fieldsToDisplay }}
              isColumn
            />
          )}
        </Form>
      </Stack>

      <Separator horizontal />

      <Box display='flex' alignItems='center' justifyContent={'flex-end'} padding={'16px'}>
        <Button
          type={ButtonType.Text}
          onClick={form.handleSubmit(onSubmit)}
          isDisabled={isSubmitDisabled}
        >
          {t('save')}
        </Button>
      </Box>
    </Stack>
  );
};
