import { Collapse, FieldArrayManager } from 'stc-ui-kit';
import { useTemplateFieldContext } from '../../../hooks/useTemplateFieldContext';
import { useCardCreationContext } from '../../../hooks/useCardCreationContext';
import TemplateLayoutRenderer from '../TemplateLayoutRenderer';
import { makeStyles } from '@/hooks/makeStyles';

const useStyles = makeStyles()(() => ({
  fieldArray: {
    "[class*='FieldArrayManager-module__button']": {
      position: 'relative',
      top: '38px',
    },
  },
}));

export function MultiInputTemplateField({ isColumn }: { isColumn?: boolean }) {
  const { fieldData, fieldName, templateData, readonly } = useTemplateFieldContext();
  const { formHooks } = useCardCreationContext();
  const { classes } = useStyles();

  const customType = templateData?.customTypes?.find((t) => t.id === fieldData.customType);

  if (!customType) return null;

  return (
    <Collapse title={fieldName} data-testid=''>
      <FieldArrayManager
        className={classes.fieldArray}
        form={formHooks}
        fieldGroupName={fieldData.id}
        maxLength={fieldData.maxCount || 100}
        isCompact
        renderItem={(_, groupName, index) => (
          <TemplateLayoutRenderer
            isColumn={!!isColumn}
            templateData={customType}
            customTypeIdGetter={(fieldId: string) => `${groupName}.${index}.${fieldId}`}
          />
        )}
        newItem={null}
        data-testid=''
        isReadOnly={readonly}
      />
    </Collapse>
  );
}
