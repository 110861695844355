import { FC } from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { iconColors } from '@/styles/theme/colors';
import { useTranslation } from 'react-i18next';
import { EFileProcessStatus } from '@/types/file';
import { ReactComponent as DuplicateIcon } from 'assets/icons/duplicate.svg';
import { NoAccounts } from '@mui/icons-material';
import { appColors } from '@/Providers/ThemeProvider/colors';

export interface IDictorCellProps {
  status: EFileProcessStatus;
  modelcount: number;
  cardCount: number;
}

export const DictorCell: FC<IDictorCellProps> = (props) => {
  const { status } = props;
  const { t } = useTranslation();

  return (
    <Box display={'flex'} color={'inherit'}>
      {status === EFileProcessStatus.INPROGRESS && (
        <Tooltip id='icon-inProgress' title={t('inProgress')}>
          <CircularProgress size={25} sx={{ color: iconColors['--orange-800'] }} />
        </Tooltip>
      )}
      {status === EFileProcessStatus.FAILED && (
        <Tooltip id='icon-failed' title={t('error')}>
          <PriorityHighIcon sx={{ color: iconColors['--light-red'] }} />
        </Tooltip>
      )}
      {status === EFileProcessStatus.COMPLETED && (
        <Tooltip id='icon-complete' title={t('multipass.completed')}>
          <DoneIcon sx={{ color: iconColors['--light-green'] }} />
        </Tooltip>
      )}
      {status === EFileProcessStatus.VERIFIED && (
        <>
          <Tooltip id='icon-verified' title={t('multipass.tooltipVerified')}>
            <DoneAllIcon sx={{ color: iconColors['--light-green'] }} />
          </Tooltip>
        </>
      )}
      {status === EFileProcessStatus.NEW && (
        <Tooltip id='icon-new' title={t('new')}>
          <MoreHorizIcon sx={{ color: 'primary' }} />
        </Tooltip>
      )}
      {status === EFileProcessStatus.DUPLICATE && (
        <Tooltip id='icon-duplicate' title={t('multipass.tooltipDuplicate')}>
          <DuplicateIcon />
        </Tooltip>
      )}
      {status === EFileProcessStatus.NOMODEL && (
        <Tooltip id='icon-nomodel' title={t('attachedFileStatus.NO_MODEL')}>
          <NoAccounts sx={{ color: appColors.darkGrey }} />
        </Tooltip>
      )}
    </Box>
  );
};
