import { appColors } from '@/Providers/ThemeProvider/colors';
import { IFace } from '@/types/card';
import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ModelQualityIconComponent } from '../../components/icons/ModelQualityIconComponent';
import { useClickOutside } from '@/hooks/useClickOutside';
import { ContextMenu, ListItemType, ListItems } from 'stc-ui-kit';
import { useTranslation } from 'react-i18next';
import { useCardCreationContext } from '../../CardCreation/hooks/useCardCreationContext';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';

export const FaceBox = ({
  face,
  imagePosition,
  imageRatio,
  isMultipleFaces,
  createNewEntry,
}: {
  face: IFace;
  imagePosition: { x: number; y: number };
  imageRatio: number;
  isMultipleFaces: boolean;
  createNewEntry: (modelId: number) => void;
}) => {
  const [isFaceSelected, setIsFaceSelected] = useState(false);
  const { setCardModel, cardData } = useCardCreationContext();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [wrapperWidth, setWrapperWidth] = useState(0);

  const { havePermissions } = useUserPermissionsContext();
  const { setAsMainButton, createNewEntryButton } =
    permissionControl.enrollment.cardCreation.modelsSection;

  const isBoxSmall = wrapperWidth < 75;

  useClickOutside({
    elementRef: wrapperRef,
    handler: () => setIsFaceSelected(false),
  });

  useEffect(() => {
    const element = wrapperRef?.current;

    if (!element) return;

    const observer = new ResizeObserver((entries) => {
      const target = entries[0].target;
      setWrapperWidth(target.clientWidth);
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, []);

  const contextMenuItems: ListItems = [
    [
      {
        id: 'setAsMain',
        name: t('multipass.setAsMain'),
        onClick: () => setCardModel(face.modelId),
        type: ListItemType.Simple,
        isDisabled: face.modelId === cardData?.faceModel?.id || !havePermissions(setAsMainButton),
      },
      {
        id: 'createNewEntry',
        name: t('multipass.createNewEntry'),
        onClick: () => createNewEntry(face.modelId),
        type: ListItemType.Simple,
        isDisabled: !havePermissions(createNewEntryButton),
      },
    ],
  ];

  const faceBoxContent = (
    <Box
      ref={wrapperRef}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: face.area.w * imageRatio,
        height: face.area.h * imageRatio,
        position: 'absolute',
        top: face.area.y * imageRatio + imagePosition.y,
        left: face.area.x * imageRatio + imagePosition.x,
        zIndex: isFaceSelected ? 101 : 100,
        padding: isBoxSmall ? '2px' : '8px',
        border: `5px solid ${isFaceSelected ? appColors.blue900 : appColors.green900}`,
        cursor: 'pointer',
      }}
      onClick={() => setIsFaceSelected(true)}
    >
      <Box
        display='flex'
        marginLeft='auto'
        alignSelf='flex-start'
        alignItems='center'
        gap={isBoxSmall ? '4px' : '16px'}
      >
        <ModelQualityIconComponent
          wrapperSx={
            isBoxSmall
              ? {
                  padding: '2px',
                }
              : {}
          }
          size={isBoxSmall ? 15 : 24}
          quality={face.quality}
          type='img'
        />
      </Box>
    </Box>
  );

  return isMultipleFaces ? (
    <ContextMenu
      onOpen={() => setIsFaceSelected(true)}
      onClose={() => setIsFaceSelected(false)}
      items={contextMenuItems}
    >
      {faceBoxContent}
    </ContextMenu>
  ) : (
    faceBoxContent
  );
};
