import { Stack } from '@mui/material';
import { FunctionComponent, SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@/hooks/makeStyles';
import { Separator, Sidebar, icons } from 'stc-ui-kit';
import { ModelsHeader } from './ModelsHeader/ModelsHeader';
import { MenuItemType } from '@/types/ui.types';
import { observer } from 'mobx-react';
import { useDataStore } from '@/Providers/StoreProvider';
import { useCardCreationContext } from '../../hooks/useCardCreationContext';
import { VoiceModels } from './VoiceModels';
import { FaceModels } from './FaceModels/FaceModels';
import { BackdropLoader } from '@/components/loaders/BackdropLoader';
import { appColors } from '@/Providers/ThemeProvider/colors';

export interface BiometricModelsMenuItem {
  type: MenuItemType;
  text: string;
  isActive: boolean;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

const SIDEBAR_WIDTH = 200;

const useStyles = makeStyles()(() => ({
  sideBarWrapper: {
    display: 'flex',
    '[class*="ResizableWidthContainer-module__resizer"]': {
      display: 'none',
    },
    '[class*="ResizableWidthContainer-module__resizableWidth"]:not([class*="ResizableWidthContainer-module__disabled"])':
      {
        minWidth: `${SIDEBAR_WIDTH}px !important`,
      },
    '[class*="Separator-module__separator"]': {
      display: 'none',
    },
    '[class*="Sidebar-module__sidebarLogo"]': {
      display: 'none',
    },
  },
}));

export const BiometricModels = observer(() => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { cardData, isFilesLoading, isPhotosLoading } = useCardCreationContext();
  const cardId = cardData?.id || 0;
  const { uiStore } = useDataStore();
  const isCollapsed = uiStore.getOpenedCard(cardId)?.sideMenuCollapsed || false;
  const activeMenuItem = uiStore.getOpenedCard(cardId)?.activeSideMenuItem || 'face';

  const handleItemClick = (clickedType: string) => {
    const type = clickedType as MenuItemType;
    uiStore.setActiveSideMenuItem(cardId, type);
  };

  const toggleCollapsed = () => {
    uiStore.setSideMenuCollapsed(cardId, !isCollapsed);
  };

  const staticTabs = [
    {
      route: 'face',
      title: t('cards.face'),
      icon: icons.IconFaceScan,
    },
    {
      route: 'voice',
      title: t('cards.voice'),
      icon: icons.IconVoiceScan,
    },
  ];

  const getIsTabActive = (tabRoute: string) => tabRoute === activeMenuItem;

  return (
    <Stack direction={'row'} flexGrow={1} maxHeight={'100%'}>
      <div className={classes.sideBarWrapper}>
        <Sidebar
          getIsTabActive={getIsTabActive}
          onTabClick={handleItemClick}
          isVisible={!isCollapsed}
          onVisibilityChange={toggleCollapsed}
          width={SIDEBAR_WIDTH}
          onWidthChange={() => null}
          staticTabs={staticTabs}
          dynamicTabs={[]}
          onTabsClose={() => null}
          onTabsOrderChange={() => null}
        />
      </div>

      <Separator vertical maxSize />

      <Stack
        direction={'column'}
        flexGrow={1}
        height={'100%'}
        position={'relative'}
        maxWidth={`calc(100% - ${isCollapsed ? 50 : SIDEBAR_WIDTH + 1}px)`}
        sx={{ backgroundColor: appColors.darkThemeBackground }}
      >
        <ModelsHeader />
        {activeMenuItem === 'face' && <FaceModels />}
        {activeMenuItem === 'voice' && <VoiceModels />}
      </Stack>

      <BackdropLoader open={isFilesLoading || isPhotosLoading} />
    </Stack>
  );
});
