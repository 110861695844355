import { RefObject, useEffect, useRef } from 'react';

export const useClickOutside = <T extends HTMLElement = HTMLElement>({
  elementRef,
  handler,
}: {
  elementRef: RefObject<T>;
  handler: (e: MouseEvent) => void;
}) => {
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;

      if (elementRef.current && !elementRef.current.contains(target)) {
        handlerRef.current(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [elementRef]);
};
