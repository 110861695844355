import { Box, Stack } from '@mui/material';
import { GalleryCard } from './GalleryCard';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { useEffect, useRef } from 'react';

interface GalleryProps {
  faceCoordinates?: { x: number; y: number; width: number; height: number };
  deviceStream: MediaStream | null;
}

export const Gallery = (props: GalleryProps) => {
  const { faceCoordinates, deviceStream } = props;
  const videoElRef = useRef<HTMLVideoElement>(null);
  const defaultFaceCoordinates = {
    x: 50,
    y: 50,
    width: 150,
    height: 190,
  };

  const coordinates = faceCoordinates ? faceCoordinates : defaultFaceCoordinates;

  const pictures: string[] = [
    require('../../assets/__mocks__/mock_face1.png'),
    require('../../assets/__mocks__/mock_face2.png'),
    require('../../assets/__mocks__/mock_face3.png'),
  ];

  useEffect(() => {
    const video = videoElRef.current;
    if (deviceStream) {
      if (video) {
        video.srcObject = deviceStream;

        const playPromise = video.play();

        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              //TODO: забери данные
            })
            .catch((error) => {
              //TODO: обработай
            });
        }
      }

      return;
    }
    if (video) {
      video.srcObject = null;
    }
  }, [deviceStream]);

  return (
    <Stack direction={'row'} height={'100%'} width={'100%'} margin={'8px'}>
      {pictures.map((photoSrc, index) => (
        <GalleryCard key={index} photoSrc={photoSrc} />
      ))}

      <Box position='relative' height={'100%'} margin={'8px'}>
        <video
          width='628px'
          height='auto'
          ref={videoElRef}
          style={{
            objectFit: 'cover',
            border: `1px solid ${appColors.steal200}`,
            borderRadius: '4px',
          }}
          id='webcam'
        >
          Video stream not available.
        </video>

        <div
          style={{
            position: 'absolute',
            top: coordinates.y,
            left: coordinates.x,
            width: coordinates.width,
            height: coordinates.height,
            border: `4px solid ${appColors.green}`,
          }}
        />
      </Box>
    </Stack>
  );
};
