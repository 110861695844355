import { IDataGridStoreData, IDataGridStoreState, PaginatonType } from 'types/grid';

import { CursorPaginator } from './cursor-paginator';
import { OffsetPaginator } from './offset-paginator';

import { GridStore } from '@/stores/grid.store';

export const LOAD_ALL_LIMIT = 10000;

export interface IPaginator<T> {
  gridStore: GridStore<T>;

  resetCurrentPage: () => void;
  setCurrentPage: (page: number) => void;
  loadData: (filters: IDataGridStoreState<T>) => Promise<IDataGridStoreData<T>>;
  loadAllData: () => Promise<T[]>;

  getLastPage: () => void;
  getFirstPage: () => void;

  hasNextPage: boolean;
  hasPrevPage: boolean;
}

export const paginators: {
  [key in PaginatonType]: new <T>(gridStore: GridStore<T>) => IPaginator<T>;
} = {
  cursor: CursorPaginator,
  offset: OffsetPaginator,
};
