import { appColors } from '@/Providers/ThemeProvider/colors';
import { backgroundColors, borderColors } from '@/styles/theme/colors';
import { styled } from '@mui/material';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';

export const gridHeaderFontStyles = {
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 500,
  color: appColors.black,
};

export const StyledDataGrid = styled(DataGrid)<DataGridProps>(() => {
  return {
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
      {
        outline: 'none',
      },
    '&.MuiDataGrid-root .MuiDataGrid-cell': {
      padding: '0 16px',
    },
    '.MuiDataGrid-cellContent': {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '17px',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
      backgroundColor: appColors.white,
      padding: '0 16px',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
      color: appColors.primary,
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
      ...gridHeaderFontStyles,
    },
    '&.MuiDataGrid-root .MuiDataGrid-iconSeparator': {
      color: borderColors['--steal-200'],
    },
    '&.MuiDataGrid-root': {
      borderColor: borderColors['--steal-200'],
      borderRadius: '4px',
    },
    '.MuiToolbar-root .MuiTablePagination-selectLabel': {
      display: 'none',
    },
    '.MuiToolbar-root .MuiTablePagination-displayedRows': {
      display: 'none',
    },
    '&.MuiDataGrid-root .Mui-checked': {
      color: backgroundColors['--violet'],
    },
    '&.MuiDataGrid-root .Mui-selected': {
      backgroundColor: '#e5ebf0',
      '&:hover': {
        backgroundColor: '#bdc4c9',
      },
    },
    // disable blue outline on focus
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-pinnedRow, & .MuiDataGrid-pinnedRow:focus': {
      color: 'gray',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left 0',
      backgroundSize: '36px',
      backgroundImage: 'url("/assets/PinIcon.svg")',
    },
    '& .MuiDataGrid-footerContainer': {
      height: '50px',
      '& .MuiSelect-select': {
        padding: '0 !important',
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
  };
});
