import { AvatarGroup } from '@mui/material';
import { CardAvatar } from '../../modules/multipass/FilingCabinet/components/CardsGrid/CardAvatar';
import { getModelIcon } from '@/modules/multipass/utils/getModelIcon';
import { makeStyles } from '@/hooks/makeStyles';

const useStyles = makeStyles()(() => ({
  wrapper: {
    '.MuiAvatar-root': {
      border: 'none',
    },

    '.MuiAvatarGroup-avatar': {
      backgroundColor: 'transparent',
    },
  },
}));

export default function CardModelsAvatars({
  photoPath,
  faceModelQuality,
  voiceModelQuality,
}: {
  photoPath: string;
  faceModelQuality: number | undefined;
  voiceModelQuality: number | undefined;
}) {
  const { classes } = useStyles();

  return (
    <AvatarGroup
      className={classes.wrapper}
      max={3}
      sx={{ alignItems: 'center', display: 'inline-flex', width: '75px' }}
      spacing={6}
    >
      <CardAvatar src={photoPath} />
      {getModelIcon({ quality: voiceModelQuality ?? null, type: 'audio' })}
      {getModelIcon({ quality: faceModelQuality ?? null, type: 'img' })}
    </AvatarGroup>
  );
}
