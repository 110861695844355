import { CustomTypeFieldDescriptor, FieldDescriptor } from '@/types/cardTemplate';
import { RadioGroupField } from 'stc-ui-kit';
import { Language } from '@/types';

export const renderDictFilterField = (
  field: FieldDescriptor | CustomTypeFieldDescriptor,
  label: string,
  language: Language
) => {
  if (!field.dictionary) return null;

  const options = field.dictionary.items.map((item) => {
    const label = item.names.find((n) => n.locale === language)?.value || item.name;

    return {
      id: item.id,
      label,
    };
  });

  return (
    <RadioGroupField
      isEmptyStateEnabled
      data-testid=''
      key={field.id}
      id={field.id}
      label={label}
      items={options}
    />
  );
};
