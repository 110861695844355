import { withErrorHandlingValidation } from '@/utils/validation/withErrorHandlingValidation';
import { useTranslation } from 'react-i18next';
import { TextFieldValue } from 'stc-ui-kit';
import * as yup from 'yup';

export const useValidation = (password?: string) => {
  const { t } = useTranslation();

  const validateString = ({
    isRequired,
    isNullable,
    min,
    max,
  }: {
    isRequired?: boolean;
    isNullable?: boolean;
    min?: number;
    max?: number;
  }) => {
    let schema: yup.StringSchema<string | null | undefined, yup.AnyObject, undefined, ''> = yup
      .string()
      .trim();

    if (isNullable) {
      schema = schema.nullable();
    }

    if (isRequired) {
      schema = schema.required(t('validation.required'));
    }

    if (typeof min === 'number') {
      schema = schema.test('min-length', t('validation.minLength', { count: min }), (value) =>
        value ? value.length >= min : true
      );
    }

    if (typeof max === 'number') {
      schema = schema.test('max-length', t('validation.maxLength', { count: max }), (value) =>
        value ? value.length <= max : true
      );
    }

    return withErrorHandlingValidation(schema);
  };

  const validatePasswordConfirm = (val: TextFieldValue) => {
    if (password !== val) {
      return t('validation.passwordsNotMatch');
    }

    return true;
  };

  const validatePassword = validateString({ isRequired: true, min: 5, max: 50 });

  return { validateString, validatePassword, validatePasswordConfirm };
};
