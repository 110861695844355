import React from 'react';

import { Paper } from '@/styles/Paper';

import { Box, CSSObject } from '@mui/material';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { Separator } from 'stc-ui-kit';

interface Props {
  leftSideHeader?: React.ReactNode;
  rightSideHeader?: React.ReactNode;
  children?: React.ReactNode;
  content?: React.ReactNode;
  styles?: {
    header?: CSSObject;
  };
}

export const ContentWithHeaderWrapper: React.FC<Props> = ({
  leftSideHeader,
  rightSideHeader,
  children,
  content,
  styles,
}) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        overflow: 'hidden',
        background: appColors.darkThemeBackground,
        border: `1px solid ${appColors.darkThemeBorder}`,
      }}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        sx={{
          padding: '16px',
          ...styles?.header,
        }}
      >
        <Box display='flex' alignItems='center'>
          {leftSideHeader}
        </Box>
        <Box display='flex' alignItems='center'>
          {rightSideHeader}
        </Box>
      </Box>

      <Separator horizontal />

      {content || (
        <Box flex={1} display='flex' flexDirection='column' overflow='auto'>
          {children}
        </Box>
      )}
    </Paper>
  );
};
