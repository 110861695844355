import { ElementType, FC, PropsWithChildren } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import styles from './headerLeftIconTextWrapper.module.scss';
import { Separator } from 'stc-ui-kit';

export interface IHeaderLeftIconTextProps {
  icon?: ElementType<SvgIconProps>;
  text: React.ReactNode;
  border?: boolean;
}

export const HeaderLeftIconTextWrapper: FC<IHeaderLeftIconTextProps & PropsWithChildren> = (
  props
) => {
  const { icon, text, border } = props;

  return (
    <div className={styles.wrapper}>
      {icon && <SvgIcon component={icon} fontSize={'medium'} />}

      <span style={{ marginLeft: '8px', marginRight: '16px' }}>{text}</span>

      {!!border && <Separator vertical maxSize />}
    </div>
  );
};
