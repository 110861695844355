import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { filesStore } from '../../stores/Files';

export const useLoadFilesData = () => {
  const { init, listFiles, updateByIds } = filesStore;
  const [visibleRange, setVisibleRange] = useState([0, 0]);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const idsForUpdateRef: MutableRefObject<string[]> = useRef([]);

  useEffect(() => {
    (async () => {
      await init();
    })().catch(console.error);
  }, [init]);

  useEffect(() => {
    const ids = listFiles?.slice(visibleRange[0], visibleRange[1] + 1).map((f) => f.id.toString());
    const idsForUpdate = idsForUpdateRef.current;
    if (
      ids &&
      !(
        idsForUpdate.length === ids.length &&
        idsForUpdate.every((value, index) => value === ids[index])
      )
    ) {
      idsForUpdateRef.current = ids;
    }
  }, [listFiles, visibleRange]);

  useEffect(() => {
    const clear = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };

    clear();

    const loadData = async () => {
      if (idsForUpdateRef.current.length > 0) {
        await updateByIds(idsForUpdateRef.current).catch(console.error);
      }
    };

    loadData().catch(console.error);

    intervalRef.current = setInterval(loadData, 15000);

    return clear;
  }, [idsForUpdateRef, updateByIds]);

  return { setVisibleRange };
};
