import { getServerMediaFilePath } from '@/services/root.api.service';
import { CardPhoto } from '@/types/card';
import { IFileLink } from '@/types/file';
import { FaceData } from '@/types/model';
import { getFileFromServer } from '@/utils/files/getFileFromServer';
import { convertTiffToJpeg } from './convertTiffToJpeg';
import { loadingErrorPath } from '../../constants';

export const fetchCardPhotos = async ({
  imageFiles,
  withFaceData = true,
}: {
  imageFiles: IFileLink[];
  withFaceData?: boolean;
}) => {
  const imagesResponses = await Promise.all(
    imageFiles.map((file) =>
      file.file ? getFileFromServer(getServerMediaFilePath(file.file?.path)) : null
    )
  );

  const processedImages = await Promise.all(
    imagesResponses.map(async (file, ind) => {
      if (!file || !file.blob) {
        return { id: imageFiles[ind].id, path: loadingErrorPath, faceData: [] };
      }

      const fileType = file.blob.type;
      let imagePath = URL.createObjectURL(file.blob);

      if (fileType === 'image/tiff' || fileType === 'image/tif') {
        try {
          imagePath = await convertTiffToJpeg(file.blob);
          console.log(imagePath);
        } catch (error) {
          console.error('Error converting TIFF to JPEG:', error);

          return null;
        }
      }

      const imageFileModelLinks = imageFiles[ind].fileModelLinks;

      return {
        id: imageFiles[ind].id,
        path: imagePath,
        fileProcessId: imageFiles[ind].fileProcessId,
        isMain: false,
        fileGroup: imageFiles[ind].fileGroup,
        createdTime: imageFiles[ind].createdTime,
        faceData: withFaceData
          ? imageFileModelLinks?.map((m) => {
              const modelData = m.fileModel.data as FaceData;

              const faceOriginal = modelData.files.find((f) => f.type === 'FACE_MODEL_SOURCE');
              const faceNormalized = modelData.files.find((f) => f.type === 'FACE_NORMALIZED');

              return {
                area: modelData.area,
                modelId: m.fileModel.id,
                quality: m.fileModel.quality,
                normalizedImageCorrelationId: faceNormalized?.data.correlationId || '',
                normalizedImageUri: faceNormalized?.data.uri || '',
                originalImageCorrelationId: faceOriginal?.data.correlationId || '',
                originalImageUri: faceOriginal?.data.uri || '',
              };
            }) || []
          : [],
      } as CardPhoto;
    })
  );

  return processedImages.filter((image): image is CardPhoto => image !== null);
};
