import React from 'react';
import ReactDOM from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import { UiKitProvider } from 'stc-ui-kit';
import 'stc-ui-kit/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './Providers/ThemeProvider';
import StoreProvider from './Providers/StoreProvider';
import { SnackbarUtilsConfigurator } from './components/common/snackBarConfigurator';
import { Snackbar } from '@/components/common/Snackbar';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { UserPermissionsContextProvider } from './contexts/UserPermissionsContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StyledEngineProvider injectFirst>
    <React.StrictMode>
      <UiKitProvider>
        <ThemeProvider>
          <StoreProvider>
            <UserPermissionsContextProvider>
              <SnackbarProvider
                maxSnack={3}
                disableWindowBlurListener
                preventDuplicate
                Components={{
                  warning: Snackbar,
                  error: Snackbar,
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <CssBaseline />
                <App />
                <SnackbarUtilsConfigurator />
              </SnackbarProvider>
            </UserPermissionsContextProvider>
          </StoreProvider>
        </ThemeProvider>
      </UiKitProvider>
    </React.StrictMode>
  </StyledEngineProvider>
);

reportWebVitals();
