import * as UTIF from 'utif';

export const convertTiffToJpeg = async (tiffBlob: Blob): Promise<string> => {
  try {
    const arrayBuffer = await tiffBlob.arrayBuffer();
    const ifds = UTIF.decode(arrayBuffer);

    if (ifds.length === 0) {
      throw new Error('No images found in TIFF file');
    }

    const ifd = ifds[0];
    UTIF.decodeImage(arrayBuffer, ifd);

    const rgba = UTIF.toRGBA8(ifd);

    const width = ifd.width;
    const height = ifd.height;

    if (!Number.isInteger(width) || !Number.isInteger(height)) {
      throw new Error('Width and height must be integers');
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('Canvas context is not available');
    }

    canvas.width = width;
    canvas.height = height;

    const imageData = ctx.createImageData(width, height);
    imageData.data.set(rgba);
    ctx.putImageData(imageData, 0, 0);

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob: Blob | null) => {
        if (blob) {
          resolve(URL.createObjectURL(blob));
        } else {
          reject(new Error('Failed to convert TIFF to JPEG'));
        }
      }, 'image/jpeg');
    });
  } catch (error) {
    console.error('Error converting TIFF to JPEG:', error);
    throw new Error('Error converting TIFF to JPEG');
  }
};
