import { ECardExportStatus, ICardsGridItem } from '@/types/card';
import { DataGridStorePredicates, IGridFilter } from '@/types/grid';
import {
  CheckboxGroupFieldValue,
  icons,
  QuickFilterItem,
  QuickFilters,
  QuickFilterTypes,
  RadioGroupFieldValue,
  TableFilterFieldValue,
  UiKitColor,
} from 'stc-ui-kit';
import { endOfYesterday, startOfToday, startOfYesterday } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDataStore } from '@/Providers/StoreProvider';
import { observer } from 'mobx-react';

type Filter = {
  models: CheckboxGroupFieldValue;
  status: CheckboxGroupFieldValue;
  date: RadioGroupFieldValue;
};

type DateFilter = 'none' | 'yesterday' | 'today' | 'interval';

const FACE_MODEL_ID: keyof ICardsGridItem = 'faceModel';
const VOICE_MODEL_ID: keyof ICardsGridItem = 'voiceModel';
const DOCUMENTS_ID = 'documents';
const DATE_NONE_ID: DateFilter = 'none';
const DATE_YESTERDAY_ID: DateFilter = 'yesterday';
const DATE_TODAY_ID: DateFilter = 'today';
const DATE_INTERVAL_ID: DateFilter = 'interval';
const DATE_FIELD_ID: keyof ICardsGridItem = 'editedOnDateTime';

const apiModelFilterItems: Partial<
  Record<keyof ICardsGridItem | typeof DOCUMENTS_ID, IGridFilter<ICardsGridItem>>
> = {
  [VOICE_MODEL_ID]: {
    fieldId: VOICE_MODEL_ID,
    predicate: DataGridStorePredicates.IsNotNull,
  },
  [FACE_MODEL_ID]: {
    fieldId: FACE_MODEL_ID,
    predicate: DataGridStorePredicates.IsNotNull,
  },
  [DOCUMENTS_ID]: {
    fieldId: DOCUMENTS_ID,
    predicate: DataGridStorePredicates.IsNotNull,
  },
};

const startOfYesterdayValue = startOfYesterday().toISOString();
const endOfYesterdayValue = endOfYesterday().toISOString();
const startOfTodayValue = startOfToday().toISOString();

const apiDateFilterItems: Partial<Record<DateFilter, IGridFilter<ICardsGridItem>[]>> = {
  yesterday: [
    {
      fieldId: DATE_FIELD_ID,
      targetValues: [startOfYesterdayValue],
      predicate: DataGridStorePredicates.GreaterOrEqual,
    },
    {
      fieldId: DATE_FIELD_ID,
      targetValues: [endOfYesterdayValue],
      predicate: DataGridStorePredicates.LessOrEqual,
    },
  ],
  today: [
    {
      fieldId: DATE_FIELD_ID,
      targetValues: [startOfTodayValue],
      predicate: DataGridStorePredicates.GreaterOrEqual,
    },
  ],
  interval: [
    {
      fieldId: DATE_FIELD_ID,
      predicate: DataGridStorePredicates.GreaterOrEqual,
    },
    {
      fieldId: DATE_FIELD_ID,
      predicate: DataGridStorePredicates.LessOrEqual,
    },
  ],
};

const formatStoreFilters = (
  storeFilters: IGridFilter<ICardsGridItem>[],
  filterComponentItems: QuickFilterItem<Filter>[]
): QuickFilterItem<Filter>[] => {
  const result: QuickFilterItem<Filter>[] = [];
  const dateValues: string[] = [];

  storeFilters.forEach((sf) => {
    switch (sf.fieldId) {
      case 'exportStatus':
        if (!sf.targetValues) return;
        sf.targetValues.forEach((tv) => {
          const filterItem = filterComponentItems.find((fi) => fi.id === tv);
          if (filterItem) {
            result.push(filterItem);
          }
        });
        break;

      case 'editedOnDateTime':
        if (sf.targetValues) {
          dateValues.push(sf.targetValues[0] as string);
        }
        break;

      default:
        const filterItem = filterComponentItems.find((fi) => fi.id === sf.fieldId);
        if (filterItem) {
          result.push(filterItem);
        }
    }
  });

  if (dateValues.length) {
    const isYesterday =
      dateValues.some((dv) => dv === startOfYesterdayValue) &&
      dateValues.some((dv) => dv === endOfYesterdayValue);

    const isToday = dateValues.length === 1 && dateValues[0] === startOfTodayValue;

    switch (true) {
      case isYesterday:
        const yesterdayFilterItem = filterComponentItems.find((fi) => fi.id === DATE_YESTERDAY_ID);
        if (yesterdayFilterItem) {
          result.push(yesterdayFilterItem);
        }
        break;

      case isToday:
        const todayFilterItem = filterComponentItems.find((fi) => fi.id === DATE_TODAY_ID);
        if (todayFilterItem) {
          result.push(todayFilterItem);
        }
        break;

      default:
        const calendarFilterItem = filterComponentItems.find((fi) => fi.id === DATE_INTERVAL_ID);
        if (calendarFilterItem) {
          result.push(calendarFilterItem);
        }
    }
  } else {
    const noDateFilterItem = filterComponentItems.find((fi) => fi.id === DATE_NONE_ID);
    if (noDateFilterItem) {
      result.push(noDateFilterItem);
    }
  }

  return result;
};

export const HeaderQuickFilters = observer(function HeaderQuickFilters() {
  const { t } = useTranslation();
  const { uiStore } = useDataStore();
  const { filingCabinetFilters, setQuickFilters } = uiStore;
  const { quickFilters } = filingCabinetFilters;

  const filterComponentItems: QuickFilterItem<Filter>[] = useMemo(
    () => [
      {
        id: VOICE_MODEL_ID,
        name: '',
        type: QuickFilterTypes.CheckboxGroup,
        fieldId: 'models',
        icons: [
          {
            icon: icons.IconVoice,
            iconColorActive: UiKitColor.Teal700,
          },
        ],
      },
      {
        id: FACE_MODEL_ID,
        name: '',
        type: QuickFilterTypes.CheckboxGroup,
        fieldId: 'models',
        icons: [
          {
            icon: icons.IconFaceScan,
            iconColorActive: UiKitColor.Teal700,
          },
        ],
      },
      {
        id: DOCUMENTS_ID,
        name: '',
        type: QuickFilterTypes.CheckboxGroup,
        fieldId: 'models',
        icons: [
          {
            icon: icons.IconAssignmentTablet,
            iconColorActive: UiKitColor.Teal700,
          },
        ],
      },
      {
        id: ECardExportStatus.NEW,
        name: '',
        type: QuickFilterTypes.CheckboxGroup,
        fieldId: 'status',
        icons: [
          {
            icon: icons.IconRadioUnselected,
            iconColorActive: UiKitColor.Indigo700,
          },
        ],
      },
      {
        id: ECardExportStatus.NEED_EXPORT,
        name: '',
        type: QuickFilterTypes.CheckboxGroup,
        fieldId: 'status',
        icons: [
          {
            icon: icons.IconTaskCompleted,
            iconColorActive: UiKitColor.Green700,
          },
        ],
      },
      {
        id: ECardExportStatus.EXPORTED,
        name: '',
        type: QuickFilterTypes.CheckboxGroup,
        fieldId: 'status',
        icons: [
          {
            icon: icons.IconExportNew,
            iconColorActive: UiKitColor.Teal700,
          },
        ],
      },
      {
        id: DATE_NONE_ID,
        name: t('multipass.allPeriod'),
        type: QuickFilterTypes.RadioGroup,
        fieldId: 'date',
      },
      {
        id: DATE_YESTERDAY_ID,
        name: t('multipass.yesterday'),
        type: QuickFilterTypes.RadioGroup,
        fieldId: 'date',
      },
      {
        id: DATE_TODAY_ID,
        name: t('multipass.today'),
        type: QuickFilterTypes.RadioGroup,
        fieldId: 'date',
      },
      {
        id: DATE_INTERVAL_ID,
        name: t('multipass.calendar'),
        fieldId: 'date',
        type: QuickFilterTypes.DateRangePicker,
        value: (() => {
          const storeFilterValues = quickFilters.filter((sf) => sf.fieldId === 'createdOnDateTime');

          if (storeFilterValues?.length !== 2) return undefined;

          const startDate = (storeFilterValues?.[0].targetValues?.[0] as string) || null;
          const endDate = (storeFilterValues?.[1].targetValues?.[0] as string) || null;

          return {
            startDate,
            endDate,
          };
        })(),
      },
    ],
    [t, quickFilters]
  );

  const filtersComponentValue = useMemo(
    () => formatStoreFilters(quickFilters, filterComponentItems),
    [filterComponentItems, quickFilters]
  );

  const handleFilterChange = (item: QuickFilterItem<Filter>, itemValue?: TableFilterFieldValue) => {
    const updatedValue = (() => {
      if (item.fieldId === 'date') {
        const updated = [...filtersComponentValue];
        const itemIndex = updated.findIndex((el) => el.fieldId === 'date');
        itemIndex > -1 ? (updated[itemIndex] = item) : updated.push(item);

        return updated;
      }

      return filtersComponentValue.find((prevItem) => prevItem.id === item.id)
        ? [...filtersComponentValue.filter((prevItem) => prevItem.id !== item.id)]
        : [...filtersComponentValue, item];
    })();

    const formattedValue: IGridFilter<ICardsGridItem>[] = [];
    const statusTargetValues: ECardExportStatus[] = [];

    updatedValue.forEach((v) => {
      switch (v.fieldId) {
        case 'models':
          const dictModelFilter = apiModelFilterItems[v.id as keyof ICardsGridItem];
          if (dictModelFilter) {
            formattedValue.push(dictModelFilter);
          }
          break;

        case 'status':
          statusTargetValues.push(v.id as ECardExportStatus);
          break;

        case 'date':
          const dictDateFilter = apiDateFilterItems[v.id as DateFilter];
          if (dictDateFilter) {
            if (v.id === 'interval' && !!itemValue) {
              if (!itemValue.startDate || !itemValue.endDate) return;

              dictDateFilter[0].targetValues = [itemValue.startDate];
              dictDateFilter[1].targetValues = [itemValue.endDate];
            }
            formattedValue.push(...dictDateFilter);
          }
          break;

        default:
          break;
      }
    });

    if (statusTargetValues.length) {
      formattedValue.push({
        fieldId: 'exportStatus',
        targetValues: statusTargetValues,
        predicate: DataGridStorePredicates.Contains,
      });
    }

    setQuickFilters(formattedValue);
  };

  return (
    <QuickFilters
      value={filtersComponentValue}
      dividersIndex={[2, 5]}
      onChange={handleFilterChange}
      items={filterComponentItems}
      // без этого пропа календарь не работает
      dateRangePickerProps={{
        minYear: undefined,
        maxYear: undefined,
      }}
    />
  );
});
