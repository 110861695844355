import { FAQ } from '@/components/FAQ/FAQ';
import { Gallery } from '@/components/Gallery/Gallery';
import { MediaSelect } from '@/components/MediaSelect/MediaSelect';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { MediaDevicesStore } from '@/stores/WebRTC/MediaDevicesStore';
import { CameraAlt } from '@mui/icons-material';
import { Button, Divider, Switch, Toolbar, Typography, Box, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { ModelStatus } from '../ModelStatus/ModelStatus';

interface VideoSampleProps {
  mediaDevicesStore: MediaDevicesStore;
}

export const VideoSample = observer((props: VideoSampleProps) => {
  const { mediaDevicesStore } = props;
  const [deviceStream, setDeviceStream] = useState<MediaStream | null>(null);
  useEffect(() => {
    const doAsync = async () => {
      try {
        if (mediaDevicesStore.selectedVideoInputDeviceId) {
          const stream = await mediaDevicesStore.getMediaDeviceStream(
            'video',
            mediaDevicesStore.selectedVideoInputDeviceId
          );
          setDeviceStream(stream);
        }
      } catch (err) {
        setDeviceStream(null);
        console.error('Ошибка при воспроизведении видео с вебкамеры: ', err);
      }
    };
    void doAsync();
  }, [mediaDevicesStore, mediaDevicesStore.selectedVideoInputDeviceId]);

  return (
    <Box>
      <Divider variant='fullWidth' />
      <Toolbar style={{ padding: '0' }}>
        <Box maxWidth='200px' padding='7px 0 7px 16px' boxSizing='border-box'>
          <MediaSelect
            selectIcon={<CameraAlt style={{ color: appColors.bgPrimary }} />}
            mediaDevicesStore={mediaDevicesStore}
            mediaType='video'
            selectMediaDeviceId={mediaDevicesStore.setSelectedVideoInputDeviceId}
            selectedDeviceId={mediaDevicesStore.selectedVideoInputDeviceId}
          />
        </Box>
        <Divider
          flexItem
          variant='middle'
          orientation='vertical'
          style={{ margin: '0 33px', height: 'auto' }}
        />
        <Button
          style={{ background: appColors.bgPrimary, color: 'white', padding: '6px 24px' }}
          startIcon={<ModelStatus status='notStarted' />}
        >
          Начать сбор
        </Button>
        <Divider variant='middle' orientation='vertical' style={{ margin: '0 33px' }} />
        <Stack direction='row' alignItems='center'>
          <Typography>Ручной режим</Typography>
          <Switch />
        </Stack>
        <Stack direction='row' alignItems='center'>
          <Typography>Показывать детектирование лиц</Typography>
          <Switch />
        </Stack>
        <Box ml='auto'>
          <FAQ
            icon={<CameraAlt />}
            text=''
            pages={[
              { text: 'Назовите Вашу Фамилию, Имя и Отчество полностью' },
              { text: 'Назовите как зовут максима' },
            ]}
          />
        </Box>
      </Toolbar>
      <Gallery deviceStream={deviceStream} />
    </Box>
  );
});
