import { CustomTypeFieldDescriptor, FieldDescriptor } from '@/types/cardTemplate';
import { DatePickerField, FormReturn } from 'stc-ui-kit';
import { FieldValues } from 'react-hook-form';
import { formatDateInputValue } from '@/utils/formatDateInputValue';

export const renderDateFilterField = (
  field: FieldDescriptor | CustomTypeFieldDescriptor,
  label: string,
  form: FormReturn<FieldValues>
) => {
  return (
    <DatePickerField
      key={field.id}
      id={field.id}
      label={label}
      data-testid=''
      onChange={formatDateInputValue({ form, fieldId: field.id })}
    />
  );
};
