import { useCallback, useState } from 'react';
import { IStorageFile } from '../types';
import filesApiService from '@/services/files.service';

interface IUseFileDialog {
  currentPath: string;
  items: IStorageFile[];
  onFileClick: (file: IStorageFile) => void;
  selectedFiles: IStorageFile[];
  isCollapseDirectory: boolean;
  opnCloseDirectory: () => void;
  loading: boolean;
  loadDirectory: (path: string) => Promise<void>;
  resetState: () => void;
}

export const useFileDialog = (): IUseFileDialog => {
  const [currentPath, setCurrentPath] = useState<string>('');
  const [directoryDataItems, setDirectoryDataItems] = useState<IStorageFile[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<IStorageFile[]>([]);
  const [isCollapseDirectory, setIsCollapseDirectory] = useState(false);
  const [loading, setLoading] = useState(false);

  const resetState = useCallback(() => {
    setCurrentPath('');
    setDirectoryDataItems([]);
    setSelectedFiles([]);
    setIsCollapseDirectory(false);
    setLoading(false);
  }, []);

  const loadDirectory = useCallback(async (path: string) => {
    try {
      setLoading(true);
      setSelectedFiles([]);
      const newPath = path.replaceAll('\\', '/');
      setCurrentPath(newPath);

      const data = await filesApiService.getFolderStructure(
        path === '' ? undefined : path.replaceAll('\\', '/')
      );

      if (!data) return;

      const items: IStorageFile[] = data.items;
      const parent: string = data.parent;
      setDirectoryDataItems(items);
      setCurrentPath(parent);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const opnCloseDirectory = useCallback(() => {
    setIsCollapseDirectory((v) => !v);
  }, []);

  const onFileClick = useCallback((file: IStorageFile) => {
    setSelectedFiles((selectedFiles) =>
      selectedFiles.includes(file)
        ? [...selectedFiles.filter((sf) => file.path !== sf.path)]
        : [...selectedFiles, file]
    );
  }, []);

  return {
    currentPath,
    items: directoryDataItems,
    onFileClick,
    selectedFiles,
    isCollapseDirectory,
    opnCloseDirectory,
    loading,
    loadDirectory,
    resetState,
  };
};
