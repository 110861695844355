import { UsersGrid } from './components/UsersGrid/UsersGrid';
import { useGridStore } from '@/hooks/grid/useGridStore';
import { User } from '@/types/user';
import { observer } from 'mobx-react';

import { RightPanelWrapper } from '@/components/RightPanelWrapper';
import { HeaderUsers } from './components/HeaderUsers';
import userService from '@/services/user.service';
import { useCallback, useEffect, useState } from 'react';
import { EDataGridStoreSortMode } from '@/types/grid';

export const Users = observer(() => {
  const [loading, setLoading] = useState(false);
  const { store } = useGridStore<User>({
    dataProvider: (state) => userService.dataProvider(state, []),
    defaultSortState: {
      sort: EDataGridStoreSortMode.ASC,
      sortField: 'username',
    },
    withInfiniteScroll: true,
  });

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    const { res } = await userService.getAllUsers();

    if (res) {
      store.setDataProvider((state) => userService.dataProvider(state, res));
      await store.reload();
    }
    setLoading(false);
  }, [store]);

  useEffect(() => {
    void fetchUsers();
  }, [fetchUsers]);

  return (
    <RightPanelWrapper header={<HeaderUsers store={store} fetchUsers={fetchUsers} />}>
      <UsersGrid store={store} loading={loading} />
    </RightPanelWrapper>
  );
});
