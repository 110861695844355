import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { borderColors } from '../theme/colors';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  height: '100%',
  ...theme.typography.body2,
  borderRadius: '4px',
  boxShadow: '0px 4px 8px #4B506129',
  borderColor: borderColors['--steal-100'],
  display: 'block',
  overflow: 'auto',
}));
export { Paper };
