import { Dispatch, SetStateAction, useEffect } from 'react';
import { useDataStore } from '@/Providers/StoreProvider';
import { getTabByFileGroup } from '../utils/getTabByFileGroup';
import { IFileLink } from '@/types/file';
import { EntryTab } from '@/types/ui.types';
import { FormReturn } from 'stc-ui-kit';
import { FieldValues } from 'react-hook-form';
import { SseMessageType } from '@/types/sse';

export const useCardFileSseMessages = ({
  cardId,
  activeTab,
  refetchCardData,
  setFileLinks,
  updateTabUiStore,
  refetchTabData,
  formHooks,
}: {
  cardId: number | undefined;
  activeTab: EntryTab;
  refetchCardData: (keepDirty?: boolean) => Promise<void>;
  setFileLinks: Dispatch<SetStateAction<IFileLink[]>>;
  updateTabUiStore: (fileProcesses: IFileLink[], cardId: number) => Promise<void>;
  refetchTabData: () => Promise<void>;
  formHooks: FormReturn<FieldValues>;
}) => {
  const { sseStore } = useDataStore();

  // 'visibilitychange' is when we switch browser tabs.
  // when we leave current browser tab sse disconnects,
  // so we need to refetch data when coming back.
  useEffect(() => {
    const onVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        await refetchData();
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId, activeTab]);

  useEffect(() => {
    if (sseStore.isReconnectedAfterError) {
      sseStore.setIsReconnectedAfterError(false);
      void refetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sseStore.isReconnectedAfterError]);

  const refetchData = async () => {
    await refetchCardData(formHooks.formState.isDirty);
    await refetchTabData();
  };

  useEffect(() => {
    const message = sseStore.cardFileMessage;
    if (!message) return;
    if (message.cardId !== cardId) return;

    const processSseMessage = () => {
      setFileLinks((prev) => {
        let updatedFileLinks: IFileLink[] = [];

        if (message.type === SseMessageType.CardFilesDeleted) {
          const notCurrentTab = message.fileLinkIds?.some((id) => !prev.find((l) => l.id === id));
          if (notCurrentTab) return prev;

          updatedFileLinks = prev.filter((l) => !message.fileLinkIds?.includes(l.id));
        } else {
          if (!message.fileView) return prev;
          const notCurrentTab = getTabByFileGroup(message.fileView.fileGroup) !== activeTab;
          if (notCurrentTab) return prev;

          updatedFileLinks = [...prev];
          const linkIndex = updatedFileLinks.findIndex((l) => l.id === message.fileView?.id);
          linkIndex > -1
            ? (updatedFileLinks[linkIndex] = message.fileView)
            : updatedFileLinks.unshift(message.fileView);
        }

        void updateTabUiStore(updatedFileLinks, cardId);

        return updatedFileLinks;
      });
    };

    processSseMessage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sseStore.cardFileMessage]);
};
