import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { useDataStore } from '@/Providers/StoreProvider';
import React, { useEffect, useState } from 'react';

import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Sidebar, SidebarDynamicTab } from 'stc-ui-kit';
import { SidebarStaticTab } from 'stc-ui-kit/components/Sidebar/types';

interface Props {
  staticTabs: SidebarStaticTab[];
  dynamicTabs?: SidebarDynamicTab[];
  onTabsClose?: () => void;
  onTabsOrderChange?: () => void;
}

export const SIDE_NAV_COLLAPSED_WIDTH = 50;
const SIDE_NAV_ESTIMATE_WIDTH = 230;
export const SIDE_NAV_EXPANDED_WIDTH = SIDE_NAV_ESTIMATE_WIDTH + 10;

export const SideNav: React.FC<Props> = (props) => {
  const {
    staticTabs,
    dynamicTabs = [],
    onTabsClose = () => null,
    onTabsOrderChange = () => null,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(localStorage.getItem('SideNavCollapse') === 'true');
  const [width, setWidth] = useState(SIDE_NAV_ESTIMATE_WIDTH);
  const { constantsStore } = useDataStore();
  const { checkIsPageAllowed } = useUserPermissionsContext();

  useEffect(() => {
    const isVisibleString: string = isVisible.toString();
    localStorage.setItem('SideNavCollapse', isVisibleString);
    constantsStore.setIsSideNavCollapsed(!isVisible);
  }, [isVisible, constantsStore]);

  const getIsTabActive = (tabRoute: string) => {
    return !!matchPath(tabRoute, location.pathname);
  };

  const handleTabClick = (tabRoute: string) => {
    navigate(tabRoute);
  };

  const availableStaticTabs = staticTabs.filter((t) => checkIsPageAllowed(t.route));

  return (
    <Sidebar
      getIsTabActive={getIsTabActive}
      onTabClick={handleTabClick}
      isVisible={isVisible}
      onVisibilityChange={setIsVisible}
      width={width}
      onWidthChange={setWidth}
      staticTabs={availableStaticTabs}
      dynamicTabs={dynamicTabs}
      onTabsClose={onTabsClose}
      onTabsOrderChange={onTabsOrderChange}
    />
  );
};
