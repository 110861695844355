import { HeaderLeftIconTextWrapper } from '@/components/HeaderLeftIconTextWrapper';
import { useTranslation } from 'react-i18next';
import { AdministrationRolesIcon } from '../../icons/Roles';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { EditRolesPrivsModal } from './EditRolesPrivsModal';
import { IRoleWithPrivilegesInGroups } from '@/types/role';
import { CreateRoleModal } from './CreateRoleModal';
import {
  Button,
  ButtonType,
  ConfirmationType,
  Separator,
  Tooltip,
  icons,
  useConfirmationModal,
} from 'stc-ui-kit';
import { prepareRoleName } from '@/utils/prepareRoleName';
import rolesService from '@/services/roles.service';
import { useRolesContext } from '../context/RolesContext';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';
import { showInfoNotification } from '@/utils/notifications';

export const HeaderRoles = () => {
  const { t } = useTranslation();
  const { havePermissions } = useUserPermissionsContext();
  const { createButton, editButton, deleteButton, rolesManagmentButton } =
    permissionControl.administration.roles;
  const { fetchData, selectedRoleIds, roles, rolesWithPrivsInGroups, resetSelection } =
    useRolesContext();
  const { showConfirmationModal } = useConfirmationModal();
  const [isPrivsModalOpen, setIsPrivsModalOpen] = useState(false);
  const [isCreateRoleModalOpen, setIsCreateRoleModalOpen] = useState(false);
  const [editableRole, setEditableRole] = useState<IRoleWithPrivilegesInGroups | null>(null);

  const handleCloseCreateRoleModal = () => {
    setEditableRole(null);
    setIsCreateRoleModalOpen(false);
  };

  const getConfirmationModalText = () => {
    const rolesList = selectedRoleIds.map((id) => {
      const roleName = roles.find((r) => r.id === id)?.roleName || '';

      return `«${prepareRoleName(roleName)}»`;
    });

    return `${t('managingRoles.areYouSureDeleteRole')} ${rolesList.join(', ')}?`;
  };

  const handleDeleteRole = async () => {
    const submitType = await showConfirmationModal({
      title: t('managingRoles.deleteRole'),
      text: getConfirmationModalText(),
      cancelButtonText: t('cancel'),
      primaryButtonText: t('delete'),
    });
    if (submitType === ConfirmationType.PrimaryAction) {
      try {
        await Promise.all(selectedRoleIds.map((id) => rolesService.deleteRole(id)));
        showInfoNotification({ header: t('messages.roleDeleted') });
        await fetchData();
        resetSelection();
      } catch (error) {
        showInfoNotification({ header: t('messages.roleDeleted') });
      }
    }
  };

  return (
    <>
      <HeaderLeftIconTextWrapper
        icon={AdministrationRolesIcon}
        border
        text={capitalize(t('roles'))}
      />
      {havePermissions(createButton) && (
        <>
          <Button icon={icons.IconAdd} onClick={() => setIsCreateRoleModalOpen(true)}>
            {t('createRole')}
          </Button>
          <Separator vertical />
        </>
      )}
      {(havePermissions(editButton) || havePermissions(deleteButton)) && (
        <>
          {havePermissions(editButton) && (
            <Tooltip tooltip={t('edit')}>
              <Button
                icon={icons.IconEdit}
                type={ButtonType.Icon}
                isDisabled={selectedRoleIds.length !== 1}
                onClick={() => {
                  setEditableRole(
                    rolesWithPrivsInGroups.find((r) => r.id === selectedRoleIds[0]) || null
                  );
                  setIsCreateRoleModalOpen(true);
                }}
              />
            </Tooltip>
          )}
          {havePermissions(deleteButton) && (
            <Tooltip tooltip={t('delete')}>
              <Button
                icon={icons.IconDelete}
                type={ButtonType.Icon}
                isDisabled={selectedRoleIds.length === 0}
                onClick={handleDeleteRole}
              />
            </Tooltip>
          )}
          <Separator vertical />
        </>
      )}
      {havePermissions(rolesManagmentButton) && (
        <Button
          type={ButtonType.Text}
          onClick={() => setIsPrivsModalOpen(true)}
          isDisabled={selectedRoleIds.length !== 1}
        >
          {t('roleManagement')}
        </Button>
      )}

      {selectedRoleIds.length === 1 && (
        <EditRolesPrivsModal
          isOpened={isPrivsModalOpen}
          onClose={() => setIsPrivsModalOpen(false)}
          initialRoleId={selectedRoleIds[0]}
        />
      )}

      {isCreateRoleModalOpen && (
        <CreateRoleModal onClose={handleCloseCreateRoleModal} initialRoleData={editableRole} />
      )}
    </>
  );
};
