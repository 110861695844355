import { FAQ } from '@/components/FAQ/FAQ';
import { MediaSelect } from '@/components/MediaSelect/MediaSelect';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { MediaDevicesStore } from '@/stores/WebRTC/MediaDevicesStore';
import { Mic } from '@mui/icons-material';
import { Button, Divider, Toolbar, Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ModelStatus } from '../ModelStatus/ModelStatus';
import { SelectSampleType } from './SelectSampleType/SelectSampleType';

interface AudioSampleProps {
  mediaDevicesStore: MediaDevicesStore;
}

export const AudioSample = observer((props: AudioSampleProps) => {
  const { mediaDevicesStore } = props;

  return (
    <Box>
      <Toolbar style={{ padding: '0' }}>
        <Box maxWidth='200px' padding='7px 0 7px 16px' boxSizing='border-box'>
          <MediaSelect
            selectIcon={<Mic style={{ color: appColors.bgPrimary }} />}
            mediaDevicesStore={mediaDevicesStore}
            mediaType='audio'
            selectMediaDeviceId={mediaDevicesStore.setSelectedAudioInputDeviceId}
            selectedDeviceId={mediaDevicesStore.selectedAudioInputDeviceId}
          />
        </Box>
        <Divider
          flexItem
          variant='middle'
          orientation='vertical'
          style={{ margin: '0 33px', height: 'auto' }}
        />
        <Button
          style={{ background: appColors.bgPrimary, color: 'white', padding: '6px 24px' }}
          startIcon={<ModelStatus status='notStarted' />}
        >
          Начать сбор
        </Button>
        <SelectSampleType />
        <FAQ
          icon={<Mic />}
          text=''
          pages={[
            { text: 'Назовите Вашу Фамилию, Имя и Отчество полностью' },
            { text: 'Назовите как зовут максима' },
          ]}
        />
      </Toolbar>
    </Box>
  );
});
