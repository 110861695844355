import DescriptionIcon from '@mui/icons-material/Description';
import { useState } from 'react';

import { HeaderLeftIconTextWrapper } from '@/components/HeaderLeftIconTextWrapper';

import { FileDialog } from '@/components/FileDialog/FileDialog';
import { filesStore } from '@/modules/multipass/stores/Files';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Tooltip } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DeleteFileModal } from '../DeleteFileModal';

export const HeaderLoadFile = observer(() => {
  const { t } = useTranslation();

  const { selectedFile } = filesStore;

  const disabled = !selectedFile?.id;
  const [isDeleteModalOpened, setDeleteModalOpened] = useState(false);

  return (
    <>
      <HeaderLeftIconTextWrapper icon={DescriptionIcon} text={t('multipass.samples')} border />
      <Box marginLeft='16px'>
        <FileDialog
          onFileChoosen={({ dontUseForPersonsEntries, prepareFiles, selectedFiles }) =>
            filesStore.addFile({
              dontUseForPersonsEntries,
              prepareFiles,
              selectedFiles,
            })
          }
        />
      </Box>
      <Tooltip id='button-delete' title={t('delete')}>
        <span>
          <IconButton
            onClick={() => setDeleteModalOpened(true)}
            color='error'
            disabled={disabled}
            sx={{
              marginLeft: '10px',
              width: 'unset',
              minWidth: 'unset',
              color: disabled ? 'lightgray' : undefined,
            }}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
      <DeleteFileModal isOpened={isDeleteModalOpened} onClose={() => setDeleteModalOpened(false)} />
    </>
  );
});
