import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Form, Modal, PasswordField, useForm } from 'stc-ui-kit';

import { User } from '@/types/user';
import { useTranslation } from 'react-i18next';

import userService from '@/services/user.service';
import { GridStore } from '@/stores/grid.store';
import { IUserToChangePassword } from '@/types';
import { observer } from 'mobx-react';
import { InputWrapper } from '../InputWrapper';
import { useValidation } from '@/hooks/useValidation';

interface ChangePasswordFormValues {
  password: string;
  confirmPassword: string;
  username: string;
}

interface Props {
  isOpened: boolean;
  onClose: () => void;
  initialUserData: User;
  store: GridStore<User>;
  fetchUsers: () => Promise<void>;
}

export const ChangePasswordUserModal: React.FC<Props> = observer(
  ({ isOpened, onClose, initialUserData, store, fetchUsers }) => {
    const { t } = useTranslation();

    const [disableConfirm, setDisableConfirm] = useState(false);

    const { form } = useForm<ChangePasswordFormValues>({
      values: {
        confirmPassword: '',
        password: '',
        username: initialUserData.username,
      },
    });
    const {
      formState: { errors },
      handleSubmit,
      watch,
      reset,
    } = form;

    const { validatePassword, validatePasswordConfirm } = useValidation(watch('password'));

    const handleClose = () => {
      reset();
      onClose();
    };

    const onSubmit = async (values: ChangePasswordFormValues) => {
      const userToChangePassword: IUserToChangePassword = {
        id: initialUserData.id,
        newPassword: values.password.trim(),
        repeatPassword: values.confirmPassword.trim(),
      };

      try {
        await userService.changePassword(userToChangePassword);
        await fetchUsers();
        store.resetChecked();
        handleClose();
      } catch (error) {
        console.error(error);
      }
      setDisableConfirm(false);
    };

    return (
      <Form form={form} data-testid=''>
        {isOpened && (
          <Modal
            onClose={handleClose}
            onConfirm={handleSubmit(onSubmit)}
            title={t('changePasswordTitle')}
            buttonConfirmText={t('save')}
            buttonCloseText={t('cancel')}
            isConfirmDisabled={Object.keys(errors).length > 0 || disableConfirm}
            data-testid=''
          >
            <Box display='flex' flexDirection='column' gap='8px' component='form'>
              <InputWrapper>
                <PasswordField
                  data-testid=''
                  id='password'
                  label={t('newPassword')}
                  placeholder={t('enterNewPassword')}
                  isRequired
                  maxLength={50}
                  validate={validatePassword}
                />
              </InputWrapper>

              <InputWrapper>
                <PasswordField
                  data-testid=''
                  id='confirmPassword'
                  isRequired
                  label={t('newPasswordConfirmation')}
                  placeholder={t('enterNewPassword')}
                  maxLength={50}
                  validate={validatePasswordConfirm}
                />
              </InputWrapper>
            </Box>
          </Modal>
        )}
      </Form>
    );
  }
);
