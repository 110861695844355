import { ICard } from '@/types/card';
import { IFileLink } from '@/types/file';

export enum SseMessageType {
  Ping = 'Ping',
  CardUpdated = 'CardUpdated',
  CardFileCreated = 'CardFileCreated',
  CardFileUpdated = 'CardFileUpdated',
  CardFilesDeleted = 'CardFilesDeleted',
  CardFoundInHotlist = 'CardFoundInHotlist',
}

export type SseMessage =
  | PingSseMessage
  | CardSseMessage
  | CardFileSseMessage
  | CardFoundInHotlistSseMessage;

type PingSseMessage = {
  type: SseMessageType.Ping;
};

export type CardFileSseMessage = {
  type:
    | SseMessageType.CardFileCreated
    | SseMessageType.CardFileUpdated
    | SseMessageType.CardFilesDeleted;
  cardId: number;
  fileView?: IFileLink;
  fileLinkIds?: number[];
};

export type CardSseMessage = {
  type: SseMessageType.CardUpdated;
  card: ICard;
  fromUserDevice: boolean;
};

export type CardFoundInHotlistSseMessage = {
  type: SseMessageType.CardFoundInHotlist;
  cardId: number;
  cardAlias: string;
};
