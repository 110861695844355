import { appColors } from '@/Providers/ThemeProvider/colors';
import { PlayArrow, Refresh, Upload } from '@mui/icons-material';
import { Button, Divider, Toolbar } from '@mui/material';
import { observer } from 'mobx-react-lite';

const HeaderDivider = () => {
  return <Divider variant='middle' orientation='vertical' style={{ margin: '0 16px' }} />;
};
interface SamplesHeaderProps {}

export const SamplesHeader = observer((props: SamplesHeaderProps) => {
  return (
    <Toolbar style={{ padding: '10px 16px 0' }}>
      <Button
        style={{ background: appColors.bgPrimary, color: 'white', padding: '6px 24px' }}
        startIcon={<PlayArrow />}
      >
        Начать сбор
      </Button>
      <HeaderDivider />
      <Button style={{ padding: '6px 0' }} startIcon={<Upload />}>
        Добавить из файла
      </Button>
      <HeaderDivider />
      <Button disabled style={{ padding: '6px 0' }} startIcon={<Refresh />}>
        Очистить всё
      </Button>
    </Toolbar>
  );
});
