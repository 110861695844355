const getRenderedSize = ({
  containerHeight,
  containerWidth,
  height,
  width,
}: {
  containerWidth: number;
  containerHeight: number;
  width: number;
  height: number;
}) => {
  const doRatio = width / height;
  const cRatio = containerWidth / containerHeight;
  let targetWidth = 0;
  let targetHeight = 0;

  if (doRatio > cRatio) {
    targetWidth = containerWidth;
    targetHeight = targetWidth / doRatio;
  } else {
    targetHeight = containerHeight;
    targetWidth = targetHeight * doRatio;
  }

  return {
    width: targetWidth,
    height: targetHeight,
    x: (containerWidth - targetWidth) / 2,
    y: (containerHeight - targetHeight) / 2,
  };
};

interface Size {
  width: number;
  height: number;
  x: number;
  y: number;
}

export const getImagePositionInfo = (img: HTMLImageElement): Size => {
  return getRenderedSize({
    containerHeight: img.height,
    containerWidth: img.width,
    width: img.naturalWidth,
    height: img.naturalHeight,
  });
};
