import { isPlainObject } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { FieldValues } from 'react-hook-form';
import { ConditionType } from 'stc-ui-kit';
import { ISideFilterField } from '@/types/cardTemplate';
import { IGridColumnFilter } from '@/types/grid';
import { ICardsGridItem } from '@/types/card';
import {
  convertConditionTypeToDataGridPredicate,
  convertTSystemFieldToICardsGridItem,
} from '@/utils/api/filters';
import {
  ListStringFieldConditions,
  notTemplateFilterKeys,
  StringFieldConditions,
  stringifiedBooleans,
} from '@/modules/multipass/FilingCabinet/components/SideFilter/constants';
import { ConditionAutoCompleteTextMultiFieldValue } from 'stc-ui-kit/components/fields/condition-fields/ConditionAutoCompleteTextMultiField/ConditionAutoCompleteTextMultiField';
import moment from 'moment';

export class SideFilterStore {
  sideFilterFields: ISideFilterField[];

  constructor(sideFilterFields: ISideFilterField[]) {
    this.sideFilterFields = sideFilterFields;

    makeObservable(
      this,
      {
        isSideFilterActive: observable,
        appliedFilter: observable,

        setSideFilterActivity: action,
        applyFilter: action,
        resetFilter: action,

        appliedFilterNotEmptyValues: computed,
        isSideFilterApplied: computed,
        columnFilterMap: computed,
        sideFilterDefaultFieldValues: computed,
      },
      { autoBind: true }
    );
  }

  // флаг, что бокой фильтр открыт
  isSideFilterActive = false;

  // применненный фильтр полностью, с пустыми значениями
  appliedFilter: FieldValues = {};

  // применненный фильтр, только не пустые значения
  get appliedFilterNotEmptyValues() {
    const result: FieldValues = {};
    Object.entries(this.appliedFilter).forEach(([key, filterFieldValue]) => {
      const isNotEmptyObject =
        isPlainObject(filterFieldValue) &&
        'value' in filterFieldValue &&
        filterFieldValue.value != null;

      const isNotEmptyArray = Array.isArray(filterFieldValue) && !!filterFieldValue.length;
      const isNotEmptyString = !!filterFieldValue && typeof filterFieldValue === 'string';

      switch (true) {
        case isNotEmptyObject:
          if (!Array.isArray(filterFieldValue.value)) {
            filterFieldValue.value = [filterFieldValue.value];
          }
          result[key] = filterFieldValue;
          break;

        case isNotEmptyArray:
          result[key] = {
            value: filterFieldValue,
            condition: ConditionType.Contains,
          };
          break;

        case isNotEmptyString:
          result[key] = {
            value: [filterFieldValue],
            condition: stringifiedBooleans.includes(filterFieldValue as string)
              ? ConditionType.Equals
              : ConditionType.Contains,
          };
          break;

        default:
          break;
      }
    });

    return result;
  }

  private getSideFilterFieldById(
    fieldId: string,
    sideFilterFields: ISideFilterField[]
  ): ISideFilterField | null {
    return sideFilterFields.find((sff) => sff.fieldId === fieldId) ?? null;
  }

  get columnFilterMap(): IGridColumnFilter<ICardsGridItem>[] {
    const filters: IGridColumnFilter<ICardsGridItem>[] = [];

    Object.entries(this.appliedFilterNotEmptyValues).forEach(([key, filterFieldValue]) => {
      let filterFieldValueTyped;
      try {
        filterFieldValueTyped = filterFieldValue as ConditionAutoCompleteTextMultiFieldValue;
      } catch (e) {
        console.error(`Error in filter value for field ${key}. Value:`);
        console.error(filterFieldValue);
        console.error(e);

        return;
      }

      if (!filterFieldValueTyped.value) {
        return;
      }

      const sideFilterField = this.getSideFilterFieldById(key, this.sideFilterFields);

      if (!sideFilterField) {
        if (notTemplateFilterKeys.includes(key as keyof ICardsGridItem)) {
          filters.push({
            key: key as keyof ICardsGridItem,
            targetValues: filterFieldValueTyped.value,
            predicate: convertConditionTypeToDataGridPredicate(filterFieldValueTyped.condition),
          });
        }

        return;
      }

      if ('systemField' in sideFilterField.field && !!sideFilterField.field.systemField) {
        // поле системное
        if (
          sideFilterField.field.type === 'String' ||
          sideFilterField.field.type === 'Dict' ||
          sideFilterField.field.type === 'ListString' ||
          sideFilterField.field.type === 'ListDict'
        ) {
          filters.push({
            key: convertTSystemFieldToICardsGridItem(sideFilterField.field.systemField),
            targetValues: filterFieldValueTyped.value,
            predicate: convertConditionTypeToDataGridPredicate(filterFieldValueTyped.condition),
          });
        } else if (
          sideFilterField.field.type === 'Date' ||
          sideFilterField.field.type === 'DateTime'
        ) {
          // TODO
          filters.push({
            key: convertTSystemFieldToICardsGridItem(sideFilterField.field.systemField),
            targetValues: filterFieldValueTyped.value.map((v) => moment(v).format()),
            predicate: convertConditionTypeToDataGridPredicate(ConditionType.Equals),
          });
        }
      } else {
        // поле не системное
        if (
          sideFilterField.field.type === 'String' ||
          sideFilterField.field.type === 'Dict' ||
          sideFilterField.field.type === 'ListString' ||
          sideFilterField.field.type === 'ListDict' ||
          sideFilterField.field.type === 'Date'
        ) {
          filters.push({
            key: 'data',
            jsonFields: [
              {
                fieldId: 'data',
                targetValues: filterFieldValueTyped.value,
                predicate: filterFieldValueTyped.condition,
                jsonFieldId: key,
              },
            ],
            targetValues: [],
            predicate: convertConditionTypeToDataGridPredicate(filterFieldValueTyped.condition),
          });
        }
      }
    });

    return filters;
  }

  get sideFilterDefaultFieldValues(): FieldValues {
    const result: FieldValues = {};
    this.sideFilterFields.forEach((ff) => {
      switch (ff.field.type) {
        case 'Date':
          result[ff.fieldId] = null;
          break;

        case 'ListDict':
        case 'Dict':
          result[ff.fieldId] = null;
          break;
        // case 'DateTime':
        // case 'Int':
        // case 'ListObject':

        case 'ListString':
          result[ff.fieldId] = { value: null, condition: ListStringFieldConditions[0] };
          break;

        case 'String':
          result[ff.fieldId] = { value: null, condition: StringFieldConditions[0] };
          break;
      }
    });

    return result;
  }

  // флаг, что боковой фильтр не пуст и применен
  get isSideFilterApplied() {
    return Object.entries(this.appliedFilterNotEmptyValues).length > 0;
  }

  // открыть/закрыть боковой фильтр
  setSideFilterActivity = (value: boolean) => {
    this.isSideFilterActive = value;
  };

  // применить боковой фильтр
  applyFilter = (value: FieldValues) => {
    this.appliedFilter = value;
  };

  // сбросить боковой фильтр
  resetFilter = () => {
    this.appliedFilter = {};
  };
}
