import { useSelectionModel } from '@/hooks/grid/useSelectionModel';
import { GridStore } from '@/stores/grid.store';
import { EntityWithId } from '@/types/entity';
import { observer } from 'mobx-react';
import { ScrollDirection, Table, TableColumn, TableRow, useTable } from 'stc-ui-kit';

import styles from './stcGridTable.module.scss';

type AllTableProps<T> = Parameters<typeof Table<T>>[0];

type Props<T> = {
  store: GridStore<T>;
  columns: TableColumn<T>[];
  tableProps?: Pick<
    AllTableProps<T>,
    | 'onRowDoubleClick'
    | 'onSelect'
    | 'renderEmptyPlaceholder'
    | 'isBorderDisabled'
    | 'selectedItemsIds'
  >;
};

export const StcGridTable = observer(
  <T extends EntityWithId>({ store, columns: defaultColumns, tableProps }: Props<T>) => {
    const { selectMany } = useSelectionModel(store);
    const items = store.data.map((d) => ({ ...d, id: d.id.toString() }));

    const {
      columns,
      isItemsLoading: isTableLoading,
      resizeColumn,
      onScroll,
      pagination,
      viewMode,
    } = useTable({
      isPageable: false,
      isLazyLoading: true,
      items,
      setItems: () => {
        //
      },
      getItems: () => {
        return Promise.resolve([] as TableRow<T>[]);
      },
      defaultFilter: {},
      defaultColumns,
      setFilter: () => {
        //
      },
      getFilter: () => ({
        current: {},
        applied: {},
      }),
      resetSelectedItems: store.resetChecked,
      getSettings: () => null,
      saveSettings: () => Promise.resolve(),
      onGetItemsError: () => null,
    });

    const isItemsLoading = store.isPending || isTableLoading;

    return (
      <Table
        {...tableProps}
        isPaddingDisabled
        items={items}
        columns={columns}
        onColumnResize={resizeColumn}
        isItemsLoading={isItemsLoading}
        onScroll={async (e) => {
          const isScrolledToBottom = store.data.length - e.lastVisibleIndex < 6;

          if (
            e.scrollDirection !== ScrollDirection.Forward ||
            !store.hasNextPage ||
            !isScrolledToBottom ||
            store.isPending
          ) {
            return;
          }

          await store.loadData({
            ...store.state,
            offset: items.length,
            limit: store.rowsOnPage,
            paginationType: 'offset',
            appendPrevData: true,
          });

          return onScroll(e);
        }}
        pagination={pagination}
        viewMode={viewMode}
        onSelect={(ids) => {
          const selected = items.filter((item) => ids.includes(item.id));
          selectMany(selected);
          tableProps?.onSelect?.(ids);
        }}
        selectedItemsIds={
          tableProps?.selectedItemsIds || store.checkedItems.map((item) => item.id.toString())
        }
        data-testid=''
        className={styles.stctable}
        isBorderDisabled={tableProps?.isBorderDisabled || false}
      />
    );
  }
);
