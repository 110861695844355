import rolesService from '@/services/roles.service';
import { IPrivilegeSection, IRoleWithPrivilegesInGroups, IRoleWithPrivs } from '@/types/role';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { mergeRolesAndPrivilegeSections } from '../utils/mergeRolesAndPrivilegeGroups';

interface ContextValues {
  roles: IRoleWithPrivs[];
  sections: IPrivilegeSection[];
  rolesWithPrivsInGroups: IRoleWithPrivilegesInGroups[];
  selectedRoleIds: number[];
  fetchData: () => Promise<void>;
  selectRole: (roleId: number) => void;
  resetSelection: () => void;
}

export const RolesContext = createContext<ContextValues | null>(null);

export const RolesContextProvider = ({ children }: PropsWithChildren) => {
  const [roles, setRoles] = useState<IRoleWithPrivs[]>([]);
  const [sections, setSections] = useState<IPrivilegeSection[]>([]);
  const [rolesWithPrivsInGroups, setRolesWithPrivsInGroups] = useState<
    IRoleWithPrivilegesInGroups[]
  >([]);
  const [selectedRoleIds, setSelectedRoleIds] = useState<number[]>([]);

  const fetchData = async () => {
    const roles = await rolesService.getRoles();
    setRoles(roles);
    const sections = await rolesService.getModules();
    setSections(sections);

    const result = mergeRolesAndPrivilegeSections(roles, sections);
    setRolesWithPrivsInGroups(result);
  };

  const resetSelection = () => {
    setSelectedRoleIds([]);
  };

  useEffect(() => {
    void fetchData();
  }, []);

  const selectRole = (roleId: number) => {
    setSelectedRoleIds((prev) =>
      prev.includes(roleId) ? prev.filter((id) => id !== roleId) : [...prev, roleId]
    );
  };

  return (
    <RolesContext.Provider
      value={{
        roles,
        sections,
        rolesWithPrivsInGroups,
        selectedRoleIds,
        fetchData,
        selectRole,
        resetSelection,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};

export const useRolesContext = () => {
  const context = useContext(RolesContext);

  if (!context) throw new Error('Provide card creation context');

  return context;
};
