import { CardPhoto, ICard } from '@/types/card';
import { CardTemplate } from '@/types/cardTemplate';
import { PropsWithChildren, createContext, useEffect, useState } from 'react';
import { useCardPhotos } from '../hooks/useCardPhotos';
import { cardsService } from '@/services/cards.service';
import { useSnackBarMessage } from '@/hooks/useSnackBarMessage';
import { observer } from 'mobx-react';
import { useDataStore } from '@/Providers/StoreProvider';
import { getFileGroupByTab } from '../utils/getFileGroupByTab';
import { EntryTab } from '@/types/ui.types';
import { fetchFileLinks } from '../utils/fetchFileLinks';
import { sortObjectsByCreatedTime } from '../utils/sortObjectsByCreatedTime';
import { EFileProcessStatus, FileGroupType, IFileLink } from '@/types/file';
import { useDeleteFiles } from '../hooks/useDeleteFiles';
import { useCreateNewEntryFromFace } from '../hooks/useCreateNewEntryFromFace';
import { useCardFileSseMessages } from '../hooks/useCardFileSseMessages';
import { FormReturn } from 'stc-ui-kit';
import { FieldValues } from 'react-hook-form';
import { useModelsChangeNotifications } from '../hooks/useModelsChangeNotifications';

interface ContextProps extends PropsWithChildren {
  selectedTemplate: CardTemplate | null;
  formHooks: FormReturn<FieldValues>;
  cardData: ICard | null;
  refetchCardData: (keepDirty?: boolean) => Promise<void>;
  hideAvatar?: boolean;
}

interface ContextValues extends ContextProps {
  photos: CardPhoto[];
  fullScreenPhotos: CardPhoto[];
  isPhotosLoading: boolean;
  isFilesLoading: boolean;
  setCardModel: (modelId: number) => Promise<void>;
  fileLinks: IFileLink[];
  deleteFiles: (ids?: number[], fileGroup?: FileGroupType) => Promise<void>;
  createNewEntry: ({ modelId, fileProcessId }: { modelId: number; fileProcessId: number }) => void;
}

export const CardCreationContext = createContext<ContextValues | null>(null);

export const CardCreationContextProvider = observer(
  ({ formHooks, selectedTemplate, children, cardData, refetchCardData, ...rest }: ContextProps) => {
    const { error } = useSnackBarMessage();
    const { uiStore } = useDataStore();
    useModelsChangeNotifications({ cardData });

    const [isFilesLoading, setIsFilesLoading] = useState(false);
    const [fileLinks, setFileLinks] = useState<IFileLink[]>([]);

    const { photos, fullScreenPhotos, loadAndProcessPhotos, sortPhotos, isPhotosLoading } =
      useCardPhotos({
        cardData,
      });

    const { createNewEntry } = useCreateNewEntryFromFace();

    const openedCard = cardData ? uiStore.getOpenedCard(cardData.id) : null;
    const activeTab: EntryTab =
      (openedCard?.activeTab === 'biometricModels'
        ? openedCard?.activeSideMenuItem
        : openedCard?.activeTab) || 'voice';
    const sortOrder = openedCard?.sortOrder.get(activeTab) || 'DESC';
    const fileGroup = getFileGroupByTab(activeTab);
    const selectedEntitiesIds = openedCard?.selectedEntities.get(activeTab) || [];

    useEffect(() => {
      void refetchTabData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, cardData?.id]);

    useEffect(() => {
      setFileLinks((prev) => [...sortObjectsByCreatedTime(prev, sortOrder)]);
      if (activeTab === 'face') {
        sortPhotos(sortOrder);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortOrder]);

    useEffect(() => {
      const refetchCardPhotos = async () => {
        if (!cardData?.id) return;
        setIsFilesLoading(true);
        const files = await fetchFileLinks(cardData.id, 'FACE');
        if (!files) {
          setIsFilesLoading(false);

          return;
        }

        const sortedFileProcesses = sortObjectsByCreatedTime(files, sortOrder);
        await loadAndProcessPhotos(sortedFileProcesses);
        setIsFilesLoading(false);
      };

      void refetchCardPhotos();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardData?.faceModel?.id, cardData?.id]);

    // on face tab show loader until all files are completed
    useEffect(() => {
      if (activeTab === 'face') {
        setIsFilesLoading(
          fileLinks.some(
            (l) => l.status === EFileProcessStatus.NEW || l.status === EFileProcessStatus.INPROGRESS
          )
        );
      }
    }, [fileLinks, activeTab]);

    const refetchTabData = async () => {
      if (!cardData?.id) return;

      setIsFilesLoading(true);
      const files = (await fetchFileLinks(cardData.id, fileGroup)) || [];

      const sortedFileProcesses = sortObjectsByCreatedTime(files, sortOrder);
      setFileLinks(sortedFileProcesses);
      setIsFilesLoading(false);

      void updateTabUiStore(sortedFileProcesses, cardData.id);
    };

    const updateTabUiStore = async (fileProcesses: IFileLink[], cardId: number) => {
      // добавить другие разделы
      switch (activeTab) {
        case 'face':
          await loadAndProcessPhotos(fileProcesses);
          break;
        default:
          break;
      }

      uiStore.setEntities(
        cardId,
        activeTab,
        fileProcesses.map((f) => {
          return {
            path: f.file.path,
            id: f.id,
            name: f.file.path.split('/').pop() || '',
          };
        })
      );
    };

    useCardFileSseMessages({
      cardId: cardData?.id,
      activeTab,
      refetchCardData,
      setFileLinks,
      updateTabUiStore,
      refetchTabData,
      formHooks,
    });

    const setCardModel = async (modelId: number) => {
      try {
        if (!cardData) return;

        const { errorDescription } = await cardsService.setCardModel({
          cardId: cardData.id,
          modelId,
        });
        if (errorDescription?.description) {
          return error(errorDescription.description);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const mainModelLinkId = (() => {
      const cardFaceModelLinkId = cardData?.faceModel?.fileModelLinks[0]?.cardMediaFileLinkId;
      const cardVoiceModelLinkId = cardData?.voiceModel?.fileModelLinks[0]?.cardMediaFileLinkId;

      switch (activeTab) {
        case 'face':
          return cardFaceModelLinkId || null;
        case 'voice':
          return cardVoiceModelLinkId || null;
        case 'documents':
          return cardFaceModelLinkId || null;
        default:
          return null;
      }
    })();

    const { deleteFiles } = useDeleteFiles({
      cardId: cardData?.id || 0,
      activeTab,
      selectedEntitiesIds,
      mainModelLinkId,
    });

    return (
      <CardCreationContext.Provider
        value={{
          formHooks,
          selectedTemplate,
          cardData,
          photos,
          fullScreenPhotos,
          isPhotosLoading,
          refetchCardData,
          setCardModel,
          fileLinks,
          isFilesLoading,
          deleteFiles,
          createNewEntry,
          ...rest,
        }}
      >
        {children}
      </CardCreationContext.Provider>
    );
  }
);
