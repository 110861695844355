import { CustomTypeFieldDescriptor, FieldDescriptor } from '@/types/cardTemplate';
import { MultiSelectField } from 'stc-ui-kit';
import { Language } from '@/types';

export const renderListDictFilterField = (
  field: FieldDescriptor | CustomTypeFieldDescriptor,
  label: string,
  placeholder: string,
  language: Language
) => {
  if (!field.dictionary) return null;

  const options = field.dictionary.items.map((item) => {
    const label = item.names.find((n) => n.locale === language)?.value || item.name;

    return {
      id: item.id,
      label,
    };
  });

  return (
    <MultiSelectField
      key={field.id}
      id={field.id}
      label={label}
      isChipsView
      options={options}
      placeholder={placeholder}
      data-testid=''
    />
  );
};
