import { cloneDeep } from 'lodash';
import { AdditionalConditions, ExtendedConditions, IApiFieldFilter, IFilterDto } from '@/types';
import {
  DataGridStorePredicates,
  IDataGridStoreData,
  IDataGridStoreState,
  IGridColumnFilter,
  IGridFilter,
} from 'types/grid';
import { ConditionType } from 'stc-ui-kit';
import { ICardsGridItem } from '@/types/card';
import { TSystemField } from '@/types/cardTemplate';

export const prepareFiltersRequestBody = <T>({
  filters,
  body,
}: {
  filters: IDataGridStoreState<T>;
  body: IFilterDto<T>;
}): IFilterDto<T> => {
  const preparedBody = cloneDeep(body);

  if (preparedBody.jsonFields) {
    return {
      limit: preparedBody.limit,
      offset: preparedBody.offset,
      operator: 'Or',
      jsonFields: preparedBody.jsonFields,
    };
  }

  if (filters.paginationType === 'offset') {
    preparedBody.offset = filters.offset;

    return preparedBody;
  }

  if (filters.paginationType === 'cursor') {
    preparedBody.after = filters.after;
    preparedBody.before = filters.before;

    return preparedBody;
  }

  throw new Error(`Pagination ${filters.paginationType as string} not implemented`);
};

export const prepareSideFiltersRequestBody = <T>({
  filters,
  body,
}: {
  filters: IDataGridStoreState<T>;
  body: IFilterDto<T>;
}): IFilterDto<T> => {
  const preparedBody = cloneDeep(body);

  if (preparedBody.jsonFields || preparedBody.fields) {
    return {
      limit: preparedBody.limit,
      offset: preparedBody.offset,
      operator: 'And',
      jsonFields: preparedBody.jsonFields,
      fields: preparedBody.fields,
      sortByFields: preparedBody.sortByFields,
    };
  }

  if (filters.paginationType === 'offset') {
    preparedBody.offset = filters.offset;

    return preparedBody;
  }

  if (filters.paginationType === 'cursor') {
    preparedBody.after = filters.after;
    preparedBody.before = filters.before;

    return preparedBody;
  }

  throw new Error(`Pagination ${filters.paginationType as string} not implemented`);
};

export const prepareFiltersResponseData = <T>({
  filters,
  data,
}: {
  filters: IDataGridStoreState<T>;
  data: T[];
}): IDataGridStoreData<T> => {
  let sliceDataFrom = 0;

  if (filters.paginationType === 'cursor' && filters.before && data.length > filters.limit) {
    sliceDataFrom = 1;
  }

  return {
    data: data.slice(sliceDataFrom, filters.limit + sliceDataFrom),
    hasNextPage: data.length - filters.limit > 0,
  };
};

export function formatColumnFilters<T>(columnFilters: IGridColumnFilter<T>[]): IApiFieldFilter[] {
  return columnFilters.flatMap((f) => {
    if (!f.targetValues || f.targetValues.length === 0) {
      return [];
    }

    return {
      fieldId: String(f.key),
      predicate: convertDataGridPredicate(f.predicate),
      targetValues: f.targetValues,
    };
  });
}

export function formatFilters<T>(quickFilters?: IGridFilter<T>[]): IApiFieldFilter[] {
  if (!quickFilters) return [];

  return Object.values(quickFilters)
    .flat()
    .map((f: IGridFilter<T>) => {
      const result: IApiFieldFilter = {
        fieldId: String(f.fieldId),
        predicate: convertDataGridPredicate(f.predicate),
      };

      if (f.targetValues) {
        result.targetValues = f.targetValues;
      }

      return result;
    });
}

function convertDataGridPredicate(dataGridPredicate: DataGridStorePredicates): ExtendedConditions {
  switch (dataGridPredicate) {
    case DataGridStorePredicates.Equals:
      return ConditionType.Equals;
    case DataGridStorePredicates.NotEquals:
      return ConditionType.NotEquals;
    case DataGridStorePredicates.StartsWith:
      return ConditionType.StartsWith;
    case DataGridStorePredicates.EndsWith:
      return ConditionType.EndsWith;
    case DataGridStorePredicates.Greater:
      return ConditionType.Greater;
    case DataGridStorePredicates.GreaterOrEqual:
      return ConditionType.GreaterOrEquals;
    case DataGridStorePredicates.Less:
      return ConditionType.Less;
    case DataGridStorePredicates.LessOrEqual:
      return ConditionType.LessOrEquals;
    case DataGridStorePredicates.Contains:
      return ConditionType.Contains;
    case DataGridStorePredicates.NotContains:
      return ConditionType.NotContains;
    case DataGridStorePredicates.IsNotNull:
      return AdditionalConditions.NotNull;
    default:
      return ConditionType.Equals;
  }
}

export function convertConditionTypeToDataGridPredicate(
  uiKitPredicate: ConditionType
): DataGridStorePredicates {
  switch (uiKitPredicate) {
    case ConditionType.Contains:
      return DataGridStorePredicates.Contains;
    case ConditionType.EndsWith:
      return DataGridStorePredicates.EndsWith;
    case ConditionType.Equals:
      return DataGridStorePredicates.Equals;
    case ConditionType.Greater:
      return DataGridStorePredicates.Greater;
    case ConditionType.GreaterOrEquals:
      return DataGridStorePredicates.GreaterOrEqual;
    case ConditionType.Less:
      return DataGridStorePredicates.Less;
    case ConditionType.LessOrEquals:
      return DataGridStorePredicates.LessOrEqual;
    case ConditionType.NotContains:
      return DataGridStorePredicates.NotContains;
    case ConditionType.NotEndsWith:
      return DataGridStorePredicates.NotEndsWith;
    case ConditionType.NotEquals:
      return DataGridStorePredicates.NotEquals;
    case ConditionType.NotStartsWith:
      return DataGridStorePredicates.NotStartsWith;
    case ConditionType.StartsWith:
      return DataGridStorePredicates.StartsWith;
    default:
      return DataGridStorePredicates.Equals;
  }
}

function firstLetterToLowerCase(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function convertTSystemFieldToICardsGridItem(
  systemField: TSystemField
): keyof ICardsGridItem {
  return firstLetterToLowerCase(systemField) as keyof ICardsGridItem;
}
