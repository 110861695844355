import { Box } from '@mui/material';
import { Button, ButtonType, icons, Tooltip } from 'stc-ui-kit';
import { useTranslation } from 'react-i18next';
import { cardsService } from '@/services/cards.service';
import { GridStore } from '@/stores/grid.store';
import { ECardExportStatus, ICardsGridItem } from '@/types/card';
import { observer } from 'mobx-react';
import { showInfoNotification } from '@/utils/notifications';

export interface ExportInfo {
  status: 'none' | 'in-progress' | 'error' | 'success';
  error: null | string;
}

interface Props {
  store: GridStore<ICardsGridItem>;
}

const STATUSES_TO_EXPORT: Set<ECardExportStatus> = new Set([
  ECardExportStatus.NEED_EXPORT,
  ECardExportStatus.EXPORTED,
]);

export const ExportCardsButton: React.FC<Props> = observer(({ store }) => {
  const { t } = useTranslation();

  const exportCards = async () => {
    const idsToExport = store.checkedItems
      .filter((card) => STATUSES_TO_EXPORT.has(card.exportStatus))
      .map((card) => Number(card.id));

    if (idsToExport.length) {
      const data = await cardsService.exportCards(idsToExport);
      if (data.statusCode === 200) {
        showInfoNotification({
          header: t('messages.cardsExported', { ids: idsToExport.join(', ') }),
        });
      }
    }
  };

  return (
    <Box display='flex' alignItems='center' gap='16px'>
      <Tooltip tooltip={t('cards.export')}>
        <Button type={ButtonType.Icon} onClick={exportCards} icon={icons.IconFileUpload} />
      </Tooltip>
    </Box>
  );
});
