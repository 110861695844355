import { Dispatch, SetStateAction, useEffect } from 'react';
import { useDataStore } from '@/Providers/StoreProvider';
import { ICard } from '@/types/card';
import { FieldValues, UseFormReset } from 'react-hook-form';

export const useCardSseMessage = ({
  cardData,
  setCardData,
  reset,
}: {
  cardData: ICard | null;
  setCardData: Dispatch<SetStateAction<ICard | null>>;
  reset: UseFormReset<FieldValues>;
}) => {
  const { sseStore } = useDataStore();

  useEffect(() => {
    const message = sseStore.cardMessage;
    if (!message) return;
    if (message.card.id !== cardData?.id) return;

    if (message.fromUserDevice) {
      setCardData(message.card);
      reset(message.card.data);
    } else {
      setCardData((prev) => {
        if (!prev) return prev;

        const faceModelChanged = cardData?.faceModel?.id !== message.card.faceModel?.id;
        const voiceModelChanged = cardData?.voiceModel?.id !== message.card.voiceModel?.id;

        if (faceModelChanged || voiceModelChanged) {
          return {
            ...prev,
            faceModel: faceModelChanged ? message.card.faceModel : prev?.faceModel,
            voiceModel: voiceModelChanged ? message.card.voiceModel : prev?.voiceModel,
          };
        }

        return prev;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sseStore.cardMessage]);
};
