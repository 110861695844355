import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledPaper } from '@/styles/Paper/StyledPaper';
import { Button } from 'stc-ui-kit';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';

interface ButtonProps {
  onClick?: () => void;
  isDisabled?: boolean;
}
interface Props {
  buttonsProps: {
    save: ButtonProps;
    saveAndExit: ButtonProps;
    exit: ButtonProps;
  };
}

export const CardCreationFooter: React.FC<Props> = ({ buttonsProps }) => {
  const { t } = useTranslation();
  const { save, exit, saveAndExit } = buttonsProps;
  const { havePermissions } = useUserPermissionsContext();

  return (
    <StyledPaper
      sx={{
        height: 'auto',
        minHeight: '68px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        background: appColors.darkThemeBackground,
        display: 'flex',
        alignItems: 'center',
        paddingInline: '16px',
        gap: '16px',
      }}
    >
      {havePermissions(permissionControl.enrollment.cardCreation.saveButton) && (
        <>
          <Button {...save}>{t('save')}</Button>
          <Button {...saveAndExit}>{t('saveAndClose')}</Button>
        </>
      )}
      <Button {...exit}>{t('close')}</Button>
    </StyledPaper>
  );
};
