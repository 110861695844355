import { Fragment } from 'react';
import { Grid } from '@mui/material';
import TemplateFieldRenderer from './TemplateFieldRenderer';

import { CardTemplateData, CardTemplateLayout } from '@/types/cardTemplate';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';

interface IProps {
  templateData: CardTemplateData;
  isColumn?: boolean;
  customTypeIdGetter?: (fieldId: string) => string;
}

export default function TemplateLayoutRenderer({
  templateData,
  isColumn = false,
  customTypeIdGetter,
}: IProps) {
  const { havePermissions } = useUserPermissionsContext();
  const layoutTemplateRender = (layout: CardTemplateLayout, key = 0) => {
    if (!layout) return null;

    const gridItemProps = {
      item: true,
      xs: isColumn ? 12 : layout.xs,
      sm: isColumn ? 12 : layout.sm,
      md: isColumn ? 12 : layout.md,
      lg: isColumn ? 12 : layout.lg,
      xl: isColumn ? 12 : layout.xl,
    };

    const gridContainerProps = {
      container: true,
      spacing: 3,
      rowSpacing: 1,
      direction: isColumn ? 'row' : layout.direction,
    };

    const fieldData = templateData.fields.find((field) => field.id === layout.fieldId);

    return (
      <Fragment key={key}>
        {layout.type === 'container' && (
          <Grid {...gridContainerProps}>
            {!!layout.children?.length &&
              layout.children.map((child, ind) => layoutTemplateRender(child, ind))}
          </Grid>
        )}

        {layout.type === 'itemContainer' && (
          <Grid {...gridItemProps} {...gridContainerProps}>
            {!!layout.children?.length &&
              layout.children.map((child, ind) => layoutTemplateRender(child, ind))}
          </Grid>
        )}

        {layout.type === 'item' && !!layout.fieldId && !!fieldData && (
          <Grid {...gridItemProps}>
            <TemplateFieldRenderer
              isColumn={isColumn}
              fieldData={fieldData}
              customTypeIdGetter={customTypeIdGetter}
              templateData={templateData}
              readonly={!havePermissions(permissionControl.enrollment.cardCreation.editFields)}
            />
          </Grid>
        )}
      </Fragment>
    );
  };

  return layoutTemplateRender(templateData.layout);
}
