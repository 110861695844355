import { ECardExportStatus, ICard, ICardsGridItem } from '@/types/card';
import { ConditionType } from 'stc-ui-kit';

export const StringFieldConditions = [
  ConditionType.Contains,
  ConditionType.Equals,
  ConditionType.NotEquals,
  ConditionType.StartsWith,
  ConditionType.EndsWith,
];
export const ListStringFieldConditions = [ConditionType.Contains];
export const DictFieldConditions = [ConditionType.Contains];

export const DateFieldConditions = [
  ConditionType.Equals,
  ConditionType.NotEquals,
  ConditionType.Less,
  ConditionType.Greater,
];
export const ListDictFieldConditions = [ConditionType.Contains];

export const notTemplateFilterKeys: Array<keyof ICardsGridItem> = ['isInHotlist', 'exportStatus'];
export const stringifiedBooleans: string[] = ['true', 'false'];
export const cardExportStatuses: ECardExportStatus[] = [
  ECardExportStatus.NEW,
  ECardExportStatus.NEED_EXPORT,
  ECardExportStatus.EXPORTED,
];

export const exportStatusId: keyof ICardsGridItem = 'exportStatus';
export const isInHotlistId: keyof ICardsGridItem = 'isInHotlist';
