import {
  CardFileSseMessage,
  CardFoundInHotlistSseMessage,
  CardSseMessage,
  SseMessage,
  SseMessageType,
} from '@/types/sse';
import { makeAutoObservable } from 'mobx';

class SseStore {
  constructor() {
    makeAutoObservable(this);
  }

  isReconnectedAfterError = false;
  cardMessage: CardSseMessage | null = null;
  cardFileMessage: CardFileSseMessage | null = null;
  cardFoundInHotlistMessage: CardFoundInHotlistSseMessage | null = null;

  setIsReconnectedAfterError = (value: boolean) => {
    this.isReconnectedAfterError = value;
  };

  setCardFoundInHotlistMessage = (message: CardFoundInHotlistSseMessage | null) => {
    this.cardFoundInHotlistMessage = message;
  };

  setMessage = (message: SseMessage) => {
    switch (message.type) {
      case SseMessageType.CardUpdated:
        this.cardMessage = message;
        break;

      case SseMessageType.CardFileCreated:
      case SseMessageType.CardFileUpdated:
      case SseMessageType.CardFilesDeleted:
        this.cardFileMessage = message;
        break;

      case SseMessageType.CardFoundInHotlist:
        this.setCardFoundInHotlistMessage(message);
        break;

      default:
        break;
    }
  };
}

export default SseStore;
