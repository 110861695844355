import { useSnackbar } from 'notistack';
import { Outlet, useMatch, useParams } from 'react-router-dom';
import { useDataStore } from '@/Providers/StoreProvider';
import { SideNav } from '@/components/SideNav/SideNav';
import { ROUTES } from '@/types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICard } from '@/types/card';
import { cardsService } from '@/services/cards.service';
import { icons, Layout, LayoutDirection, LayoutPadding, SidebarDynamicTab } from 'stc-ui-kit';
import { SidebarStaticTab } from 'stc-ui-kit/components/Sidebar/types';
import { HotlistSseMessageObserver } from './HotlistSseMessageObserver';
import { getCardAliasWithCreationDate } from '@/utils/getCardAliasWithCreationDate';

export const Multipass = () => {
  const isOnCardCreationPage = useMatch(ROUTES.MULTIPASS_CARD_CREATION);
  const isOnCardEditPage = useMatch(ROUTES.MULTIPASS_CARD_EDIT);
  const [editingCard, setEditingCard] = useState<ICard | null>(null);
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const { searchRequestRootStore, templatesStore } = useDataStore(); // получить глобальное значение

  const store = searchRequestRootStore.requestListStore;

  store.init(enqueueSnackbar);

  useEffect(() => {
    const fetchEditingCard = async (id: number) => {
      try {
        const { res } = await cardsService.getById(id);

        if (!res) return;

        setEditingCard(res);
      } catch (error) {
        console.error(error);
      }
    };

    if (isOnCardEditPage && params.id) {
      void fetchEditingCard(Number(params.id));
    } else {
      setEditingCard(null);
    }
  }, [isOnCardEditPage, params.id]);

  const staticTabs: SidebarStaticTab[] = useMemo(() => {
    return [
      // {
      //   route: ROUTES.MULTIPASS_LOAD_FILE,
      //   title: t('multipass.samples'),
      //   icon: icons.IconSummary,
      // },
      // {
      //   route: ROUTES.MULTIPASS_INTERACTIVE_CARD,
      //   title: t('multipass.interactiveCard'),
      //   icon: icons.IconRecording,
      // },
      {
        route: ROUTES.MULTIPASS_FILLING_CABINET,
        title: t('multipass.entries'),
        icon: icons.IconSummary,
      },
    ];
  }, [t]);

  const dynamicTabs = useMemo(() => {
    const createSideNavItemForEditingCard = (card: ICard): SidebarDynamicTab => {
      const cardTemplate = templatesStore.templates.find((t) => t.id === card.entryTemplateId);

      return {
        route: ROUTES.MULTIPASS_CARD_EDIT.replace(':id', card.id.toString()),
        baseRoute: ROUTES.MULTIPASS_FILLING_CABINET,
        icon: icons.IconSummaryEdit,
        title: getCardAliasWithCreationDate(card, cardTemplate),
        isCloseDisabled: true,
      };
    };

    const items: SidebarDynamicTab[] = [];

    if (isOnCardCreationPage) {
      items.push({
        baseRoute: ROUTES.MULTIPASS_FILLING_CABINET,
        icon: icons.IconSummaryAdd,
        title: t('multipass.newEntry'),
        route: ROUTES.MULTIPASS_CARD_CREATION,
      });
    }

    if (isOnCardEditPage && editingCard) {
      items.push(createSideNavItemForEditingCard(editingCard));
    }

    return items;
  }, [editingCard, isOnCardCreationPage, isOnCardEditPage, t, templatesStore.templates]);

  return (
    <Layout direction={LayoutDirection.Horizontal} padding={LayoutPadding.None}>
      <HotlistSseMessageObserver />
      <SideNav
        staticTabs={staticTabs}
        dynamicTabs={dynamicTabs}
        onTabsClose={() => null}
        onTabsOrderChange={() => null}
      />
      <Outlet />
    </Layout>
  );
};
