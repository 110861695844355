import React, { useCallback, useEffect, useState } from 'react';
import { getImagePositionInfo } from 'utils/image/getImagePositionInfo';

export const useImageSize = ({ imageRef }: { imageRef: React.RefObject<HTMLImageElement> }) => {
  const [imageOriginalSize, setImageOriginalSize] = useState({ width: 0, height: 0 });
  const [imagePosition, setImagePosition] = useState({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });
  const ratio =
    imageOriginalSize.width && imagePosition.width
      ? imagePosition.width / imageOriginalSize.width
      : null;

  const changeImageSizes = useCallback((image: HTMLImageElement) => {
    const pos = getImagePositionInfo(image);
    setImagePosition(pos);
    setImageOriginalSize({
      width: image.naturalWidth,
      height: image.naturalHeight,
    });
  }, []);

  useEffect(() => {
    const imageEl = imageRef.current;

    const observer = new ResizeObserver((entries) => {
      const image = entries[0].target as HTMLImageElement;

      if (!image || !image.naturalWidth || !image.naturalHeight) return;

      changeImageSizes(image);
    });

    if (imageEl) {
      observer.observe(imageEl);
    }

    return () => {
      imageEl && observer.unobserve(imageEl);
    };
  }, [changeImageSizes, imageRef]);

  return {
    ratio,
    imagePosition,
    changeImageSizes,
  };
};
