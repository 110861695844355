import { useEffect, useRef } from 'react';
import { CancelRequestSignal, Player, useFileTranslationPlayer } from 'stc-ui-kit';
import { PlayerNrfFilter } from 'stc-ui-kit/components/Player/filters/types';
import { HotkeysPlayer, PlayerSettings } from 'stc-ui-kit/components/Player/types';
import { v4 as uuidv4 } from 'uuid';
import styles from './WmsPlayer.module.scss';
import { Box } from '@mui/material';
import { OscillogramSegment } from 'stc-ui-kit/components/Player/Oscillogram/Oscillogram';
import { makePlayerUrl, makePlayerWsUrl } from '@/services/root.api.service';
import { useTranslation } from 'react-i18next';
import { useNonDuplicatedNotifications } from '@/hooks/useNonDuplicatedNotifications';

const DEFAULT_PLAYER_SETTINGS: PlayerSettings = {
  volume: 50,
  speed: 1,
  balance: 0,
  isOscillogramVisible: true,
  agcFilter: {
    isEnabled: false,
    baseLevel: 0,
    isReduceStrong: false,
    isAmplifyWeak: false,
  },
  nrfFilter: {
    isEnabled: false,
    gainMax: 1,
    lowFrequencyCutoff: 0,
    highFrequencyCutoff: 0,
    lowFrequencySlope: 0,
    highFrequencySlope: 0,
  },
  isMixingEnabled: false,
};

const HOTKEYS: HotkeysPlayer = {
  play: {
    code: 'Enter',
    altKey: false,
    ctrlKey: false,
    shiftKey: false,
  },
  playPause: {
    code: 'Space',
    altKey: false,
    ctrlKey: false,
    shiftKey: false,
  },
  volumeHigh: {
    code: 'ArrowUp',
    altKey: false,
    ctrlKey: false,
    shiftKey: false,
  },
  volumeLow: {
    code: 'ArrowDown',
    altKey: false,
    ctrlKey: false,
    shiftKey: false,
  },
  speedDecrease: null,
  rewindForward: {
    code: 'ArrowRight',
    altKey: false,
    ctrlKey: false,
    shiftKey: false,
  },
  rewindBack: {
    code: 'ArrowLeft',
    altKey: false,
    ctrlKey: false,
    shiftKey: false,
  },
  loopMode: null,
  speedIncrease: null,
  toggleMixing: null,
};

type FileTranslationParams = {
  recordId: string;
  recordFileId: string;
};

interface IWmsPlayerProps {
  fileName: string | null;
  segments?: OscillogramSegment[];
  isCompact?: boolean;
}

export function WmsPlayer({ fileName, segments, isCompact = false }: IWmsPlayerProps) {
  const playerId = useRef(`player_${uuidv4()}`);
  const { t } = useTranslation();
  const { showErrorNotification } = useNonDuplicatedNotifications();

  // костыль для отключения автоплея
  const forcePause = useRef(false);

  useEffect(() => {
    if (!fileName) return;

    const init = async () => {
      await loadTranslation({
        recordId: fileName,
        recordFileId: decodeURIComponent(fileName),
      });

      pause();
      forcePause.current = true;
    };

    void init();

    return pause;
    // eslint-disable-next-line
  }, [fileName]);

  const {
    volume,
    speed,
    balance,
    isOscillogramVisible,
    agcFilter,
    nrfFilter,
    onVolumeChange,
    onSpeedChange,
    onBalanceChange,
    onOscillogramVisibilityChange,
    onAgcFilterChange,
    onNrfFilterChange,
    play,
    pause,
    seek,
    startPlayerDate,
    endPlayerDate,
    currentMillisecondsPosition,
    mediaType,
    isLoading,
    error,
    isPlaying,
    oscillogramPeaksCount,
    oscillogramChannelsPeaks,
    onMediaElementDidMount,
    loopRegion,
    onChangeLoopRegion,
    loadTranslation,
    reloadTranslation,
  } = useFileTranslationPlayer({
    playerId: playerId.current,

    translationApi: {
      getFileTranslation: (
        params: FileTranslationParams,
        cancelRequestSignal: CancelRequestSignal
      ) =>
        fetch(makePlayerUrl('files/by-path'), {
          method: 'POST',
          body: JSON.stringify({
            FilePath: params.recordFileId,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }).then((res) => {
          const data = res.json();

          return data;
        }),
      getFileTranslationErrorCode: (error) => {
        if (error instanceof Error) {
          if (error.name === 'RangeError' && error.message === 'Invalid time value') {
            showErrorNotification({
              header: `404 ${t('fileIsNotFound')}`,
              message: 'BL service error occurred',
            });
          }

          if (error.name === 'SyntaxError' && error.message.startsWith('Unexpected token')) {
            showErrorNotification({
              header: `${t('messages.playerIsNotResponding')}`,
              message: '',
            });
          }
        }

        return 0;
      },

      errorCodeRequestCancelled: 0,

      seekFileTranslation: ({
        mediaStreamId,
        positionSeconds,
        bufferedSeconds,
      }: {
        mediaStreamId: string;
        positionSeconds: number;
        bufferedSeconds: number;
      }) => {
        return fetch(
          makePlayerUrl(
            `files/${mediaStreamId}/seek?positionSec=${positionSeconds}&bufferSec=${bufferedSeconds}`
          ),
          {
            method: 'POST',
          }
        ) as unknown as Promise<void>;
      },
      pullFileStream: (mediaStreamId: string, positionSeconds: number) => {
        fetch(makePlayerUrl(`files/${mediaStreamId}/pull-till?positionSec=${positionSeconds}`), {
          method: 'POST',
        }) as unknown as Promise<void>;
      },
      getPlayerSettings: () => DEFAULT_PLAYER_SETTINGS,

      savePlayerSettings: (settings: PlayerSettings) => Promise.resolve(),

      setFileTranslationNrfFilter: (mediaStreamId: string, nrfFilter: PlayerNrfFilter) =>
        fetch(makePlayerUrl(`files/${mediaStreamId}/settings`), {
          method: 'POST',
          body: JSON.stringify({
            AutoGainControlSettings: {
              BaseLevel: 0,
              ReduceStrong: true,
              AmplifyWeak: true,
            },
            NoiseReductionSettings: {
              GainMax: nrfFilter.gainMax,
              Lf: nrfFilter.lowFrequencyCutoff,
              Hf: nrfFilter.highFrequencyCutoff,
              Lk: nrfFilter.lowFrequencySlope,
              Hk: nrfFilter.highFrequencySlope,
            },
          }),
        }) as unknown as Promise<void>,

      enableFileTranslationNrfFilter: (mediaStreamId: string) =>
        fetch(makePlayerUrl(`files/${mediaStreamId}/nrf?enable=true`), {
          method: 'POST',
        }) as unknown as Promise<void>,

      disableFileTranslationNrfFilter: (mediaStreamId: string) =>
        fetch(makePlayerUrl(`files/${mediaStreamId}/nrf?enable=false`), {
          method: 'POST',
        }) as unknown as Promise<void>,
    },

    onPlayingStateChange: (playerId: string, isPlaying: boolean) => {
      // Здесь на стороне приложения происходит подсветка вкладки сайдбара с проигрываемым плеером
    },

    getWebsocketConnection: (mediaStreamId: string) =>
      new WebSocket(makePlayerWsUrl(`files/${mediaStreamId}`)),
  });

  const handleReloadPlayer = async () => {
    await reloadTranslation();
  };

  // костыли для отключения автоплея -----
  useEffect(() => {
    if (forcePause.current && isPlaying) {
      pause();
      forcePause.current = false;
    }
  }, [isPlaying, pause]);

  const handlePlay = () => {
    forcePause.current = false;

    play();
  };
  // ------------------

  return (
    <Box className={`${styles.wrapper} ${isCompact ? styles.isCompact : ''}`}>
      <Player
        volume={volume}
        speed={speed}
        balance={balance}
        isOscillogramVisible={isCompact ? false : isOscillogramVisible}
        oscillogramPeaks={oscillogramChannelsPeaks}
        startDate={startPlayerDate}
        oscillogramPeaksCount={oscillogramPeaksCount}
        currentMillisecondsPosition={currentMillisecondsPosition}
        endDate={endPlayerDate}
        onPlay={handlePlay}
        onPause={pause}
        onVolumeChange={onVolumeChange}
        onSpeedChange={onSpeedChange}
        onBalanceChange={onBalanceChange}
        onOscillogramVisibilityChange={onOscillogramVisibilityChange}
        onManualChangePosition={seek}
        loopRegion={loopRegion}
        onChangeLoopRegion={onChangeLoopRegion}
        mediaType={mediaType}
        isLoading={isLoading}
        error={error}
        onMediaElementDidMount={onMediaElementDidMount}
        agcFilter={agcFilter}
        nrfFilter={nrfFilter}
        onAgcFilterChange={onAgcFilterChange}
        onNrfFilterChange={onNrfFilterChange}
        isPlaying={isPlaying}
        getHotkeys={() => Promise.resolve(HOTKEYS)}
        getInitialHeight={() => null}
        saveInitialHeight={() => null}
        onPlayerReload={fileName ? handleReloadPlayer : () => null}
        isMixingEnabled={false}
        onMixingEnable={() => null}
        onMixingDisable={() => null}
      />
    </Box>
  );
}
