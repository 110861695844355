export const backgroundColors = {
  '--violet': 'rgba(111, 100, 246, 1)',
  '--light-grey': 'rgba(190, 206, 218, 0.24)',
  '--blue-gray-400': 'rgba(190, 206, 218, 0.48)',
};

export const iconColors = {
  '--steal': 'rgba(69, 74, 89, 0.72)',
  '--orange-800': 'rgba(217, 104, 0, 1)',
  '--light-red': 'rgba(222, 38, 29, 1)',
  '--light-green': 'rgba(40, 169, 112, 1)',
};

export const borderColors = {
  '--steal-100': 'rgba(69, 74, 89, 0.08)',
  '--steal-200': 'rgba(69, 74, 89, 0.16)',
  '--violet-900': 'rgba(111, 100, 246, 1)',
};

export const textColors = {
  '--light-grey': 'rgba(0, 0, 0, 0.32)',
};