import { Typography } from '@mui/material';
import { Tooltip } from 'stc-ui-kit';

interface GridCellWrapperWithTooltipProps {
  value: string | undefined;
}

export const GridCellWrapperWithTooltip = ({ value }: GridCellWrapperWithTooltipProps) => {
  return (
    <Tooltip tooltip={value}>
      <Typography fontSize={'14px'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {value}
      </Typography>
    </Tooltip>
  );
};
