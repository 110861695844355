import { Modal } from '@/components/Modal/Modal';
import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSnackBarMessage } from '@/hooks/useSnackBarMessage';
import { filesStore } from '@/modules/multipass/stores/Files';
import { ApiError } from '@/utils/ApiError';
import { observer } from 'mobx-react';

interface Props {
  isOpened: boolean;
  onClose: () => void;
}

export const DeleteFileModal: React.FC<Props> = observer(({ isOpened, onClose }) => {
  const { t } = useTranslation();
  const { selectedFile } = filesStore;
  const fileName = useMemo(() => decodeURIComponent(selectedFile?.name || ''), [selectedFile]);

  const { apiError, error, success } = useSnackBarMessage();

  const handlerActionDelete = useCallback(async () => {
    try {
      const res = await filesStore.deleteSelectedFile();
      if (res) {
        success(t('messages.fileDeletedSuccessful', { fileName: fileName }));
      }
    } catch (exc) {
      if (exc instanceof ApiError) {
        apiError(t('messages.failedToDeleteFile'), exc);
      } else {
        error(t('errorOccurred'));
      }
    }
    onClose();
  }, [apiError, error, fileName, onClose, success, t]);

  return (
    <Modal
      open={isOpened}
      onClose={onClose}
      title={t('multipass.deleteFileModal.title')}
      customConfirmButton={
        <Button variant='contained' color='error' onClick={handlerActionDelete}>
          {t('multipass.deleteFileModal.textButtonConfirm')}
        </Button>
      }
    >
      <Box>
        <Typography variant='h6' fontSize='14px' sx={{ wordBreak: 'break-all' }}>
          {`${t('multipass.deleteFileModal.textBody')} ${fileName}?`}
        </Typography>
        <Typography variant='subtitle1' fontSize='14px'>
          {t('thisActionCannotBeUndone')}
        </Typography>
      </Box>
    </Modal>
  );
});
