import { SortOrder } from '@/types/ui.types';

export const sortObjectsByCreatedTime = <T extends { createdTime?: string }[]>(
  fileProcesses: T,
  sortOrder: SortOrder
) => {
  return fileProcesses.sort((a, b) => {
    if (!a.createdTime || !b.createdTime) return 0;

    if (sortOrder === 'ASC') {
      return new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime();
    } else {
      return new Date(b.createdTime).getTime() - new Date(a.createdTime).getTime();
    }
  });
};
