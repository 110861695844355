import { DatePickerField } from 'stc-ui-kit';
import { useTemplateFieldContext } from '../../../hooks/useTemplateFieldContext';
import { useCardCreationContext } from '../../../hooks/useCardCreationContext';
import { formatDateInputValue } from '@/utils/formatDateInputValue';

export const DateTemplateField = () => {
  const { fieldData, fieldName, customTypeFieldId, readonly } = useTemplateFieldContext();
  const { formHooks } = useCardCreationContext();

  const fieldId = customTypeFieldId || fieldData.id;

  return (
    <DatePickerField
      id={fieldId}
      isRequired={fieldData.required}
      label={fieldName}
      data-testid=''
      onChange={formatDateInputValue({ form: formHooks, fieldId })}
      isReadOnly={readonly}
    />
  );
};
