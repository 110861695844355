import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';

import { ContentWithHeaderWrapper } from '@/components/common/Pages/ContentWithHeaderWrapper';
import { filesStore } from '@/modules/multipass/stores/Files';
import { EFileProcessStatus } from '@/types/file';
import { formatMsToTime } from '@/utils/date/formatMsToTime';
import { LoadingButton } from '@mui/lab';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DictorsContent } from '../Dictors';
import styles from './FileProcessingContent.module.scss';

export const FileProcessingContent = observer(() => {
  const { t } = useTranslation();
  const { selectedFile, setSelectedFile } = filesStore;

  const showDictorsContent = useMemo(
    () =>
      selectedFile?.status &&
      [EFileProcessStatus.COMPLETED, EFileProcessStatus.VERIFIED].includes(selectedFile.status),
    [selectedFile?.status]
  );

  useEffect(() => {
    if (selectedFile?.id && showDictorsContent) {
      void filesStore.loadDictorsInfo();
    }
  }, [selectedFile?.id, showDictorsContent]);

  if (!selectedFile) return null;

  const statusToMessage: Record<EFileProcessStatus, string> = {
    COMPLETED: '',
    VERIFIED: '',
    DUPLICATE: t('fileWasPreviouslyUploaded', {
      name: selectedFile.originalName ? decodeURIComponent(selectedFile.originalName) : '',
      interpolation: {
        escapeValue: false,
      },
    }),
    FAILED: t('multipass.fileProcessingError'),
    IN_PROGRESS: t('fileInProgress'),
    NEW: t('fileInProgress'),
    NO_MODEL: '',
  };

  return (
    <ContentWithHeaderWrapper
      leftSideHeader={
        showDictorsContent && (
          <>
            <Box className={styles.headerItem}>
              {t('multipass.foundedDictors')}: <span>{filesStore.dictorsInfo?.length}</span>
            </Box>
            <Box className={styles.headerItem}>
              {t('multipass.audioDuration')}:{' '}
              <span>{formatMsToTime(+(selectedFile?.fileData?.durationSec ?? 0) * 1000)}</span>
            </Box>
            <Box className={styles.headerItem}>
              {t('multipass.pureSpeechDuration')}:{' '}
              <span>
                {formatMsToTime(
                  (filesStore.dictorsInfo?.reduce((acc, curr) => {
                    return (acc += Math.ceil(curr.data.speechTimeSec || 0));
                  }, 0) ?? 0) * 1000
                )}
              </span>
            </Box>
          </>
        )
      }
      rightSideHeader={
        <>
          <LoadingButton
            variant='contained'
            sx={{ marginRight: '16px' }}
            loading={filesStore.loading}
            disabled={!showDictorsContent}
            onClick={async () => await filesStore.updateStatus()}
          >
            {selectedFile?.status === EFileProcessStatus.COMPLETED
              ? t('multipass.verifyFile')
              : t('resume')}
          </LoadingButton>
          <button className={styles.closeBtn} onClick={() => setSelectedFile(null)}>
            <CloseIcon />
          </button>
        </>
      }
    >
      {!showDictorsContent ? (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography margin={10}>{statusToMessage[selectedFile.status]}</Typography>
        </Box>
      ) : (
        <DictorsContent selectedFileId={selectedFile.id} />
      )}
    </ContentWithHeaderWrapper>
  );
});
