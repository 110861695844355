import { WmsPlayer } from '@/components/WmsPlayer';
import {
  Button,
  ButtonStyle,
  ButtonType,
  Form,
  icons,
  ListItemModel,
  SelectField,
  SelectFieldValue,
  Separator,
  Toolbar,
  useForm,
} from 'stc-ui-kit';
import { SelectOption } from 'stc-ui-kit/types/common';
import { IFileLink } from '@/types/file';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { useCardCreationContext } from '../../../hooks/useCardCreationContext';
import styles from './VoiceModels.module.scss';
import { getModelIcon } from '@/modules/multipass/utils/getModelIcon';
import { Box } from '@mui/material';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';
import { VoiceModelFileData } from '@/types/model';
import { convertSecondsToTime } from '@/utils/convertSecondsToTime';
import { Void } from '@/components/common/Void';

interface IProps {
  selectedProcess: IFileLink;
  onClose: () => void;
}

export function VoiceModelsPlayer({ selectedProcess, onClose }: IProps) {
  const { t } = useTranslation();
  // const [ranges, setRanges] = useState(new Map<string, number[]>());
  const { cardData, setCardModel } = useCardCreationContext();
  const { havePermissions } = useUserPermissionsContext();

  const modelId = cardData?.voiceModel?.id;

  const speakerSelectorOptions: SelectOption[] = useMemo(
    () =>
      (selectedProcess?.fileModelLinks || []).map((link, ind) => {
        const data = link.fileModel.data.files[0].data as VoiceModelFileData;
        const speechTimeSec = convertSecondsToTime(data.speechTimeSec);
        const label = ` ${t('speaker')} ${ind + 1} ${speechTimeSec ? `(${speechTimeSec})` : ''}`;

        return {
          id: link.fileModel.id.toString(),
          label: label,
        };
      }),
    [t, selectedProcess]
  );

  const renderOption = (
    option: ListItemModel & {
      isDeleted?: boolean;
    }
  ) => {
    const link = selectedProcess?.fileModelLinks?.find(
      (l) => l.fileModel.id.toString() === option.id
    );

    const icon = getModelIcon({
      quality: Number(link?.fileModel.quality) ?? null,
      type: 'audio',
    });

    return (
      <Box display={'flex'} flexWrap={'nowrap'} alignItems={'center'}>
        <span style={{ paddingRight: '7px' }}>{icon}</span>
        <span>{option.name}</span>
      </Box>
    );
  };

  const getSelectedOptionIcon = useCallback(
    (option: SelectOption | undefined) => {
      if (!option) return '';

      const link = selectedProcess?.fileModelLinks?.find(
        (l) => l.fileModel.id.toString() === option.id
      );

      return getModelIcon({ quality: Number(link?.fileModel.quality) ?? null, type: 'audio' });
    },
    [selectedProcess?.fileModelLinks]
  );

  const { form } = useForm<{ speaker: SelectFieldValue }>({
    defaultValues: {
      speaker: null,
    },
  });

  useEffect(() => {
    form.setValue(
      'speaker',
      speakerSelectorOptions.find((o) => o.id === modelId?.toString())?.id || null
    );
  }, [selectedProcess?.id, form, modelId, speakerSelectorOptions]);

  // TODO: when player will be able to handle different speakers, use this to fetch and store speaker ranges data
  // const handleSpeakerSelect = async (value: SelectFieldValue) => {
  //   if (!value || !selectedProcess) return;

  //   if (ranges.get(value)) return;

  //   const res = await filesApiService.getMediaData(value, selectedProcess.mediaFile.id);

  //   setRanges((prev) => {
  //     const updated = new Map(prev);

  //     if (res && Array.isArray(res.ranges)) {
  //       updated.set(value, res.ranges);
  //     }

  //     return updated;
  //   });
  // };

  return (
    <>
      <Separator horizontal />
      <Toolbar data-testid=''>
        <Form form={form} data-testid=''>
          <SelectField
            id='speaker'
            options={speakerSelectorOptions}
            data-testid=''
            isClearable={false}
            isActiveMark={false}
            getSelectedValuePrefix={getSelectedOptionIcon}
            renderOption={renderOption}
            className={styles.speakerSelectorWrapper}
          />
        </Form>

        {havePermissions(
          permissionControl.enrollment.cardCreation.modelsSection.setAsMainButton
        ) && (
          <>
            <Separator vertical />
            <Button
              type={ButtonType.Text}
              isDisabled={!form.watch().speaker || form.watch().speaker === modelId?.toString()}
              onClick={() => setCardModel(Number(form.watch().speaker))}
            >
              {t('multipass.setAsMainSpeaker')}
            </Button>
          </>
        )}

        <Void />

        <Button
          onClick={onClose}
          icon={icons.IconClose24}
          type={ButtonType.Icon}
          buttonStyle={ButtonStyle.Secondary}
          tooltip={t('close')}
        />
      </Toolbar>

      <WmsPlayer fileName={selectedProcess.file.path} />
    </>
  );
}
