import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { Toolbar } from 'stc-ui-kit';

export interface IRightPanelWrapperProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

export const RightPanelWrapper: FC<IRightPanelWrapperProps & PropsWithChildren> = (props) => {
  const { header, footer, children } = props;

  return (
    <Box display='flex' flexDirection='column' width={1} overflow='auto'>
      {header && <Toolbar data-testid=''>{header}</Toolbar>}
      <Box display='flex' flexDirection='column' flexGrow={1} padding={2} width={1} overflow='auto'>
        {children}
      </Box>
      {!!footer && footer}
    </Box>
  );
};
