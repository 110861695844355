import { ECardExportStatus } from '@/types/card';
import { en } from './en';
import { EFileProcessStatus } from '@/types/file';

export const ru: typeof en = {
  appCreatorInfo: '© ООО «ЦРТ». Все права защищены.',
  appInfo: 'Анализ биометрических множеств и иной информации',
  appName: 'Трал 4. Фактор',
  FileDialog: {
    addFile: 'Добавить файл',
    cancel: 'Отменить',
    deviceSection: 'Устройства',
    dontUseForPersonCreation: 'Не использовать для создания карточек',
    folderSection: 'Папки',
    prepareFilesForUploading: 'Подготовьте файлы для загрузки в аналитическую систему',
    title: 'Выбор файла',
  },
  add: 'Добавить',
  addUser: 'Добавить пользователя',
  admin: 'админ',
  administration: 'Администрирование',
  attachedFileStatus: {
    [EFileProcessStatus.NEW]: 'Новый',
    [EFileProcessStatus.INPROGRESS]: 'В процессе',
    [EFileProcessStatus.COMPLETED]: 'Добавлен',
    [EFileProcessStatus.FAILED]: 'Ошибка',
    [EFileProcessStatus.VERIFIED]: 'Обработан',
    [EFileProcessStatus.DUPLICATE]: 'Дубликат',
    [EFileProcessStatus.NOMODEL]: 'Нет модели',
    unknown: '',
  },
  attachedFiles: 'Прикрепленные файлы',
  auth: {
    appName: 'Фактор ®',
    confirmNewPassword: 'Повторите новый пароль',
    createNewPassword: 'Придумайте новый пароль',
    enter: 'Войти',
    enterNewPassword: 'Введите новый пароль',
    newPassword: 'Новый пароль',
    newPasswordConfirmation: 'Подтверждение нового пароля',
    title: 'Войти в Мультипаспорт',
    user: 'Пользователь',
    welcome: 'Добро пожаловать!',
    youDontHavePermissions: 'У вас недостаточно прав',
  },
  back: 'Назад',
  yes: 'Да',
  no: 'Нет',
  block: 'Заблокировать',
  blockUser: 'Блокировка пользователя',
  blockYourself: 'Блокировать свою учетную запись запрещено',
  cancel: 'Отменить',
  cardTemplate: {
    common: 'Мультипаспорт',
  },
  cards: {
    areYouShureDirDeleteOne: 'Вы действительно хотите удалить выбранную карточку?',
    areYouShureDirDeleteOneSeveral:
      'Вы действительно хотите удалить выбранные карточки ({{count}})?',
    biometricModels: 'Биометрические модели',
    changed: 'Дата и время изменения',
    changedBy: 'Кем изменена',
    clear: 'Очистить',
    closeCardTitle: 'Закрыть карточку',
    created: 'Дата и время создания',
    createdBy: 'Кем создана',
    createdCard: 'Карточка успешно создана',
    dateOfBirth: 'Дата рождения',
    deleteAddedInCardAudio: 'Вы действительно хотите удалить выбранный аудиофайл?',
    deleteAddedInCardAudios: 'Вы действительно хотите удалить выбранные аудиофайлы?',
    deleteAddedInCardPhoto: 'Вы действительно хотите удалить выбранное изображение?',
    deleteAddedInCardPhotos: 'Вы действительно хотите удалить выбранные изображения?',
    deleteAudioTitle: 'Удаление аудиофайла',
    deleteAudiosTitle: 'Удаление аудиофайлов',
    deleteCard: 'Удаление карточки',
    deleteDocumentConfirmation: 'Вы действительно хотите удалить выбранный документ?',
    deleteDocumentTitle: 'Удаление документа',
    deleteDocumentWithMainPhoto:
      'Вы действительно хотите удалить выбранный документ и созданную модель лица?',
    deleteDocumentsConfirmation: 'Вы действительно хотите удалить выбранные документы?',
    deleteDocumentsTitle: 'Удаление документов',
    deleteFileConfirmation: 'Вы действительно хотите удалить выбранный файл?',
    deleteFileTitle: 'Удаление файла',
    deleteFilesConfirmation: 'Вы действительно хотите удалить выбранные файлы?',
    deleteFilesTitle: 'Удаление файлов',
    deleteMainPhotoDesc:
      'Вы действительно хотите удалить выбранное изображение и созданную модель лица?',
    deletePhotoTitle: 'Удаление изображения',
    deletePhotosTitle: 'Удаление изображений',
    description: 'Комментарий',
    documentAvailabilityIndicator: 'Признак наличия документа',
    documents: 'Документы',
    export: 'Экспорт',
    exportGridStatus: 'Статус экспорта в GridID',
    exportStatus: {
      [ECardExportStatus.NEW]: 'Новая',
      [ECardExportStatus.NEED_EXPORT]: 'Создана',
      [ECardExportStatus.EXPORTED]: 'Экспортирована',
      unknown: '',
    },
    face: 'Лицо',
    faceModel: 'Модель лица',
    fullname: 'ФИО',
    gender: 'Пол',
    lastExported: 'Дата экспорта в GridID',
    models: 'Модели',
    name: 'Имя',
    noItemsAvailable: 'Нет доступных элементов',
    noModel: 'Без лицевой модели',
    noUploadedImages: 'Нет загруженных изображений',
    personId: 'Паспорт',
    search: 'Введите текст',
    source: 'Источник',
    statusCreated: 'Создана',
    statusNew: 'Новая',
    succesCreateCard: 'Карточка успешно создана',
    succesDeletedСard: 'Карточка успешно удалена',
    title: 'Картотека',
    type: 'Тип',
    unsavedChangesConfirmation:
      'В карточке есть несохраненные изменения. Вы действительно хотите закрыть карточку?',
    uploadImageTobuildFaceModel: 'Чтобы построить модель лица, загрузите изображение',
    voice: 'Голос',
  },
  changePassword: 'Сменить пароль',
  changePasswordTitle: 'Смена пароля',
  chooseRole: 'Выберите роль',
  close: 'Закрыть',
  collapse: 'Свернуть',
  commentary: 'Комментарий',
  copyCardId: 'Копировать ID карточки',
  copyId: 'Копировать ID',
  create: 'Создать',
  createRole: 'Создать роль',
  createUser: 'Создать пользователя',
  delete: 'Удалить',
  download: 'Скачать',
  edit: 'Редактировать',
  enrollment: 'Мультипаспорт',
  enterComment: 'Введите комментарий',
  enterFirstname: 'Введите имя',
  enterFullName: 'Введите ФИО',
  enterLastname: 'Введите фамилию',
  enterMiddlename: 'Введите отчество',
  enterNewPassword: 'Введите новый пароль',
  enterPassword: 'Введите пароль',
  enterUsername: 'Введите логин',
  error: 'Ошибка',
  errorOccurred: 'Произошла ошибка',
  exclude: 'Исключить',
  exit: 'Завершить',
  fieldError: {
    InvalidCount: 'Ошибка в количестве значений в поле',
    InvalidDataType: 'Ошибка в типе данных в поле',
    InvalidDateFormat: 'Ошибка в формате даты',
    InvalidDateTimeFormat: 'Ошибка в формате времени',
    InvalidValue: 'Введенное значение не соответствует формату',
    MissingRequired: 'Не заполнено обязательное поле',
    NotUniqueValue: 'Введенное значение не уникально',
    UnknownCustomType: 'Неизвестный тип поля',
    UnknownField: 'Неизвестное поле',
    error: 'Неверно заполнено поле "{{field}}": ',
  },
  fileInProgress: 'Файл в процессе обработки',
  fileIsNotFound: 'Файл не найден',
  fileWasPreviouslyUploaded: 'Файл был загружен в систему ранее с названием «{{name}}»',
  firstname: 'Имя',
  fullname: 'ФИО',
  gallery: 'Режим галереи',
  gridTable: {
    hideCol: 'Спрятать столбец',
    showCols: 'Настройки',
    sortAsc: 'Сортировать по возрастанию',
    sortDesc: 'Сортировать по убыванию',
    unsort: 'Сбросить сортировку',
  },
  group: 'Группа',
  groups: {
    addUser: 'Добавление пользователя',
    confirmDelete: 'Вы действительно хотите удалить группу «{{name}}» ?',
    confirmDeleteNote:
      'Данное действие невозможно отменить. После удаления группу нельзя будет назначить пользователю.',
    confirmExclude: 'Вы действительно хотите исключить из группы выбранного пользователя?',
    confirmExcludeNote:
      'Пользователя можно будет включить в группу с помощью кнопки «Добавить пользователя»',
    createGroup: 'Создание группы',
    deleteGroup: 'Удаление группы',
    editGroup: 'Редактирование группы',
    enterGroupName: 'Введите название группы',
    exclude: 'Исключить',
    excludeFromGroup: 'Исключить из группы',
    excludeUserFromGroup: 'Исключение пользователя из группы',
    groupName: 'Название группы',
    groups: 'Группы',
    selectUser: 'Выберите пользователя',
  },
  id: 'ID',
  imagesComparison: 'Сравнение фотографий',
  inProgress: 'В процессе обработки',
  include: 'Включить',
  invalidDate: 'Некорректная дата',
  isBanned: 'Заблокирован',
  language: {
    saveLanguageFailed: 'При сохранении языка произошла ошибка',
  },
  lastname: 'Фамилия',
  list: 'Отобразить списком',
  login: 'Логин',
  managingRoles: {
    areYouSureDeleteRole: 'Вы действительно хотите удалить роль',
    choosePrivileges: 'Выберите привилегии',
    collapseAll: 'Свернуть всё',
    deleteRole: 'Удаление роли',
    enterRoleName: 'Введите название роли',
    expandAll: 'Развернуть всё',
    managingPrivs: 'Управление привилегиями',
    privileges: 'Привилегии',
    roleCreation: 'Создание роли',
    roleEdit: 'Редактирование роли',
    roleName: 'Название роли',
    searchPlaceholder: 'Введите привилегию',
  },
  messages: {
    audioModelDeleted: 'Голосовая модель успешно удалена',
    audioModelEdit: 'Голосовая модель успешно перестроена',
    audioUploaded: 'Аудио загружено',
    authError: 'Ошибка авторизации:',
    cardFoundInHotlist: 'Карточка «{{name}}» найдена в хотлисте!',
    cardsExported: 'Экспортированы карточки: {{ids}}',
    errorDeleteRole: 'Ошибка при удалении роли',
    faceModelDeleted: 'Лицевая модель успешно удалена',
    faceModelEdit: 'Лицевая модель успешно перестроена',
    failedCopyID: 'Не удалось скопировать ID',
    failedToDeleteFile: 'Не удалось удалить файл',
    fileDeletedSuccessful: 'Файл {{fileName}} успешно удален',
    fileUploaded: 'Файл успешно загружен',
    idCopied: 'ID успешно скопирован',
    invalidFileFormat: 'Неверный формат файла',
    openCard: 'Открыть карточку',
    photoUploaded: 'Фото успешно загружено',
    playerIsNotResponding: 'Плеер не отвечает',
    roleCreatedSuccessful: 'Роль успешно создана',
    roleDeleted: 'Роль успешно удалена',
    roleUpdatedSuccessful: 'Роль успешно обновлена',
    serverIsNotResponding: 'Сервер не отвечает. Повторите запрос позже',
    userChangePasswordSuccessful: 'Пароль успешно изменен',
    userCreatedSuccessful: 'Пользователь успешно создан',
    userUpdatedSuccessful: 'Пользователь успешно обновлен',
    userWasAdded: 'Пользователь {{username}} добавлен в группу «{{groupName}}»',
    userWasExcluded: 'Пользователь {{username}} исключен из группы «{{groupName}}»',
    usersBlocked: 'Пользователи успешно заблокированы',
    usersUnblocked: 'Пользователи успешно разблокированы',
    usersWereAdded: 'Пользователи {{usernames}} добавлены в группу «{{groupName}}»',
    usersWereExcluded: 'Пользователи {{usernames}} исключены из группы «{{groupName}}»',
  },
  middlename: 'Отчество',
  multipass: {
    addFacialModel: 'Добавить лицевую модель',
    addInCard: 'Добавить в карточку',
    addedBy: 'Кем добавлено',
    additionDate: 'Дата добавления',
    allPeriod: 'Весь период',
    audioDuration: 'Длительность записи',
    autoMarkup: 'Авторазметка',
    biometrics: 'Биометрия',
    birthDate: 'Дата рождения',
    calendar: 'Календарь',
    cardErrorCreated: 'Карточка не создана',
    cardExported: 'Карточка успешно экспортирована',
    cardExportedWithErrors: 'Карточка экспортирована с ошибками',
    cardSuccessfullyCreated: 'Карточка успешно создана',
    cardSuccessfullyEdited: 'Карточка успешно отредактирована',
    cardSuccessfullyExported: 'Карточка успешно экспортирована',
    cardWasChangedAfterExport: 'Карточка изменена после экспорта',
    closePlayer: 'Скрыть расширенный плеер',
    comment: 'Комментарий',
    completed: 'Загружено',
    createEntry: 'Создать карточку',
    createNewEntry: 'Создать новую карточку',
    createdDateTime: 'Дата и время добавления',
    createdTime: 'Дата загрузки',
    deleteFileModal: {
      textBody: 'Вы действительно хотите удалить запись',
      textButtonConfirm: 'Удалить',
      title: 'Удаление записи',
    },
    desktop: 'Рабочий стол',
    dictors: 'Дикторы',
    editedDateTime: 'Дата и время изменения',
    entries: 'Картотека',
    entryCreated: 'Карточка успешно создана',
    errorCardNotExported: 'Ошибка экспорта. Не удалось экспортировать карточку',
    errorWhenLoadingCard: 'Произошла ошибка при загрузке карточки',
    errorWhenLoadingTemplates: 'Произошла ошибка при загрузке шаблонов карточки',
    excluded: 'Исключено',
    extension: 'Расширение',
    fileDetails: 'Детали файла',
    fileList: 'Список файлов',
    fileName: 'Имя файла',
    fileProcessNotFound: 'Обработанный файл не найден',
    fileProcessingError:
      'При обработке файла произошла ошибка. Загрузите файл заново или свяжитесь с администратором',
    foundedDictors: 'Найденные дикторы',
    gender: 'Пол',
    generalInfo: 'Основная информация',
    isInHotList: 'Есть в горячем списке',
    infoAudio: 'Информация об аудиофайле',
    infoPhoto: 'Информация о фото',
    interactiveCard: 'Интерактивная карточка',
    manualMarkup: 'Ручная разметка',
    missingDataText: 'Загрузите аудиозапись, чтобы начать работу',
    missingDataTitle: 'Аудиозаписи',
    modelNotFound: 'Модель не найдена',
    modelQuality: 'Качество модели',
    nameEdit: 'Кем изменён',
    nameLoad: 'Кем добавлен',
    newEntry: 'Новая карточка',
    newEntryFrom: 'Новая от',
    from: 'от',
    noTemplatesFound: 'Не найдены шаблоны для создания карточки',
    noUploadedAudio: 'Нет загруженных аудиозаписей',
    openFullSize: 'Открыть в полном размере',
    closeFullscreen: 'Выйти из полноэкранного просмотра',
    openInNewTab: 'Открыть в новой вкладке',
    openPlayer: 'Показать расширенный плеер',
    passport: 'Паспорт',
    personalData: 'Данные',
    pureSpeechDuration: 'Длительность чистой речи',
    recommendedData: 'Рекомендуемые характеристики',
    resource: 'Источник',
    samples: 'Обработка файлов',
    selectFileTitle: 'Выберите запись, чтобы увидеть информацию о спикерах',
    selectedMarkup: 'Выбранная разметка',
    setAsMain: 'Сделать основной',
    setAsMainSpeaker: 'Сделать основным',
    speechDuration: 'Длительность размеченной речи',
    status: 'Статус',
    toDisplayEntrySelectOneObject: 'Для отображения карточки выберите один объект',
    today: 'Сегодня',
    tooltipDuplicate: 'Дубликат',
    tooltipVerified: 'Завершено',
    totalDuration: 'Общая длительность',
    typeFile: 'Тип',
    uploadAudio: 'Чтобы построить модель голоса, загрузите аудиозапись',
    verifyFile: 'Завершить',
    yesterday: 'Вчера',
  },
  name: 'Имя',
  new: 'Новый',
  newPassword: 'Новый пароль',
  newPasswordConfirmation: 'Повторите новый пароль',
  password: 'Пароль',
  passwordConfirmation: 'Повторите пароль',
  player: {
    algorithm: 'Алгоритм',
    audioDuration: 'Длительность аудио',
    byteUnit: 'байт',
    clipFragmentsLength: 'Совокупная длительность клиппированных фрагментов',
    coe: 'СКО для значения реверберации',
    dBUnit: 'дБ',
    encodingType: 'Тип кодирования',
    fileSize: 'Размер файла',
    hzUnit: 'Гц',
    lowerBound: 'Нижняя частотная граница речевого сигнала',
    maxLengthNoClip: 'Максимальная длительность неклиппированных фрагментов',
    next: 'След.',
    noiseFragmentsLength: 'Совокупная длительность зашумленных фрагментов',
    prev: 'Пред.',
    quality: 'Качество',
    reverberation: 'Реверберация',
    reverberationMoments: 'Кол-во моментов реверберации',
    samplingRate: 'Частота дискретизации',
    segmentsLength: 'Длина выделенных сегментов',
    signalAndNoise: 'ОСШ',
    slowDown: 'Замедлить воспроизведение',
    speechDuration: 'Длительность речи',
    speedUp: 'Ускорить воспроизведение',
    upperBound: 'Верхняя частотная граница речевого сигнала',
  },
  privileges: {
    ADMINISTRATION: 'Администрирование',
    CREATE_CARDS: {
      actions: 'Просмотр карточек по группе пользователя; | Создание карточки',
      description: 'Возможные действия в разделе Мультипаспорт / Картотека:',
      title: 'Создание карточки',
    },
    CREATE_GROUPS: {
      actions: 'Просмотр списка групп и их участников; | Создание новой группы',
      description: 'Возможные действия в разделе Администрирование / Группы:',
      title: 'Создание группы',
    },
    CREATE_ROLES: {
      actions: 'Просмотр списка ролей и их привилегий; | Создание роли',
      description: 'Возможные действия в разделе Администрирование / Роли:',
      title: 'Создание роли',
    },
    CREATE_USER: {
      actions: 'Просмотр списка пользователей; | Создание нового пользователя',
      description: 'Возможные действия в разделе Администрирование / Пользователи:',
      title: 'Создание пользователя',
    },
    DELETE_CARDS: {
      actions: 'Просмотр карточек по группе пользователя; | Удаление карточки',
      description: 'Возможные действия в разделе Мультипаспорт / Картотека:',
      title: 'Удаление карточки',
    },
    DELETE_GROUPS: {
      actions: 'Просмотр списка групп и их участников; | Удаление группы',
      description: 'Возможные действия в разделе Администрирование / Группы:',
      title: 'Удаление группы',
    },
    DELETE_ROLES: {
      actions: 'Просмотр списка ролей и их привилегий; | Удаление роли',
      description: 'Возможные действия в разделе Администрирование / Роли:',
      title: 'Удаление роли',
    },
    EDIT_CARDS: {
      actions: 'Просмотр карточек по группе пользователя; | Редактирование карточки',
      description: 'Возможные действия в разделе Мультипаспорт / Картотека:',
      title: 'Редактирование карточки',
    },
    EDIT_GROUPS: {
      actions: 'Просмотр списка групп и их участников; | Редактирование группы',
      description: 'Возможные действия в разделе Администрирование / Группы:',
      title: 'Редактирование группы',
    },
    EDIT_ROLES: {
      actions:
        'Просмотр списка ролей и их привилегий; | Редактирование роли; | Управление привилегиями - включение привилегий в роль и исключение привилегий из роли',
      description: 'Возможные действия в разделе Администрирование / Роли:',
      title: 'Редактирование роли',
    },
    EDIT_USER: {
      actions:
        'Просмотр списка пользователей; | Редактирование пользователя; | Сброс пароля пользователя',
      description: 'Возможные действия в разделе Администрирование / Пользователи:',
      title: 'Редактирование пользователя',
    },
    ENROLLMENT: 'Картотека',
    EXPORT_CARDS: {
      actions: 'Просмотр карточек по группе пользователя; | Экспорт карточек',
      description: 'Возможные действия в разделе Мультипаспорт / Картотека:',
      title: 'Экспорт карточки',
    },
    EXPORT_CARD_FILES: {
      actions: 'Просмотр карточек по группе пользователя; | Экспорт файлов карточки',
      description: 'Возможные действия в разделе Мультипаспорт / Картотека:',
      title: 'Экспорт файлов карточки',
    },
    FILE_MANAGEMENT: 'Управление картотекой',
    LOCK_UNLOCK_USER: {
      actions:
        'Просмотр списка пользователей; | Блокировка пользователей; | Разблокировка пользователей',
      description: 'Возможные действия в разделе Администрирование / Пользователи:',
      title: 'Блокировка и разблокировка пользователя',
    },
    USER_ACCOUNT_MANAGEMENT: 'Управление учетными записями пользователей',
    USER_GROUP_AFFILIATION: {
      actions:
        'Просмотр списка групп и их участников; | Добавление пользователя в группу; | Удаление пользователя из группы',
      description: 'Возможные действия в разделе Администрирование / Группы:',
      title: 'Добавление пользователя в группу и исключение из группы',
    },
    USER_GROUP_MANAGEMENT: 'Управление группами пользователей',
    USER_ROLE_MANAGEMENT: 'Управление ролями пользователей',
    VIEW_ALL_CARDS: {
      actions: 'Просмотр всех карточек',
      description: 'Возможные действия в разделе Мультипаспорт / Картотека:',
      title: 'Просмотр всей картотеки',
    },
    VIEW_CARDS: {
      actions: 'Просмотр карточек по группе пользователя',
      description: 'Возможные действия в разделе Мультипаспорт / Картотека:',
      title: 'Просмотр картотеки',
    },
    VIEW_GROUPS: {
      actions: 'Просмотр списка групп и их участников',
      description: 'Возможные действия в разделе Администрирование / Группы:',
      title: 'Просмотр списка групп',
    },
    VIEW_ROLES: {
      actions: 'Просмотр списка ролей и их привилегий',
      description: 'Возможные действия в разделе Администрирование / Роли:',
      title: 'Просмотр списка ролей',
    },
    VIEW_USERS: {
      actions: 'Просмотр списка пользователей',
      description: 'Возможные действия в разделе Администрирование / Пользователи:',
      title: 'Просмотр списка пользователей',
    },
    reference: 'Справка',
  },
  refresh: 'Обновить',
  repeatNewPassword: 'Повторите новый пароль',
  repeatPassword: 'Повторите пароль',
  resume: 'Возобновить',
  role: 'Роль',
  roleManagement: 'Управление ролями',
  roles: 'роли',
  save: 'Сохранить',
  saveAndClose: 'Сохранить и закрыть',
  saveToStartWithBioscanner: 'Сохраните работу для начала работы с BioScanner',
  searchQuery: 'Поисковые запросы',
  selectGroup: 'Выберите группу',
  selected: 'Выбрано',
  sets: 'Множества',
  sideFilterSelectPlaceholder: 'Выберите значение',
  sideFilterStringPlaceholder: 'Введите значение',
  speaker: 'Диктор',
  thisActionCannotBeUndone: 'Данное действие невозможно отменить',
  tile: 'Отобразить плиткой',
  unblock: 'Разблокировать',
  unblockUser: 'Разблокировать пользователя',
  upload: 'Загрузить',
  userCreation: 'Создание пользователя',
  userEdit: 'Редактирование пользователя',
  username: 'Имя пользователя',
  users: 'Пользователи',
  validation: {
    invalidFormat: 'Некорректный формат',
    maxCount: 'Макс. кол-во значений в поле {{count}}',
    maxLength: 'Макс. кол-во символов: {{count}}',
    minLength: 'Мин. кол-во символов: {{count}}',
    passwordsNotMatch: 'Пароли не совпадают',
    required: 'Обязательное поле',
    requiredOptions: 'Выберите одно из значений',
    shouldBeEmail: 'Введен некорректный адрес эл.почты',
  },
  youSureWantBlockUsers: 'Вы действительно хотите заблокировать пользователя?',
  youSureWantUnblockUsers: 'Вы действительно хотите разблокировать пользователя?',
};
