import { getServerMediaFilePath } from '@/services/root.api.service';
import { getFileFromServer } from '@/utils/files/getFileFromServer';
import { Avatar } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface Props {
  src: string;
}

export const CardAvatar: React.FC<Props> = ({ src }) => {
  const [loadedImage, setLoadedImage] = useState('');

  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        if (!src) {
          setLoadedImage('');

          return;
        }

        const resp = await getFileFromServer(getServerMediaFilePath(src));

        if (!resp) {
          setLoadedImage('');

          return;
        }

        setLoadedImage(URL.createObjectURL(resp.blob));
      } catch (error) {
        console.log(error);
      }
    };

    void fetchAvatar();
  }, [src]);

  return <Avatar src={loadedImage} sx={{ width: 35, height: 35 }} />;
};
