import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

import styles from './Modal.module.scss';

import { Modal as MuiModal } from '@mui/material';
import { Button, ButtonStyle } from 'stc-ui-kit';
import { useTranslation } from 'react-i18next';

interface Props {
  children: React.ReactElement;
  open: boolean;
  onClose: () => void;
  onConfirmClick?: () => void | Promise<void>;
  confirmDisabled?: boolean;
  title: string;
  customConfirmButton?: React.ReactNode;
  compact?: boolean;
}

export const Modal: React.FC<Props> = ({
  children,
  onClose,
  open,
  onConfirmClick,
  title,
  confirmDisabled,
  customConfirmButton,
  compact,
}) => {
  const { t } = useTranslation();

  const wrapperClassName = compact ? `${styles.wrapper} ${styles.compact}` : styles.wrapper;

  return (
    <MuiModal open={open} onClose={onClose}>
      <div className={wrapperClassName}>
        <div className={styles.header}>
          <span>{title}</span>
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={styles.main}>{children}</div>
        <div className={styles.footer}>
          <div className={styles.buttons}>
            {customConfirmButton || (
              <Button onClick={onConfirmClick} isDisabled={confirmDisabled}>
                {t('save')}
              </Button>
            )}
            <Button onClick={onClose} buttonStyle={ButtonStyle.Secondary}>
              {t('cancel')}
            </Button>
          </div>
        </div>
      </div>
    </MuiModal>
  );
};
