import { IFileLink, ITableItem } from '@/types/file';
import { convertSecondsToTime } from '@/utils/convertSecondsToTime';
import { useTranslation } from 'react-i18next';
import { EmptyPlaceholder, Icon, icons, TableColumn, TableRow, UiKitColor } from 'stc-ui-kit';
import { useCardCreationContext } from '../../../hooks/useCardCreationContext';
import { useDataStore } from '@/Providers/StoreProvider';
import { getModelIcon } from '@/modules/multipass/utils/getModelIcon';
import { PropsWithChildren, useEffect } from 'react';
import { ReactComponent as NoImageSvg } from '@/assets/icons/noAudioPlaceholder.svg';
import { StatusWithIcon } from '@/modules/multipass/StatusWithIcon';
import { formatDateTimeString } from '@/utils/formatDateTimeString';
import { getFileNameFromPath } from '@/utils/getFileNameFromPath';
import { StcGridTable } from '@/components/Grid/StcGridTable';
import { useGridStore } from '@/hooks/grid/useGridStore';
import { VoiceModelFileData } from '@/types/model';

const CenteredCell = ({ children }: PropsWithChildren) => (
  <span style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{children}</span>
);

export function VoiceModelsTable({
  onRowOpen,
}: {
  onRowOpen: (item: TableRow<ITableItem>) => void;
}) {
  const { fileLinks, cardData } = useCardCreationContext();
  const { uiStore } = useDataStore();
  const { t } = useTranslation();

  const cardId = cardData?.id || 0;
  const openedCard = cardData ? uiStore.getOpenedCard(cardData.id) : null;
  const selectedEntitiesIds =
    openedCard?.selectedEntities.get('voice')?.map((id) => id.toString()) || [];

  const { store } = useGridStore<IFileLink>({
    dataProvider: () => new Promise((res) => res({ data: fileLinks })),
  });

  useEffect(() => {
    store.setData({ data: fileLinks });
  }, [fileLinks, store]);

  const handleSelect = (itemsIds: string[]) => {
    uiStore.setSelectedEntities(
      cardId,
      'voice',
      itemsIds.map((id) => Number(id))
    );
  };

  const findModelSpeaker = (row: ITableItem) => {
    return row.fileModelLinks?.find((l) => l.fileModel.id === cardData?.voiceModel?.id);
  };

  const columns: Array<TableColumn<IFileLink>> = [
    {
      id: 'quality',
      title: t('multipass.modelQuality'),
      isVisible: true,
      width: 140,
      renderRowCell: (row) => {
        const modelSpeaker = findModelSpeaker(row);
        if (!modelSpeaker) return '';

        return (
          <CenteredCell>
            {getModelIcon({ quality: modelSpeaker?.fileModel.quality ?? null, type: 'audio' })}
          </CenteredCell>
        );
      },
    },
    {
      id: 'totalDuration',
      title: t('multipass.totalDuration'),
      isVisible: true,
      width: 130,
      renderRowCell: (row) => {
        const duration = row.fileProcessMetadata?.metadataItems[0].data.durationSec;

        return duration ? convertSecondsToTime(duration) : '';
      },
    },
    {
      id: 'speechDuration',
      title: t('multipass.speechDuration'),
      isVisible: true,
      width: 140,
      renderRowCell: (row) => {
        const modelSpeaker = findModelSpeaker(row);
        if (!modelSpeaker) return '';

        const data = modelSpeaker.fileModel.data.files[0].data as VoiceModelFileData;

        return convertSecondsToTime(data.speechTimeSec);
      },
    },
    {
      id: 'selectedMarkup',
      title: t('multipass.selectedMarkup'),
      isVisible: true,
      width: 160,
      renderRowCell: (row) => {
        const voiceModelIndex = (row.fileModelLinks || []).findIndex(
          (l) => l.fileModel.id === cardData?.voiceModel?.id
        );
        if (voiceModelIndex < 0) return '';

        return `${t('speaker')} ${voiceModelIndex + 1}`;
      },
    },
    {
      id: 'autoMarkup',
      title: t('multipass.autoMarkup'),
      isVisible: true,
      width: 120,
      renderRowCell: (row) => {
        const modelSpeaker = findModelSpeaker(row);
        if (!modelSpeaker) return '';

        if ('modelSource' in modelSpeaker) {
          return modelSpeaker.modelSource ? (
            ''
          ) : (
            <CenteredCell>
              <Icon iconColor={UiKitColor.Green800} icon={icons.IconCheckmark} />
            </CenteredCell>
          );
        }
      },
    },
    {
      id: 'manualMarkup',
      title: t('multipass.manualMarkup'),
      isVisible: true,
      width: 140,
      renderRowCell: (row) => {
        const modelSpeaker = findModelSpeaker(row);
        if (!modelSpeaker) return '';

        if ('modelSource' in modelSpeaker) {
          return modelSpeaker.modelSource ? (
            <CenteredCell>
              <Icon iconColor={UiKitColor.Green800} icon={icons.IconCheckmark} />
            </CenteredCell>
          ) : (
            ''
          );
        }
      },
    },
    {
      id: 'status',
      title: t('multipass.status'),
      isVisible: true,
      width: 130,
      renderRowCell: (row) => <StatusWithIcon status={row.status} />,
    },
    {
      id: 'creator',
      title: t('multipass.nameLoad'),
      isVisible: true,
      width: 120,
      renderRowCell: (row) => row.creator.username,
    },
    {
      id: 'createdTime',
      title: t('multipass.createdDateTime'),
      isVisible: true,
      width: 190,
      renderRowCell: (row) => formatDateTimeString(row.createdTime),
    },
    {
      id: 'editor',
      title: t('multipass.nameEdit'),
      isVisible: true,
      width: 120,
      renderRowCell: (row) => row.editor?.username,
    },
    {
      id: 'editedTime',
      title: t('multipass.editedDateTime'),
      isVisible: true,
      width: 190,
      renderRowCell: (row) => formatDateTimeString(row.editedTime),
    },
    {
      id: 'fileName',
      title: t('multipass.fileName'),
      isVisible: true,
      width: 160,
      renderRowCell: (row) => getFileNameFromPath(row.path),
    },
  ];

  return (
    <StcGridTable
      store={store}
      columns={columns}
      tableProps={{
        onRowDoubleClick: onRowOpen,
        onSelect: handleSelect,
        renderEmptyPlaceholder: () => (
          <EmptyPlaceholder
            title={t('multipass.noUploadedAudio')}
            label={t('multipass.uploadAudio')}
            icon={() => <NoImageSvg />}
          />
        ),
        isBorderDisabled: true,
        selectedItemsIds: selectedEntitiesIds,
      }}
    />
  );
}
