import { MediaDevicesStore } from '@/stores/WebRTC/MediaDevicesStore';
import { Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select/Select';
import { observer } from 'mobx-react';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './MediaSelect.module.scss';
interface MediaSelectProps {
  mediaType: 'video' | 'audio'; // MediaStreamConstraints boolean keys
  deviceCustomLabel?: string;
  selectedDeviceId?: string;
  selectMediaDeviceId: (id: string) => void;
  mediaDevicesStore: MediaDevicesStore;
  selectIcon?: ReactNode;
}

export const MediaSelect = observer((props: MediaSelectProps) => {
  const { mediaDevicesStore, selectMediaDeviceId, selectedDeviceId, mediaType, selectIcon } = props;
  const [devices, setDevices] = useState<MediaDeviceInfo[] | null>(null);
  const selectedDevice = useMemo(
    () => devices?.find((d) => d.deviceId === selectedDeviceId),
    [devices, selectedDeviceId]
  );
  const { isInited, allDevicesByType } = mediaDevicesStore;
  useEffect(() => {
    if (isInited) {
      allDevicesByType && setDevices(allDevicesByType[mediaType]);
    }
  }, [allDevicesByType, isInited, mediaType]);

  const onSelect = useCallback(
    (event: SelectChangeEvent<string>) => {
      selectMediaDeviceId(event.target.value);
    },
    [selectMediaDeviceId]
  );

  return (
    <Tooltip placement='top' title={selectedDevice?.label}>
      <Select
        variant='standard'
        placeholder='selectDevice'
        startAdornment={selectIcon}
        value={devices?.length && selectedDeviceId ? selectedDeviceId : 'ns'}
        onChange={onSelect}
        fullWidth
        inputProps={{ className: styles.selectInputComponent }}
      >
        <MenuItem selected={!selectedDeviceId || !devices?.length} disabled value='ns'>
          Не выбрано
        </MenuItem>
        {devices?.map((device) => (
          <MenuItem
            selected={selectedDeviceId === device.deviceId}
            key={device.deviceId}
            value={device.deviceId}
          >
            {device.label}
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  );
});
