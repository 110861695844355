import { NotificationType, showNotification } from 'stc-ui-kit';
import { NotificationParams } from './types';

export const showErrorNotification = ({ header, message }: NotificationParams) => {
  showNotification({
    dataTestId: '',
    header: header || '',
    message: message || '',
    type: NotificationType.Error,
  });
};
