import { useSnackbar } from 'notistack';

import { ApiError } from '@/utils/ApiError';
import { useCallback } from 'react';

export function useSnackBarMessage() {
  const { enqueueSnackbar } = useSnackbar();

  const apiError = useCallback(
    (message: string, error: ApiError) => {
      const errorMessage = [message];

      if (error.httpCode) {
        errorMessage.push(`status code: ${error.httpCode}`);
      }

      if (error.errorType?.code) {
        errorMessage.push(`code: '${error.errorType.code}'`);
      }
      if (error.errorType?.description) {
        errorMessage.push(`description: '${error.errorType.description}'`);
      }

      enqueueSnackbar(errorMessage.join(' '), { variant: 'error' });
    },
    [enqueueSnackbar]
  );

  const error = useCallback(
    (message: string) => {
      enqueueSnackbar(message, {
        variant: 'error',
        transitionDuration: { enter: 300, exit: 1000 },
      });
    },
    [enqueueSnackbar]
  );

  const warning = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'warning' });
    },
    [enqueueSnackbar]
  );

  const info = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'info' });
    },
    [enqueueSnackbar]
  );

  const success = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'success' });
    },
    [enqueueSnackbar]
  );

  return { apiError, error, warning, info, success };
}
