import { useGridStore } from '@/hooks/grid/useGridStore';
import { cardsService } from '@/services/cards.service';
import { ICardsGridItem } from '@/types/card';
import { EDataGridStoreSortMode } from '@/types/grid';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { CardsGrid } from './components/CardsGrid/CardsGrid';
import { HeaderFilingCabinet } from './components/HeaderFilingCabinet';
import { Box } from '@mui/material';
import { Toolbar } from 'stc-ui-kit';
import { CardQuickView } from '../CardCreation/components/CardQuickView/CardQuickView';
import { useDataStore } from '@/Providers/StoreProvider';
import { SideFilter } from './components/SideFilter';
import { useSelectionModel } from '@/hooks/grid/useSelectionModel';
import { useLocation } from 'react-router-dom';

export const FilingCabinet = observer(() => {
  const { templatesStore, uiStore } = useDataStore();
  const { templatesSystemFields, sideFilterStore } = templatesStore;
  const { columnFilterMap } = sideFilterStore;
  const location = useLocation();
  const { filingCabinetFilters } = uiStore;
  const { searchString, quickFilters } = filingCabinetFilters;

  const { store } = useGridStore<ICardsGridItem>({
    dataProvider: (state) => cardsService.dataProvider(state),
    defaultSortState: {
      sort: EDataGridStoreSortMode.DSC,
      sortField: 'createdOnDateTime',
    },
    withInfiniteScroll: true,
    initialState: {
      limit: 50,
      columnFilterMap: columnFilterMap,
    },
    reloadOnInit: false,
  });

  useEffect(() => {
    store.applyFilters(columnFilterMap, searchString, quickFilters);
  }, [store, columnFilterMap, searchString, quickFilters]);

  const { selectMany } = useSelectionModel(store);

  useEffect(() => {
    const selectIfNotSelected = () => {
      if (store.data[0] && !store.checkedItems.length) {
        const redirectedFromId = location.state?.redirectedFromId;
        const index = store.data.findIndex((card) => card.id === redirectedFromId);

        selectMany([store.data[Math.max(index, 0)]]);
      }
    };

    selectIfNotSelected();
  }, [selectMany, store.checkedItems.length, store.data, location.state?.redirectedFromId]);

  return (
    <Box display='flex' flexDirection='column' width={1} overflow='auto'>
      <Toolbar data-testid=''>
        <HeaderFilingCabinet store={store} />
      </Toolbar>

      <Box display='flex' flexDirection='row' flexGrow={1} width={1} overflow='auto'>
        <CardQuickView store={store} />

        <Box display='flex' flexDirection='column' width={1} overflow='auto' p={2}>
          <CardsGrid store={store} systemFields={templatesSystemFields} />
        </Box>

        {sideFilterStore.isSideFilterActive && <SideFilter />}
      </Box>
    </Box>
  );
});
