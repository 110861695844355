import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header, icons, Tab } from 'stc-ui-kit';
import { ROUTES } from '@/types';
import { useDataStore } from '@/Providers/StoreProvider';
import userService from '@/services/user.service';
import { filesStore } from '@/modules/multipass/stores/Files';
import AboutModal from '@/components/AboutModal';
import { observer } from 'mobx-react';
import { QrCodeButton } from './QrCodeButton';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';

export const TopMenu: FC = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userStore } = useDataStore();
  const { clear } = filesStore;
  const { userData, logoutAndClearUserData } = userStore;
  const { checkIsPageAllowed } = useUserPermissionsContext();

  const menuTabs: (Tab & { route: string })[] = useMemo(
    () => [
      {
        id: 'enrollment',
        title: t('enrollment'),
        path: [
          ROUTES.MULTIPASS_FILLING_CABINET,
          ROUTES.MULTIPASS_LOAD_FILE,
          ROUTES.MULTIPASS_INTERACTIVE_CARD,
          ROUTES.MULTIPASS_CARD_CREATION,
          ROUTES.MULTIPASS_CARD_EDIT,
        ].find((r) => checkIsPageAllowed(r)),
        route: ROUTES.MULTIPASS,
      },
      {
        id: 'administration',
        title: t('administration'),
        path: [
          ROUTES.ADMINISTRATION_USERS,
          ROUTES.ADMINISTRATION_GROUPS,
          ROUTES.ADMINISTRATION_ROLES,
        ].find((r) => checkIsPageAllowed(r)),
        route: ROUTES.ADMINISTRATION,
      },
    ],
    [checkIsPageAllowed, t]
  );

  const activeTabId =
    menuTabs.filter((tab) => pathname.includes(tab.route))[0]?.id ?? menuTabs[0].id;

  const handleLogout = useCallback(async () => {
    await userService.logOut();
    logoutAndClearUserData();
    clear();
  }, [clear, logoutAndClearUserData]);

  const displayedTabs = useMemo(
    () => menuTabs.filter((tab: Tab) => tab.path && checkIsPageAllowed(tab.path)),
    [checkIsPageAllowed, menuTabs]
  );

  const handleChangeActiveTabId = useCallback(
    (tabId: string) => {
      const navigateTo = displayedTabs.filter((tab) => tab.id === tabId)[0]?.path;
      navigateTo && navigate(navigateTo);
    },
    [displayedTabs, navigate]
  );

  return (
    <Header
      title={t('appName')}
      iconLogo={icons.IconLogoLight}
      tabs={displayedTabs}
      activeTabId={activeTabId}
      onChangeActiveTabId={handleChangeActiveTabId}
      userLogin={userData.username}
      onLogout={handleLogout}
      infoContent={<AboutModal />}
      isFullscreenEnabled={false}
      headerContent={<QrCodeButton />}
    />
  );
});
