import { appColors } from '@/Providers/ThemeProvider/colors';
import { RightPanelWrapper } from '@/components/RightPanelWrapper';
import { cardsService } from '@/services/cards.service';
import { ROUTES, APIResponseType } from '@/types';
import { CardFormData, ICard } from '@/types/card';
import { CardTemplate } from '@/types/cardTemplate';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { cardCreationFromQueryName, createCardEditLink } from '../utils/cardCreationLinks';
import { CardCreationMain } from './components/CardCreationMain';
import { CardCreationFooter } from './components/CardCreationFooter';
import { CardCreationHeader } from './components/CardCreationHeader';
import { CardCreationContextProvider } from './context/CardCreationContext';
import { Form, FormReturn, useForm } from 'stc-ui-kit';
import { makeStyles } from '@/hooks/makeStyles';
import { useDataStore } from '@/Providers/StoreProvider';
import { observer } from 'mobx-react';
import { useUnsavedChangesModal } from './hooks/useUnsavedChangesModal';
import { FieldValues } from 'react-hook-form';
import { formatTemplateValues } from './utils/formatTemplateValues';
import { useCardSseMessage } from './hooks/useCardSseMessage';
import { trimStringFormValues } from '@/utils/trimStringFormValues';
import { useCardFieldErrors } from './hooks/useCardFieldErrors';

export const CardCreation = () => {
  const params = useParams();

  return <CardCreationComponent key={params.id} />;
};

const useStyles = makeStyles()(() => ({
  form: {
    height: '100%',
    overflow: 'auto',
    width: '100%',
  },
}));

const CardCreationComponent = observer(() => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { uiStore, templatesStore } = useDataStore();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const dataFromQuery = useMemo(() => {
    const modelIdParam = searchParams.get('modelId');
    const modelId = modelIdParam !== null ? Number(modelIdParam) : null;

    const fileProcessIdParam = searchParams.get('fileProcessId');
    const fileProcessId = fileProcessIdParam !== null ? Number(fileProcessIdParam) : null;

    return {
      modelId,
      fileProcessId,
      fileUrl: searchParams.get('fileUrl') || '',
      from: searchParams.get(cardCreationFromQueryName) || ROUTES.MULTIPASS_FILLING_CABINET,
    };
  }, [searchParams]);

  const cardId: number | null = params.id !== undefined ? Number(params.id) : null;
  const isCreatingNewCard = !cardId;

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState<null | CardTemplate>(null);
  const [cardData, setCardData] = useState<ICard | null>(null);

  const { showFieldErrorNotification } = useCardFieldErrors({ template: selectedTemplate });

  useEffect(() => {
    if (cardId && !uiStore.getOpenedCard(cardId)) {
      uiStore.initOpenedCard(cardId);
    }
  }, [cardId, uiStore]);

  // это нужно для того, чтобы в картотеке выбиралась текущая карточка,
  // если возвращаться на предыдущую страницу с помощью браузерной навигации
  useEffect(() => {
    return () => {
      if (window.location.pathname === dataFromQuery.from) {
        navigate(dataFromQuery.from, { state: { redirectedFromId: cardId }, replace: true });
      }
    };
  }, [location, dataFromQuery.from, navigate, cardId]);

  // Временное решение, пока нет функционала для открытых карточек в боковой панели
  useEffect(() => {
    return () => {
      if (!isCreatingNewCard) {
        uiStore.closeCard(cardId);
      }
    };
  }, [cardId, isCreatingNewCard, uiStore]);

  const { form } = useForm({
    defaultValues: async () => {
      try {
        setIsLoading(true);

        let template: CardTemplate | null;
        let cardData: CardFormData | null;

        if (isCreatingNewCard) {
          if (dataFromQuery.modelId === null) {
            setError(t('multipass.modelNotFound'));

            return;
          }
          template = await templatesStore.getDefaultTemplate();
          cardData = null;
        } else {
          const { res: card } = await cardsService.getById(cardId);
          if (!card) {
            setError(t('multipass.errorWhenLoadingCard'));

            return;
          }

          setCardData(card);

          template = await templatesStore.getTemplateById(card.entryTemplateId);
          cardData = card.data;
        }

        if (!template) {
          setError(t('multipass.errorWhenLoadingTemplates'));

          return;
        }

        setError('');
        setSelectedTemplate(template);

        return formatTemplateValues(template.data, cardData);
      } catch (error) {
        setError(t('multipass.errorWhenLoadingCard'));
      } finally {
        setIsLoading(false);
      }
    },
  });

  const {
    formState: { errors, isDirty, isSubmitting },
    reset,
  } = form;

  useCardSseMessage({ cardData, setCardData, reset });

  const refetchCardData = useCallback(
    async (keepDirty = false) => {
      if (!cardId) return;

      const { res } = await cardsService.getById(cardId);
      if (res) {
        setCardData(res);

        if (!keepDirty) {
          reset(res.data);
        }
      }
    },
    [cardId, reset]
  );

  const onSubmit = useCallback(
    async (values: CardFormData) => {
      try {
        const modelId = dataFromQuery.modelId;
        const fileProcessId = dataFromQuery.fileProcessId;

        if (!selectedTemplate) return { error: t('multipass.noTemplatesFound') };

        let cardResponse: null | APIResponseType<ICard> = null;

        trimStringFormValues(values);

        if (isCreatingNewCard) {
          if (modelId === null) return { error: t('multipass.modelNotFound') };
          if (fileProcessId === null) return { error: t('multipass.fileProcessNotFound') };

          const res = await cardsService.createCard({
            entryTemplateId: selectedTemplate.id,
            modelId,
            data: values,
            fileProcessId,
          });

          cardResponse = res;
        } else {
          const res = await cardsService.editCard({
            id: cardId,
            entity: {
              entryTemplateId: selectedTemplate.id,
              data: values,
            },
          });

          cardResponse = res;
        }

        if (!cardResponse.res) {
          if (cardResponse.errorDescription) {
            showFieldErrorNotification(cardResponse.errorDescription);
          }

          return { error: cardResponse.errorDescription };
        }

        setCardData(cardResponse.res);

        reset(formatTemplateValues(selectedTemplate.data, cardResponse.res.data));

        return { card: cardResponse.res };
      } catch (error) {
        console.log(error);

        return { error };
      }
    },
    [
      cardId,
      dataFromQuery.fileProcessId,
      dataFromQuery.modelId,
      isCreatingNewCard,
      reset,
      selectedTemplate,
      t,
      showFieldErrorNotification,
    ]
  );

  const onExit = useCallback(() => {
    navigate(dataFromQuery.from, { state: { redirectedFromId: cardId } });
    if (!isCreatingNewCard) {
      uiStore.closeCard(cardId);
    }
  }, [cardId, dataFromQuery.from, isCreatingNewCard, navigate, uiStore]);

  const onSave = useCallback(async () => {
    const values = form.getValues();
    const { error } = await onSubmit(values);

    return error;
  }, [form, onSubmit]);

  const saveAndExit = useCallback(async () => {
    const error = await onSave();
    if (error) return;

    onExit();
  }, [onExit, onSave]);

  const isSubmitDisabled = isSubmitting || !!error || !isDirty || Object.keys(errors).length > 0;

  useUnsavedChangesModal({ when: isDirty, onSave });

  const pageContent = () => {
    if (isLoading) {
      return (
        <Box flex='1' display='flex' alignItems='center' justifyContent='center'>
          <CircularProgress sx={{ color: appColors.bgPrimary }} />
        </Box>
      );
    }

    if (error || !selectedTemplate) {
      return (
        <Typography fontWeight='700' margin='16px' color={appColors.red}>
          {error}
        </Typography>
      );
    }

    return (
      <Form form={form} data-testid='' className={classes.form}>
        <Box display='flex' overflow='hidden' height={'100%'}>
          <CardCreationMain />
        </Box>
      </Form>
    );
  };

  return (
    <CardCreationContextProvider
      formHooks={form as FormReturn<FieldValues>}
      selectedTemplate={selectedTemplate}
      cardData={cardData}
      refetchCardData={refetchCardData}
    >
      <RightPanelWrapper
        header={<CardCreationHeader />}
        footer={
          <CardCreationFooter
            buttonsProps={{
              exit: { onClick: onExit },
              save: {
                onClick: form.handleSubmit(async (values) => {
                  const { card } = await onSubmit(values);

                  if (!card?.id) return;

                  if (isCreatingNewCard) {
                    navigate(
                      createCardEditLink({
                        id: card.id,
                        from: dataFromQuery.from,
                      })
                    );
                  }
                }),
                isDisabled: isSubmitDisabled,
              },
              saveAndExit: {
                onClick: saveAndExit,
                isDisabled: isSubmitDisabled,
              },
            }}
          />
        }
      >
        {pageContent()}
      </RightPanelWrapper>
    </CardCreationContextProvider>
  );
});
