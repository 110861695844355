import userService from '@/services/user.service';
import { IGroup } from '@/types/group';
import { difference } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Form, Modal, MultiSelectField, MultiSelectFieldValue, useForm } from 'stc-ui-kit';
import { SelectOption } from 'stc-ui-kit/types/common';
import { useGroupsPageContext } from './useGroupsPageContext';
import { useCallback, useEffect, useState } from 'react';
import { User } from '@/types/user';
import groupService from '@/services/group.service';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';
import { showInfoNotification } from '@/utils/notifications';

interface IProps {
  onClose: () => void;
  currentValue: string[];
}

export function AddUserModal({ onClose, currentValue }: IProps) {
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const { t } = useTranslation();
  const { havePermissions } = useUserPermissionsContext();

  const { form: userSelectForm } = useForm<{ users: MultiSelectFieldValue }>({
    defaultValues: {
      users: currentValue,
    },
  });

  const { groups, selectedGroupsIds, fetchGroupUsers } = useGroupsPageContext();

  const selectedGroup: IGroup | undefined = groups.find(
    (g: IGroup) => g.id.toString() === selectedGroupsIds[0]
  );

  useEffect(() => {
    const fetchAllUsers = async () => {
      const data = await userService.getAllUsers();
      setAllUsers(data.res || []);
    };

    void fetchAllUsers();
  }, []);

  const handleConfirm = useCallback(async () => {
    if (!selectedGroup) return;

    const selectedUsersIds = userSelectForm.watch().users || [];

    const addedUsersIds = difference(selectedUsersIds, currentValue).map((id) => Number(id));
    const excludedUsersIds = difference(currentValue, selectedUsersIds).map((id) => Number(id));

    try {
      if (addedUsersIds.length) {
        await groupService.addOrExcludeUserfromGroup(addedUsersIds, selectedGroup);

        showInfoNotification({
          header:
            addedUsersIds.length === 1
              ? t('messages.userWasAdded', {
                  username: allUsers.find((u) => u.id === addedUsersIds[0])?.username,
                  groupName: selectedGroup.groupName,
                })
              : t('messages.usersWereAdded', {
                  usernames: allUsers
                    .filter((u) => addedUsersIds.includes(u.id))
                    .map((u) => u.username)
                    .join(', '),
                  groupName: selectedGroup.groupName,
                }),
        });
      }

      if (excludedUsersIds.length) {
        await groupService.addOrExcludeUserfromGroup(excludedUsersIds, null);

        showInfoNotification({
          header:
            excludedUsersIds.length === 1
              ? t('messages.userWasExcluded', {
                  username: allUsers.find((u) => u.id === excludedUsersIds[0])?.username,
                  groupName: selectedGroup.groupName,
                })
              : t('messages.usersWereExcluded', {
                  usernames: allUsers
                    .filter((u) => excludedUsersIds.includes(u.id))
                    .map((u) => u.username)
                    .join(', '),
                  groupName: selectedGroup.groupName,
                }),
        });
      }

      await fetchGroupUsers();
    } catch (error) {
      console.error(error);
    } finally {
      onClose();
    }
  }, [allUsers, currentValue, fetchGroupUsers, onClose, selectedGroup, userSelectForm, t]);

  const areSameValues = () => {
    const selectedUsersIds = userSelectForm.watch().users || [];

    return (
      selectedUsersIds.length === currentValue.length &&
      !difference(selectedUsersIds, currentValue).length
    );
  };

  return (
    <Modal
      onClose={onClose}
      onConfirm={handleConfirm}
      data-testid=''
      className='addUserToGroupModal'
      title={t('groups.addUser')}
      buttonConfirmText={t('add')}
      buttonCloseText={t('cancel')}
      isConfirmDisabled={areSameValues()}
      isCloseButtonHidden={
        !havePermissions(permissionControl.administration.groups.addUserConfirmButton)
      }
    >
      <Form form={userSelectForm} data-testid=''>
        <MultiSelectField
          placeholder={t('groups.selectUser')}
          isSearchable={false}
          id='users'
          options={(allUsers || [])
            .map(
              (u): SelectOption => ({
                id: u.id.toString(),
                label: u.username,
              })
            )
            .sort((a, b) => a.label.localeCompare(b.label))}
          data-testid=''
        />
      </Form>
    </Modal>
  );
}
