import { styled } from '@mui/material';
import { SVGAttributes } from 'react';

import { appColors } from '@/Providers/ThemeProvider/colors';

const BASE_SIZE = 18;

type Size = {
  w: number;
  h: number;
};

interface IStyledIcon {
  color: string | undefined;
  rectColor: string | undefined;
}

const StyledIconWrapper = styled('div')(({ color, rectColor }: IStyledIcon) => {
  return {
    '& path': {
      fill: appColors[color as keyof typeof appColors] || color,
    },
    '& rect ': {
      fill: appColors[rectColor as keyof typeof appColors] || rectColor,
    },
  };
});

export const SvgIcon: React.FC<{
  IconComponent: React.FC<SVGAttributes<SVGElement>>;
  size?: Size;
  color?: string;
  rectColor?: string;
}> = ({ size = { w: BASE_SIZE, h: BASE_SIZE }, color, IconComponent, rectColor }) => {
  const { w, h } = size;

  return (
    <StyledIconWrapper color={color} rectColor={rectColor} sx={{ display: 'flex' }}>
      <IconComponent width={w} height={h} />
    </StyledIconWrapper>
  );
};
