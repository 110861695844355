import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { Form, SearchField, useForm } from 'stc-ui-kit';
import { useDataStore } from '@/Providers/StoreProvider';

export const FillingCabinetSearchInput = observer(() => {
  const { t } = useTranslation();
  const { uiStore } = useDataStore();
  const { filingCabinetFilters, setSearchString } = uiStore;
  const { searchString } = filingCabinetFilters;

  const { form } = useForm({
    defaultValues: {
      filter: searchString,
    },
  });

  const handleChange = useCallback(() => {
    const value = form.watch('filter');
    setSearchString(value);
  }, [form, setSearchString]);

  return (
    <Box flex='1 1 332px' maxWidth='332px'>
      <Form form={form} data-testid=''>
        <SearchField
          id='filter'
          placeholder={t('cards.search')}
          data-testid=''
          debouncedOnChange={handleChange}
        />
      </Form>
    </Box>
  );
});
