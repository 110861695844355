import rootApiService from './root.api.service';

import { CallAPIType, METHODS, SnackbarActionType } from '@/types';
import { ApiError } from '@/utils/ApiError';
import { IFileInfoResponse, IFileUploadedResponse } from '@/apiTypes';
import { IFileStorageStructure } from '@/components/FileDialog/types';
import { EntityApi } from './entityApi.service';
import { DataProvider } from '@/types/grid';
import {
  IFileModelView,
  IFileLink,
  IFileProcessResponse,
  IFileProcessUpdate,
  IMediaData,
  IModelUpdate,
  IProcessPathRequest,
} from '@/types/file';
import snackBarConfigurator from '@/components/common/snackBarConfigurator';
import { enqueueSnackbar } from 'notistack';

const fileProcessingApi = new EntityApi('processing/file-processes');

class FilesApi {
  checkIfExists = async (sha1: string, snackbarAction: SnackbarActionType) => {
    const APIOptions: CallAPIType = {
      path: `file/exists?key=${sha1}`,
      method: METHODS.GET,
      body: null,
    };
    const apiResponse = await rootApiService.callAPI<IFileInfoResponse>(APIOptions);

    if (apiResponse.res && apiResponse.res.key === sha1) {
      return true;
    } else if (apiResponse.statusCode !== 404) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return false;
  };

  uploadFile = async (
    fileName: string,
    sha1: string,
    fileBytes: Uint8Array,
    snackbarAction: SnackbarActionType
  ) => {
    const tosterOptions = {
      snackbarAction,
    };
    const APIOptions: CallAPIType = {
      path: `file/upload?key=${sha1}&name=${fileName}`,
      method: METHODS.POST,
      tosterOptions,
      body: fileBytes,
      headers: new Map<string, string>([['Content-Type', 'application/octet-stream']]),
    };
    const apiResponse = await rootApiService.callAPI<IFileUploadedResponse>(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return apiResponse.res;
  };

  getFolderStructure = async (path?: string): Promise<IFileStorageStructure | null> => {
    const params = path ? `?path=${encodeURIComponent(path)}` : '';

    const APIOptions: CallAPIType = {
      path: `file/structure${params}`,
      method: METHODS.GET,
      tosterOptions: {
        snackbarAction: enqueueSnackbar,
        message: '',
      },
      body: null,
    };
    const apiResponse = await rootApiService.callAPI<IFileStorageStructure>(APIOptions);

    if (apiResponse.res) {
      return apiResponse.res;
    } else if (apiResponse.statusCode !== 404) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return null;
  };

  dataProvider: DataProvider<IFileLink> = (state, allCards?: IFileLink[]) => {
    return fileProcessingApi.getFiltersData({
      filters: state,
      localData: allCards,
    });
  };

  getAllFiles = async (limit: string, offset: string): Promise<IFileLink[] | null> => {
    const APIOptions: CallAPIType = {
      path: 'processing/file-processes/all',
      method: METHODS.POST,
      body: {
        limit,
        offset,
        sortByFields: [
          {
            name: 'createdTime',
            order: 'DESC',
          },
        ],
      },
    };
    const apiResponse = await rootApiService.callAPI<IFileLink[]>(APIOptions);

    if (apiResponse.res) {
      return apiResponse.res;
    } else if (apiResponse.statusCode !== 404) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return null;
  };

  getFilesByIds = async (ids: number[]): Promise<IFileLink[] | null> => {
    const APIOptions: CallAPIType = {
      path: 'processing/file-processes/byIds',
      method: METHODS.POST,
      body: ids,
    };
    const apiResponse = await rootApiService.callAPI<IFileLink[]>(APIOptions);

    if (apiResponse.res) {
      return apiResponse.res;
    } else if (apiResponse.statusCode !== 404) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return null;
  };

  addFile = async (paths: IProcessPathRequest[]): Promise<boolean> => {
    const APIOptions: CallAPIType = {
      path: 'processing/file-processes',
      method: METHODS.POST,
      body: {
        paths,
      },
      tosterOptions: { snackbarAction: enqueueSnackbar },
    };
    const apiResponse = await rootApiService.callAPI<IFileProcessResponse>(APIOptions);
    if (apiResponse.res) {
      if (apiResponse.res.errors && apiResponse.res.errors.length > 0) {
        apiResponse.res.errors.forEach((e) => {
          snackBarConfigurator.error(e.description);
        });
      }

      return true;
    } else if (apiResponse.statusCode !== 404) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return false;
  };

  delFileById = async (id: number): Promise<boolean> => {
    const APIOptions: CallAPIType = {
      path: `processing/file-processes/${id}`,
      method: METHODS.DELETE,
      tosterOptions: {
        snackbarAction: enqueueSnackbar,
      },
    };
    const apiResponse = await rootApiService.callAPI<IFileProcessResponse>(APIOptions);

    if (apiResponse.res && apiResponse.res.errors?.length > 0) {
      apiResponse.res.errors.forEach((e) => {
        snackBarConfigurator.error(e.description);
      });

      return false;
    }

    if (apiResponse.errorDescription) {
      return false;
    }

    return true;
  };

  update = async (updateStatus: IFileProcessUpdate): Promise<boolean> => {
    const APIOptions: CallAPIType = {
      path: 'processing/file-processes/update',
      method: METHODS.POST,
      body: {
        ...updateStatus,
      },
    };
    const apiResponse = await rootApiService.callAPI<IFileInfoResponse>(APIOptions);
    if (apiResponse.res) {
      return true;
    } else if (apiResponse.statusCode !== 404) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return false;
  };

  getModelsByFileId = async <T>(fileId: string): Promise<IFileModelView<T>[] | null> => {
    const APIOptions: CallAPIType = {
      path: `processing/file-models/by-file?fileId=${fileId}`,
      method: METHODS.GET,
    };

    const apiResponse = await rootApiService.callAPI<IFileModelView<T>[]>(APIOptions);

    if (apiResponse.res) {
      return apiResponse.res;
    } else if (apiResponse.statusCode !== 404) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return null;
  };

  getMediaData = async (
    fileModelId: number | string,
    mediaFileId: number
  ): Promise<IMediaData | null> => {
    const APIOptions: CallAPIType = {
      path: `processing/file-models/${fileModelId}/media-data?mediaId=${mediaFileId}`,
      method: METHODS.GET,
    };

    const apiResponse = await rootApiService.callAPI(APIOptions);

    if (apiResponse.res) {
      return apiResponse.res;
    } else if (apiResponse.statusCode !== 404) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return null;
  };

  createCommentFile = async (comment: string | null, cardId: number, linkId: number) => {
    const APIOptions: CallAPIType = {
      path: `card/${cardId}/file-links/${linkId}/photo-comment`,
      method: METHODS.PUT,
      body: {
        comment,
      },
    };
    const apiResponse = await rootApiService.callAPI(APIOptions);
    if (apiResponse.res) {
      return true;
    } else if (apiResponse.statusCode !== 404) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }
  };

  updateModel = async (updateStatus: IModelUpdate): Promise<boolean> => {
    const APIOptions: CallAPIType = {
      path: 'processing/file-models/update',
      method: METHODS.POST,
      body: {
        ...updateStatus,
      },
      tosterOptions: { snackbarAction: enqueueSnackbar },
    };
    const apiResponse = await rootApiService.callAPI<IFileInfoResponse>(APIOptions);
    if (apiResponse.res) {
      return true;
    } else if (apiResponse.statusCode !== 404) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return false;
  };
}

export default new FilesApi();
