import { createSvgIcon } from '@mui/material';

export const MultipassDuplicateIcon = createSvgIcon(
  <svg width='18' height='22' viewBox='0 0 18 22' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H7V22H9V0H7V2ZM7 17H2L7 11V17ZM16 2H11V4H16V17L11 11V20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2Z'
      fillOpacity='0.8'
    />
  </svg>,
  'multipass_duplicate'
);
