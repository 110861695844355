import { CardFormData } from '@/types/card';
import { CardTemplateData } from '@/types/cardTemplate';

export const formatTemplateValues = (templateData: CardTemplateData, data: CardFormData | null) => {
  return templateData.fields.reduce((acc: CardFormData, curr) => {
    let result = data?.[curr.id];

    if (!data?.[curr.id]) {
      if (curr.type === 'ListObject') {
        const customType = templateData.customTypes?.find((type) => type.id === curr.customType);

        if (customType) {
          const value = formatTemplateValues(customType, data);

          result = [value];
        }
      }
    }

    acc[curr.id] = result || null;

    return acc;
  }, {});
};
