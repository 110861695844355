import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { ConfirmationType, useConfirmationModal } from 'stc-ui-kit';

interface UseUnsavedChangesModalProps {
  when: boolean;
  onSave: () => Promise<unknown>;
}

export const useUnsavedChangesModal = ({ when, onSave }: UseUnsavedChangesModalProps) => {
  const { t } = useTranslation();
  const { showConfirmationModal } = useConfirmationModal();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      when && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state === 'blocked' && !!when) {
      void showUnsavedChangesModal();
    } else {
      blocker.proceed?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker.state]);

  const showUnsavedChangesModal = async () => {
    const submitType = await showConfirmationModal({
      title: t('cards.closeCardTitle'),
      text: t('cards.unsavedChangesConfirmation'),
      note: t('thisActionCannotBeUndone'),
      primaryButtonText: t('close'),
      secondaryButtonText: t('saveAndClose'),
      cancelButtonText: t('cancel'),
    });

    switch (submitType) {
      case ConfirmationType.PrimaryAction:
        blocker.proceed?.();
        break;

      case ConfirmationType.SecondaryAction:
        const error = await onSave();
        if (error) {
          blocker.reset?.();

          return;
        }
        blocker.proceed?.();
        break;

      case ConfirmationType.Cancel:
        blocker.reset?.();
        break;
    }
  };
};
