import { orderBy, uniqBy } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import { EDataGridStoreSortMode, IDataGridStoreState } from 'types/grid';

import { IPaginator, LOAD_ALL_LIMIT } from './paginator';
import { GridStore } from '@/stores/grid.store';

enum CursorPaginatorPages {
  // первая страница
  START = 0,
  // вторая страница
  AFTER_START = 1,
  // ненулевая страница с неизвестным номером
  UNKNOWN = 2,
}

export class CursorPaginator<T> implements IPaginator<T> {
  gridStore: GridStore<T>;

  constructor(gridStore: GridStore<T>) {
    this.gridStore = gridStore;
    if (this.gridStore.state.after || this.gridStore.state.before) {
      this.gridStore.currentPage = CursorPaginatorPages.UNKNOWN;
    }

    makeAutoObservable(this);
  }

  setCurrentPage(page: number) {
    if (page === 0) {
      this.resetCurrentPage();
    }

    if (page > 0) {
      // Идем вперед по гриду
      if (page > this.gridStore.currentPage) {
        this.gridStore.state = {
          ...this.gridStore.state,
          after: this.getLastElementFromData(),
          before: null,
        };
        this.gridStore.currentPage = CursorPaginatorPages.UNKNOWN;

        return;
      }

      // Идем назад по гриду
      if (page < this.gridStore.currentPage) {
        this.gridStore.state = {
          ...this.gridStore.state,
          before: this.getFirstElementFromData(),
          after: null,
        };

        return;
      }
    }
  }

  resetCurrentPage() {
    runInAction(() => {
      this.gridStore.state.after = null;
      this.gridStore.state.before = null;
      this.gridStore.currentPage = this.isOnForcedLastPage
        ? CursorPaginatorPages.UNKNOWN
        : CursorPaginatorPages.START;
    });
  }

  getFirstElementFromData() {
    const firstElementIndex = 0;

    return this.gridStore.data[firstElementIndex];
  }

  getLastElementFromData() {
    return this.gridStore.data[this.gridStore.data.length - 1];
  }

  async loadData(filters: IDataGridStoreState<T>) {
    const dataProvider = this.gridStore.dataProvider;

    if (this.gridStore.state.before || this.gridStore.state.after) {
      this.isOnForcedLastPage = false;
    }

    let sort: EDataGridStoreSortMode = this.gridStore.state.sort;

    if (this.isOnForcedLastPage) {
      sort =
        sort === EDataGridStoreSortMode.ASC
          ? EDataGridStoreSortMode.DSC
          : EDataGridStoreSortMode.ASC;
    }

    const data = await dataProvider({ ...filters, sort });

    // шагнули назад, и получили не "полную" страницу данных
    if (this.gridStore.state.before && !data.hasNextPage) {
      data.data = uniqBy([...data.data, ...this.gridStore.data], 'id');
      data.hasNextPage = data.data.length > this.gridStore.state.limit;
      data.data = data.data.slice(0, this.gridStore.state.limit);
      this.resetCurrentPage();
    }

    if (!data.hasNextPage && !this.gridStore.state.after) {
      this.resetCurrentPage();
    }

    const originalGridSort = this.gridStore.state.sort;

    if (originalGridSort !== sort) {
      const sortField = this.gridStore.state.sortField;

      return {
        ...data,
        data: orderBy(
          data.data,
          [sortField],
          [originalGridSort === EDataGridStoreSortMode.ASC ? 'asc' : 'desc']
        ),
      };
    }

    return data;
  }

  async loadAllData() {
    const data = await this.gridStore.dataProvider({
      ...this.gridStore.state,
      before: null,
      after: null,
      limit: LOAD_ALL_LIMIT,
    });

    return data.data;
  }

  private isOnForcedLastPage = false;

  getLastPage() {
    this.isOnForcedLastPage = true;
    this.gridStore.setCurrentPageAndLoadData(0);
  }

  getFirstPage() {
    this.isOnForcedLastPage = false;
    this.gridStore.setCurrentPageAndLoadData(0);
  }

  get hasNextPage() {
    if (this.isOnForcedLastPage) {
      return false;
    }

    return this.gridStore.hasNextPage;
  }

  get hasPrevPage() {
    return this.gridStore.currentPage !== CursorPaginatorPages.START;
  }
}
