import { useTranslation } from 'react-i18next';
import { Button, ButtonType, icons, Tooltip } from 'stc-ui-kit';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { Stack, Typography } from '@mui/material';
import { showErrorNotification, showInfoNotification } from '@/utils/notifications';

export const CopyIdButton = ({
  id,
  isCompact = true,
}: {
  id: number | null | undefined;
  isCompact?: boolean;
}) => {
  const { t } = useTranslation();

  const handleCopyId = async () => {
    if (id) {
      try {
        await copyToClipboard(id.toString());
        showInfoNotification({ header: t('messages.idCopied') });
      } catch (error) {
        showErrorNotification({ header: t('messages.failedCopyID') });
      }
    }
  };

  return (
    <Stack direction={'row'} spacing={1}>
      {isCompact && (
        <>
          <Typography fontWeight={500} whiteSpace={'nowrap'}>{`ID: ${
            typeof id === 'number' ? id : ''
          }`}</Typography>
          <Tooltip tooltip={t('copyId')}>
            <Button icon={icons.IconCopySolid} onClick={handleCopyId} type={ButtonType.Icon} />
          </Tooltip>
        </>
      )}

      {!isCompact && (
        <Button onClick={handleCopyId} icon={icons.IconLink} type={ButtonType.Text}>
          {t('copyCardId')}
        </Button>
      )}
    </Stack>
  );
};
