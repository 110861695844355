import { Box, Stack } from '@mui/material';

import { DocumentsAndAttachedFilesTable } from './DocumentsAndAttachedFilesTable';
import { ModelsHeader } from '../ModelsHeader/ModelsHeader';
import { BackdropLoader } from '@/components/loaders/BackdropLoader';
import { useCardCreationContext } from '../../../hooks/useCardCreationContext';
import { useState } from 'react';
import { useFullScreenCardCarousel } from '@/modules/multipass/hooks/useFullScreenCardCarousel';
import { TableRow } from 'stc-ui-kit';
import { fetchCardPhotos } from '../../../utils/fetchCardPhotos';
import { CarouselPhotosContextProvider } from '@/modules/multipass/context/CarouselPhotosContext';
import { WmsPlayer } from '@/components/WmsPlayer';
import { ITableItem } from '@/types/file';

interface Props {
  tab: 'documents' | 'attachedFiles';
}

export const DocumentsAndAttachedFiles: React.FC<Props> = ({ tab }) => {
  const { isFilesLoading, fileLinks } = useCardCreationContext();
  const [playerAudioPath, setPlayerAudioPath] = useState<string | null>(null);
  const { carouselRender, loadAndShowPhotos, closeFullScreenCarousel } =
    useFullScreenCardCarousel();

  const imageFiles = fileLinks.filter((file) => file.type === 'img' && file.file?.path);

  const onRowOpen = (item: TableRow<ITableItem>) => {
    switch (item.type) {
      case 'img':
        const index = imageFiles.findIndex((imgFile) => imgFile.id === Number(item.id));

        if (index === -1) {
          return;
        }

        setPlayerAudioPath(null);

        void loadAndShowPhotos({
          selectedSlideIndex: index,
          fetcher: () => fetchCardPhotos({ imageFiles, withFaceData: tab === 'documents' }),
        });
        break;
      case 'audio':
        if (item.file.path === playerAudioPath) {
          setPlayerAudioPath(null);
        } else {
          setPlayerAudioPath(item.file.path);
        }
        break;
      default:
        return;
    }
  };

  return (
    <>
      <Stack direction={'column'} width={'100%'} height={'100%'} position={'relative'}>
        <ModelsHeader />

        <Box height={'100%'} display={'flex'} flexDirection={'column'}>
          <DocumentsAndAttachedFilesTable tab={tab} onRowOpen={onRowOpen} />
        </Box>

        {playerAudioPath && <WmsPlayer fileName={playerAudioPath} />}

        <CarouselPhotosContextProvider onDeletePhoto={closeFullScreenCarousel}>
          {carouselRender}
        </CarouselPhotosContextProvider>
      </Stack>
      <BackdropLoader open={isFilesLoading} />
    </>
  );
};
