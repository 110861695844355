import { appColors } from '@/Providers/ThemeProvider/colors';
import { makeStyles } from '@/hooks/makeStyles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { prepareRoleName } from '@/utils/prepareRoleName';
import { HierarchyAccordion, HierarchyAccordionItem } from 'stc-ui-kit';
import { useRolesContext } from '../context/RolesContext';

const useStyles = makeStyles()(() => ({
  accordion: {
    '[data-testid="hierarchy-accordion-item-wrapper"]': {
      '&>[class*="AccordionItem-module__listItemInner"]': {
        display: 'none',
      },
      '&>[data-testid*="hierarchy-accordion-item"]>div>div>[class*="Icon-module__iconContainer"]': {
        display: 'none',
      },
    },
  },
}));

export const RolesAccordion = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { rolesWithPrivsInGroups, selectRole } = useRolesContext();

  const nodes: HierarchyAccordionItem[] = [
    {
      id: 'wrapper',
      title: '',
      children: rolesWithPrivsInGroups.map((role) => ({
        id: role.id.toString(),
        title: prepareRoleName(role.roleName),
        isSelectable: true,
        children: role.privilegeGroups.map((group) => ({
          id: `${role.id}-${group.name}`,
          isSelectable: false,
          title: t(`privileges.${group.name}`, ''),
          children: group.privileges.map((priv) => ({
            id: priv,
            isSelectable: false,
            title: t(`privileges.${priv}.title`, ''),
          })),
        })),
      })),
    },
  ];

  return (
    <Box
      sx={{
        background: appColors.darkThemeBackground,
        border: `1px solid ${appColors.darkThemeBorder}`,
        borderRadius: '4px',
      }}
      width={'100%'}
      height={'max-content'}
      flexGrow={1}
    >
      <HierarchyAccordion
        onSelectItem={(id) => selectRole(Number(id))}
        nodes={nodes}
        data-testid=''
        className={classes.accordion}
      />
    </Box>
  );
};
