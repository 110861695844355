import { Pause, PlayArrow } from '@mui/icons-material';
import React from 'react';

type Status = 'notStarted' | 'inProcess';
interface ModelStatusProps {
  status: Status;
}

export const ModelStatus = (props: ModelStatusProps) => {
  switch (props.status) {
    case 'notStarted':
      return <PlayArrow />;
    case 'inProcess':
      return <Pause />;
  }
};
