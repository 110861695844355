import { FileGroupType } from '@/types/file';
import { EntryTab } from '@/types/ui.types';

export const getTabByFileGroup = (group: FileGroupType | undefined): EntryTab | null => {
  switch (group) {
    case 'FACE':
      return 'face';
    case 'VOICE':
      return 'voice';
    case 'DOC':
      return 'documents';
    case null:
      return 'attachedFiles';
    default:
      return null;
  }
};
