import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';

interface ICollapsableSection {
  children?: ReactNode;
  title: string;
  count: number;
  isCollapse: boolean;
  openClose: () => void;
}

export const CollapsableSection: FC<ICollapsableSection> = ({
  children,
  title,
  count,
  isCollapse,
  openClose,
}) => {
  return (
    <Box sx={{ marginTop: '15px' }}>
      <Stack direction={'row'} alignItems={'center'}>
        <IconButton onClick={openClose}>
          {!isCollapse ? <ArrowDropDown /> : <ArrowRight />}
        </IconButton>
        <Typography sx={{ fontWeight: '500' }}>{`${title}(${count})`}</Typography>
        <Divider orientation='horizontal' sx={{ flex: 1, marginLeft: '5px' }} />
      </Stack>
      {!isCollapse && <Box sx={{ paddingLeft: '16px' }}>{children}</Box>}
    </Box>
  );
};
