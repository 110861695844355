import { CallAPIType, METHODS } from '@/types';
import { IGroup } from '@/types/group';
import rootApiService from './root.api.service';

class GroupService {
  getAll = async (): Promise<IGroup[] | null> => {
    const APIOptions: CallAPIType = {
      path: 'user/department/all',
      method: METHODS.GET,
    };

    const apiResponse = await rootApiService.callAPI<IGroup[]>(APIOptions);

    return apiResponse.res;
  };

  create = async (group: IGroup): Promise<IGroup | null> => {
    const APIOptions: CallAPIType = {
      path: 'user/department',
      method: METHODS.POST,
      body: {
        id: 0,
        groupName: group.groupName,
      },
    };

    const apiResponse = await rootApiService.callAPI<IGroup>(APIOptions);

    return apiResponse.res;
  };

  edit = async (group: IGroup): Promise<IGroup | null> => {
    const APIOptions: CallAPIType = {
      path: 'user/department',
      method: METHODS.PUT,
      body: {
        id: group.id,
        groupName: group.groupName,
      },
    };

    const apiResponse = await rootApiService.callAPI<IGroup>(APIOptions);

    return apiResponse.res;
  };

  delete = async (groupId: number): Promise<IGroup | null> => {
    const APIOptions: CallAPIType = {
      path: `user/department/${groupId}`,
      method: METHODS.DELETE,
    };

    const apiResponse = await rootApiService.callAPI<null>(APIOptions);

    return apiResponse.res;
  };

  addOrExcludeUserfromGroup = async (userIds: number[], group: IGroup | null) => {
    const APIOptions: CallAPIType = {
      path: `user/assign-department?departmentId=${group?.id || ''}`,
      method: METHODS.PUT,
      body: userIds,
    };

    const apiResponse = await rootApiService.callAPI<null>(APIOptions);

    return apiResponse.res;
  };
}

export default new GroupService();
