import { EFileProcessStatus } from '@/types/file';

export const processStatusToColor: Record<EFileProcessStatus, string> = {
  IN_PROGRESS: 'orange',
  VERIFIED: 'purple',
  NEW: 'gray',
  COMPLETED: 'green',
  FAILED: 'red',
  DUPLICATE: 'gray',
  NO_MODEL: 'green',
};
