import { EFileProcessStatus } from '@/types/file';
import { processStatusToColor } from './processStatusToColor';
import { ECardExportStatus } from '@/types/card';
import { cardExportStatusToColor } from './cardExportStatusToColor';

const SIZE = '8px';

export const getStatusIcon = ({
  status,
  isCard = false,
}: {
  status: EFileProcessStatus | ECardExportStatus;
  isCard?: boolean;
}) => {
  return (
    <span
      style={{
        display: 'inline-block',
        marginRight: '7px',
        width: SIZE,
        minWidth: SIZE,
        height: SIZE,
        minHeight: SIZE,
        borderRadius: '50%',
        backgroundColor: isCard
          ? cardExportStatusToColor[status as ECardExportStatus]
          : processStatusToColor[status as EFileProcessStatus],
      }}
    />
  );
};
