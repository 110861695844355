import lodash from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';

import { IPaginator, paginators } from 'utils/grid/paginator';

import {
  DataProvider,
  EDataGridStoreSortMode,
  IDataGridStore,
  IDataGridStoreData,
  IDataGridStoreState,
  IGridColumnFilter,
  IGridFilter,
} from 'types/grid';

export const defaultGridFiltersState: IDataGridStoreState<null> = {
  offset: 0,
  limit: 25,
  columnFilterMap: [],
  quickFilters: [],
  sortField: 'id' as never,
  sort: EDataGridStoreSortMode.ASC,
  after: null,
  before: null,
  paginationType: 'offset',
};

export type DefaultSortState<T> = Pick<IDataGridStoreState<T>, 'sort' | 'sortField'>;

export const GRID_DEFAULT_ROWS_ON_PAGE = 25;

// Стора состояния грида
export class GridStore<T> implements IDataGridStore<T> {
  data: T[] = [];
  isPending = false;
  error = '';
  hasNextPage = true;

  state: IDataGridStoreState<T> = { ...defaultGridFiltersState } as IDataGridStoreState<T>;
  defaultSortState: DefaultSortState<T> = {
    sort: defaultGridFiltersState.sort,
    sortField: defaultGridFiltersState.sortField,
  };
  paginator: IPaginator<T>;

  checkedItems: T[] = [];
  rowsOnPage = GRID_DEFAULT_ROWS_ON_PAGE;
  currentPage = 0;

  dataProvider: DataProvider<T>;

  selectionChangeHandler?: () => void;

  withInfiniteScroll = false;

  constructor(
    provider: DataProvider<T>,
    initialState?: Partial<IDataGridStoreState<T>>,
    defaultSortState?: DefaultSortState<T>,
    withInfiniteScroll?: boolean
  ) {
    this.dataProvider = provider;
    this.state = this.getDefaultState();

    if (initialState) this.state = { ...this.state, ...initialState };

    this.rowsOnPage = this.state.limit;

    if (defaultSortState) {
      this.defaultSortState = {
        ...defaultSortState,
        sortField: defaultSortState.sortField,
      };
      this.state.sortField = defaultSortState.sortField;
      this.state.sort = defaultSortState.sort;
    }

    const PaginatorConstructor = paginators[this.state.paginationType];
    this.paginator = new PaginatorConstructor(this);

    this.withInfiniteScroll = !!withInfiniteScroll;

    makeAutoObservable(this);
  }

  get isFiltersApplied() {
    return this.state.columnFilterMap.length > 0;
  }

  setCheckedItems(items: T[]) {
    this.checkedItems = items;
  }

  setDataProvider(provider: DataProvider<T>) {
    this.dataProvider = provider;
  }

  resetChecked(): void {
    this.checkedItems = [];
  }

  resetSort() {
    // need to revert default sort
    if (this.defaultSortState.sortField === this.state.sortField) {
      return this.setSortState({
        field: this.defaultSortState.sortField,
        order:
          this.state.sort === EDataGridStoreSortMode.ASC
            ? EDataGridStoreSortMode.DSC
            : EDataGridStoreSortMode.ASC,
      });
    }

    this.setSortState({
      field: this.defaultSortState.sortField,
      order: this.defaultSortState.sort,
    });
  }

  setSortState({ field, order }: { field: keyof T; order: EDataGridStoreSortMode }) {
    this.state.sortField = field;
    this.state.sort = order;
    this.paginator.getFirstPage();
  }

  reload = async () => {
    const reloadPrevData = !!this.withInfiniteScroll;

    await this.loadData({
      ...this.state,
      limit: reloadPrevData ? Math.max(this.data.length, this.rowsOnPage) : this.rowsOnPage,
      offset: reloadPrevData ? 0 : this.state.offset,
      appendPrevData: false,
    });
  };

  loadData = async (state?: IDataGridStoreState<T> & { appendPrevData?: boolean }) => {
    try {
      this.setPending(true);
      this.hasNextPage = false;
      this.setError('');
      this.setState(state || this.getDefaultState());

      const filters = lodash.cloneDeep(this.state);
      const data = await this.paginator.loadData(filters);

      runInAction(() => {
        const prevData = state?.appendPrevData ? this.data : [];

        this.setData({
          hasNextPage: data.hasNextPage,
          data: [...prevData, ...data.data],
        });
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setError('Something went wrong, data not loaded');
      });
    } finally {
      runInAction(() => {
        this.setPending(false);
      });
    }
  };

  getDefaultState(): IDataGridStoreState<T> {
    return {
      ...defaultGridFiltersState,
    } as IDataGridStoreState<T>;
  }

  applyFilters(
    filters?: IGridColumnFilter<T>[],
    searchString?: string,
    quickFilters?: IGridFilter<T>[]
  ) {
    if (filters) {
      this.state.columnFilterMap = lodash.cloneDeep(filters);
    }
    if (quickFilters) {
      this.state.quickFilters = quickFilters;
    }
    if (searchString !== undefined) {
      this.state.searchString = searchString;
    }
    this.setCurrentPageAndLoadData(0);
  }

  setRowsOnPage = (number: number) => {
    this.rowsOnPage = number;
    this.paginator.getFirstPage();
  };

  setCurrentPageAndLoadData = (newCurrentPage: number) => {
    if (this.isPending) return;
    this.paginator.setCurrentPage(newCurrentPage);

    void this.reload();
  };

  checkCurrentPage = (reload?: boolean) => {
    const oldCurrentPage = this.currentPage;

    if (reload || oldCurrentPage !== this.currentPage) {
      void this.reload();
    }
  };

  setState = (state: IDataGridStoreState<T>) => {
    this.state = state;
  };

  setData = (data: IDataGridStoreData<T>) => {
    const preparedData = lodash.uniqBy(data.data, 'id');

    this.data = preparedData;
    this.hasNextPage = data.hasNextPage || false;
    this.checkCurrentPage();
  };

  setPending = (isPending: boolean) => {
    this.isPending = isPending;
  };

  setError = (error: string) => {
    this.error = error;
  };

  getFirstElementFromData() {
    return this.data[0];
  }

  getLastElementFromData() {
    return this.data[this.data.length - 1];
  }
}
