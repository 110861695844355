import { ForwardedRef, forwardRef } from 'react';
import { ReactComponent as Icon } from 'assets/icons/entries.svg';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EntriesIcon = forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => {
  return (
    <SvgIcon ref={ref} {...props}>
      <Icon />
    </SvgIcon>
  );
});