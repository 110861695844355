import { Avatar } from '@mui/material';
import { ReactComponent as ModelTypeAudio } from 'assets/icons/modelTypeAudio.svg';
import { ReactComponent as ModelTypePhoto } from 'assets/icons/modelTypePhoto.svg';

import { appColors } from '@/Providers/ThemeProvider/colors';
import { getModelQuality } from '@/modules/multipass/utils/getModelQuality';
import { modelQualityToColor } from '@/modules/multipass/utils/modelQualityToColor';
import { FileType } from '@/types/file';
import { SvgIcon } from '@/components/SvgIcon/SvgIcon';

export const getModelIcon = ({ quality, type }: { quality: number | null; type: FileType }) => {
  return (
    <Avatar sx={{ width: 24, height: 24 }}>
      <SvgIcon
        size={{ w: 24, h: 24 }}
        IconComponent={type === 'audio' ? ModelTypeAudio : ModelTypePhoto}
        rectColor={
          quality !== null
            ? (modelQualityToColor[getModelQuality(quality)] as keyof typeof appColors)
            : 'rgba(205, 205, 205, 1)'
        }
      />
    </Avatar>
  );
};
