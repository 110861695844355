import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContentWithHeaderWrapper } from '@/components/common/Pages/ContentWithHeaderWrapper';
import { BiometricModels } from './BiometricModels';
import { Tab, Tabs } from 'stc-ui-kit';
import { CardModelsTab } from '@/types/ui.types';
import { observer } from 'mobx-react';
import { useDataStore } from '@/Providers/StoreProvider';
import { useCardCreationContext } from '../../hooks/useCardCreationContext';
import { DocumentsAndAttachedFiles } from './DocumentsAndAttachedFiles/DocumentsAndAttachedFiles';

export const CardModelsSection = observer(() => {
  const { t } = useTranslation();
  const { uiStore } = useDataStore();
  const { cardData } = useCardCreationContext();
  const cardId = cardData?.id || 0;
  const tabs: Array<Tab> = [
    {
      id: 'biometricModels',
      title: t('cards.biometricModels'),
    },
    {
      id: 'documents',
      title: t('cards.documents'),
    },
    {
      id: 'attachedFiles',
      title: t('attachedFiles'),
    },
  ];

  const activeTab = uiStore.getOpenedCard(cardId)?.activeTab || 'biometricModels';
  const handleTabChange = (tabId: string) => {
    uiStore.setActiveTab(cardId, tabId as CardModelsTab);
  };

  return (
    <ContentWithHeaderWrapper
      styles={{
        header: {
          padding: 0,
        },
      }}
      leftSideHeader={
        <Tabs tabs={tabs} activeTabId={activeTab} onChange={handleTabChange} data-testid='' />
      }
      content={
        <Box flex='1' overflow='auto' display='flex' flexDirection='column'>
          {activeTab === 'biometricModels' && <BiometricModels />}
          {activeTab === 'documents' && <DocumentsAndAttachedFiles tab='documents' />}
          {activeTab === 'attachedFiles' && <DocumentsAndAttachedFiles tab='attachedFiles' />}
        </Box>
      }
    />
  );
});
