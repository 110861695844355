import { Language } from '@/types';
import { CardTemplateData, FieldDescriptor } from '@/types/cardTemplate';
import { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DateTemplateField,
  RadioGroupTemplateField,
  StringTemplateField,
  ListStringTemplateField,
  SingleSelectTemplateField,
  MultiselectTemplateField,
  MultiInputTemplateField,
} from './TemplateFields';

interface IProps {
  templateData: CardTemplateData;
  customTypeIdGetter?: (fieldId: string) => string;
  isColumn?: boolean;
  fieldData: FieldDescriptor;
  readonly: boolean;
}

interface TemplateFieldContextValue {
  fieldData: FieldDescriptor;
  fieldName: string;
  language: Language;
  validationErrorMessage: string;
  templateData: CardTemplateData | null;
  customTypeFieldId: string;
  readonly: boolean;
}

export const TemplateFieldContext = createContext<TemplateFieldContextValue>({
  fieldData: {} as FieldDescriptor,
  fieldName: '',
  language: 'ru',
  validationErrorMessage: '',
  templateData: null,
  customTypeFieldId: '',
  readonly: true,
});

export default function TemplateFieldRenderer({
  templateData,
  customTypeIdGetter,
  isColumn,
  fieldData,
  readonly,
}: IProps) {
  const { t, i18n } = useTranslation();
  const language = i18n.language as Language;

  const name = fieldData.names?.find((n) => n.locale === language)?.value || fieldData.name;

  return (
    <TemplateFieldContext.Provider
      value={{
        fieldData,
        customTypeFieldId: customTypeIdGetter?.(fieldData.id) || '',
        language,
        fieldName: name,
        templateData: templateData,
        validationErrorMessage: t('validation.invalidFormat'),
        readonly,
      }}
    >
      {fieldData.type === 'String' && <StringTemplateField />}
      {fieldData.type === 'ListString' && <ListStringTemplateField />}
      {fieldData.type === 'Date' && <DateTemplateField />}
      {fieldData.type === 'Dict' && fieldData.view === 'Radio' && <RadioGroupTemplateField />}
      {fieldData.type === 'Dict' && fieldData.view !== 'Radio' && <SingleSelectTemplateField />}
      {fieldData.type === 'ListDict' && <MultiselectTemplateField />}
      {fieldData.type === 'ListObject' && <MultiInputTemplateField isColumn={isColumn} />}
    </TemplateFieldContext.Provider>
  );
}
