import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Form,
  MultiSelectField,
  PasswordField,
  TextField,
  useForm,
  Modal,
  SelectField,
} from 'stc-ui-kit';
import userService from '@/services/user.service';
import { GridStore } from '@/stores/grid.store';
import { DefaultUserRoles, IRole, IUserToBeCreated, IUserToBeUpdated } from '@/types';
import { User } from '@/types/user';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { prepareRoleName } from '@/utils/prepareRoleName';
import { InputWrapper } from '../InputWrapper';
import { isEqual } from 'lodash';
import { IGroup } from '@/types/group';
import groupService from '@/services/group.service';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { permissionControl } from '@/types/permissionControl';
import { useValidation } from '@/hooks/useValidation';
import { trimStringFormValues } from '@/utils/trimStringFormValues';

type CreateUserFormValues = {
  username: string;
  rolesIds: string[] | null;
  password: string;
  confirmPassword: string;
  firstname: string;
  lastname: string;
  middlename: string;
  description: string;
  groupId: string;
};

interface Props {
  isOpened: boolean;
  onClose: () => void;
  initialUserData: User | null;
  store: GridStore<User>;
  fetchUsers: () => Promise<void>;
}

export const CreateUserModal: React.FC<Props> = observer(
  ({ isOpened, onClose, initialUserData, store, fetchUsers }) => {
    const { t } = useTranslation();
    const { havePermissions } = useUserPermissionsContext();
    const { createConfirmButton, editConfirmButton } = permissionControl.administration.users;

    const [roleOptions, setRoleOptions] = useState<IRole[]>([]);
    const [disableConfirm, setDisableConfirm] = useState(false);
    const [defaultValueSelect, setDefaultValueSelect] = useState<IRole | undefined>();

    const [groups, setGroups] = useState<IGroup[]>([]);

    const { form } = useForm<CreateUserFormValues>({
      values: {
        description: initialUserData?.description || '',
        firstname: initialUserData?.firstname || '',
        lastname: initialUserData?.lastname || '',
        middlename: initialUserData?.middlename || '',
        rolesIds:
          initialUserData?.roles.map((role) => String(role.id)) ||
          (defaultValueSelect ? [String(defaultValueSelect.id)] : []),
        groupId: initialUserData?.department?.id.toString() || '',
        username: initialUserData?.username || '',
        confirmPassword: '',
        password: '',
      },
    });

    const { formState, handleSubmit, watch, reset } = form;
    const { validateString, validatePassword, validatePasswordConfirm } = useValidation(
      watch('password')
    );

    useEffect(() => {
      const fetchRoles = async () => {
        try {
          const roles = await userService.getRoles();
          setDefaultValueSelect(roles.find((el) => el.roleName === DefaultUserRoles.OPERATOR));
          setRoleOptions(roles);
        } catch (error) {
          console.error('Error fetching roles:', error);
        }
      };

      const fetchGroups = async () => {
        const groups = await groupService.getAll();
        setGroups(groups || []);
      };

      fetchRoles().catch((error) => {
        console.error('Error in fetchRoles:', error);
      });

      fetchGroups().catch((error) => console.error(error));
    }, []);

    const handleClose = () => {
      reset();
      onClose();
    };

    const onSubmit = async (values: CreateUserFormValues) => {
      setDisableConfirm(true);

      const selectedRoleIds = values.rolesIds?.map((roleId) => Number(roleId)) || [];

      const selectedGroup = Number(values.groupId);

      trimStringFormValues(values);

      try {
        if (initialUserData) {
          const updatedUser: IUserToBeUpdated = {
            ...initialUserData,
            username: values.username,
            roles: selectedRoleIds,
            department: selectedGroup,
            firstname: values.firstname,
            lastname: values.lastname,
            middlename: values.middlename,
            description: values.description,
          };
          await userService.updateUser(updatedUser);
        } else {
          const createdUser: IUserToBeCreated = {
            username: values.username,
            password: values.password,
            firstname: values.firstname,
            lastname: values.lastname,
            middlename: values.middlename,
            description: values.description,
            department: selectedGroup,
            roles: selectedRoleIds,
          };
          await userService.createUser(createdUser);
        }

        await fetchUsers();
        store.resetChecked();
        handleClose();
      } catch (error) {
        console.error(error);
      }

      setDisableConfirm(false);
    };

    const isConfirmDisabled =
      !!Object.keys(formState.errors).length ||
      disableConfirm ||
      isEqual(formState.defaultValues, form.watch());

    return (
      <Form data-testid='' form={form}>
        {isOpened && (
          <Modal
            onClose={handleClose}
            onConfirm={handleSubmit(onSubmit)}
            title={initialUserData ? t('userEdit') : t('userCreation')}
            isConfirmDisabled={isConfirmDisabled}
            buttonConfirmText={initialUserData ? t('save') : t('create')}
            isConfirmButtonHidden={
              initialUserData
                ? !havePermissions(editConfirmButton)
                : !havePermissions(createConfirmButton)
            }
            buttonCloseText={t('cancel')}
            data-testid=''
          >
            <Grid container columnSpacing={3} rowSpacing={2}>
              <InputWrapper>
                <TextField
                  id='username'
                  isRequired
                  placeholder={t('enterUsername')}
                  data-testid=''
                  label={t('login')}
                  validate={validateString({ isRequired: true, min: 3, max: 50 })}
                />
              </InputWrapper>

              <InputWrapper>
                {!initialUserData && (
                  <PasswordField
                    id='password'
                    data-testid=''
                    isRequired
                    placeholder={t('enterPassword')}
                    label={t('password')}
                    validate={validatePassword}
                  />
                )}
              </InputWrapper>

              <InputWrapper>
                {!initialUserData && (
                  <PasswordField
                    id='confirmPassword'
                    data-testid=''
                    isRequired
                    placeholder={t('enterPassword')}
                    label={t('passwordConfirmation')}
                    validate={validatePasswordConfirm}
                  />
                )}
              </InputWrapper>

              <InputWrapper>
                <TextField
                  id='lastname'
                  label={t('lastname')}
                  data-testid=''
                  isRequired
                  placeholder={t('enterLastname')}
                  validate={validateString({ isRequired: true, min: 1, max: 50 })}
                />
              </InputWrapper>

              <InputWrapper>
                <TextField
                  id='firstname'
                  label={t('firstname')}
                  data-testid=''
                  isRequired
                  placeholder={t('enterFirstname')}
                  validate={validateString({ isRequired: true, min: 1, max: 50 })}
                />
              </InputWrapper>

              <InputWrapper>
                <TextField
                  id='middlename'
                  label={t('middlename')}
                  data-testid=''
                  placeholder={t('enterMiddlename')}
                  validate={validateString({
                    isNullable: true,
                    isRequired: false,
                    min: 5,
                    max: 50,
                  })}
                />
              </InputWrapper>

              <InputWrapper>
                <MultiSelectField
                  id='rolesIds'
                  data-testid=''
                  label={t('role')}
                  placeholder={t('chooseRole')}
                  isRequired
                  options={roleOptions.map((r) => ({
                    id: String(r.id),
                    label: prepareRoleName(r.roleName),
                  }))}
                />
              </InputWrapper>

              <InputWrapper>
                <SelectField
                  isRequired
                  id='groupId'
                  label={t('group')}
                  placeholder={t('selectGroup')}
                  options={groups.map((g) => ({
                    id: g.id.toString(),
                    label: g.groupName,
                  }))}
                  data-testid='groupId'
                  validate={validateString({ isRequired: true })}
                />
              </InputWrapper>

              <InputWrapper>
                <TextField
                  id='description'
                  data-testid=''
                  placeholder={t('enterComment')}
                  label={t('commentary')}
                  validate={validateString({ isNullable: true, isRequired: false, max: 500 })}
                  maxLength={1000}
                />
              </InputWrapper>
            </Grid>
          </Modal>
        )}
      </Form>
    );
  }
);
