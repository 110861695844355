import { FileGroupType } from './file';
import { Model } from './model';

export type CardFormData = Record<string, string | string[] | null | CardFormData[]>;

export interface CreateCardDto {
  entryTemplateId: number | null;
  data: CardFormData;
  modelId: number | null;
  fileProcessId: number | null;
}

export interface EditCardDto {
  entryTemplateId: number;
  data: CardFormData;
}

export type CardExportGridStatus = 'SUCCESS' | 'WARNING' | 'FAILED';

export interface CardsExportInfo {
  id: number;
  type: string;
  status: 'IN_PROGRESS' | 'NOT_STARTED' | 'COMPLETED' | 'FAILED';
  startedTime: string;
  finishedTime: string;
  errorDescription: string;
}

export enum ECardExportStatus {
  NEW = 'NEW',
  NEED_EXPORT = 'NEED_EXPORT',
  EXPORTED = 'EXPORTED',
}

export interface IFace {
  modelId: number;
  normalizedImageCorrelationId: string;
  normalizedImageUri: string;
  originalImageCorrelationId: string;
  originalImageUri: string;
  area: { h: number; w: number; x: number; y: number };
  quality: number;
}

export interface CardPhoto {
  id: number;
  path: string;
  fileProcessId: number;
  faceData: IFace[];
  isMain: boolean;
  fileGroup: FileGroupType;
  createdTime?: string;
}

type User = {
  id: number;
  isEnabled: boolean;
  name: string;
  username: string;
};

export interface ICard {
  id: number;
  createdOnDateTime: string;
  editedOnDateTime: string;
  accessLevel: string;
  entryTemplateId: number;
  accessGroups: string[];
  data: CardFormData;
  exportStatus: ECardExportStatus;
  exportResultStatus: CardExportGridStatus | null;
  faceModel: Model | null;
  voiceModel: Model | null;
  photoPath: string | null;
  audioPath: string | null;
  exportedOnDateTime: string;
  creator: User;
  editor?: User;
  totalFiles: number;
  sourceFiles: string[];
  isDuplicate: boolean;
  strField1: string;
  strField2: string;
  strField3: string;
  strField4: string;
  strField5: string;
  strField6: string;
  strField7: string;
  strField8: string;
  strField9: string;
  strField10: string;
  dateField1: string;
  dateField2: string;
  dateField3: string;
  dateField4: string;
  dateField5: string;
  isInHotlist: boolean;

  // metadata
  // lastExportResultDate
  // department
}

export type ICardsGridItem = Omit<ICard, 'creator' | 'editor' | 'audioPath'> & {
  creatorName: string;
  editorName: string;
  voicePath: string | null;
  // required for quick filters panel
  documents: string;
};
