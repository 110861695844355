import { EntryTab } from '@/types/ui.types';
import {
  allowedCardAttachedFilesFormats,
  allowedCardAudioFormats,
  allowedCardDocFormats,
  allowedCardImageFormats,
} from '../../constants';

export const getFileFormatsByTab = (tab: EntryTab): string => {
  switch (tab) {
    case 'face':
      return allowedCardImageFormats.join(', ');
    case 'voice':
      return allowedCardAudioFormats.join(', ');
    case 'documents':
      return allowedCardDocFormats.join(', ');
    default:
      return allowedCardAttachedFilesFormats.join(', ');
  }
};
