import { useEffect } from 'react';
import { UsersGrid } from '../Users/components/UsersGrid/UsersGrid';
import { useGridStore } from '@/hooks/grid/useGridStore';
import { User } from '@/types/user';
import userService from '@/services/user.service';
import { EDataGridStoreSortMode } from '@/types/grid';
import { IGroup } from '@/types/group';
import { observer } from 'mobx-react';
import { RightPanelWrapper } from '@/components/RightPanelWrapper';
import { UsersBlockHeader } from './UsersBlockHeader';
import { useGroupsPageContext } from './useGroupsPageContext';

export const UsersBlock = observer(() => {
  const { groupUsers, groups, selectedGroupsIds, isUsersLoading } = useGroupsPageContext();

  const selectedGroup: IGroup | undefined = groups.find(
    (g: IGroup) => g.id.toString() === selectedGroupsIds[0]
  );

  const { store: usersTableStore } = useGridStore<User>({
    dataProvider: (state) => userService.dataProvider(state, []),
    defaultSortState: {
      sort: EDataGridStoreSortMode.ASC,
      sortField: 'username',
    },
    withInfiniteScroll: true,
  });

  useEffect(() => {
    const resetTable = async () => {
      usersTableStore.resetChecked();

      usersTableStore.setDataProvider((state) =>
        userService.dataProvider(
          state,
          groupUsers.sort((a, b) => a.username.localeCompare(b.username))
        )
      );
      await usersTableStore.reload();
    };

    resetTable().catch((error) => console.error(error));
  }, [groupUsers, selectedGroup?.id, usersTableStore]);

  return (
    <RightPanelWrapper header={<UsersBlockHeader store={usersTableStore} />}>
      <UsersGrid store={usersTableStore} loading={isUsersLoading} />
    </RightPanelWrapper>
  );
});
