import { ROUTES } from '@/types';
import { IDictor } from '@/types/dictor';
import { getPlayerFilePathForModel } from './getPlayerFilePathForModel';

export const cardCreationFromQueryName = 'from';

export const createCardEditLink = ({ id, from }: { id: number; from: string }) => {
  return ROUTES.MULTIPASS_CARD_EDIT.replace(':id', String(id)).concat(
    `?${cardCreationFromQueryName}=${from}`
  );
};

export const createCardCreationLink = ({
  modelId,
  dictor,
  fileProcessId,
}: {
  modelId: number;
  dictor: IDictor;
  fileProcessId: number;
}) => {
  return ROUTES.MULTIPASS_CARD_CREATION.concat(
    `?modelId=${modelId}&fileProcessId=${fileProcessId}&fileUrl=${getPlayerFilePathForModel(
      dictor
    )}&${cardCreationFromQueryName}=${ROUTES.MULTIPASS_LOAD_FILE}`
  );
};
