import { HeaderLeftIconTextWrapper } from '@/components/HeaderLeftIconTextWrapper';
import { useTranslation } from 'react-i18next';
import { PermContactCalendar } from '@mui/icons-material';

export const InteractiveCardPageHeader = () => {
  const { t } = useTranslation();

  return (
    <HeaderLeftIconTextWrapper icon={PermContactCalendar} text={t('multipass.interactiveCard')} />
  );
};
