import { format, isValid } from 'date-fns';
import { FieldValues } from 'react-hook-form';
import { DatePickerFieldValue, FormReturn } from 'stc-ui-kit';

export const formatDateInputValue =
  ({ form, fieldId }: { form: FormReturn<FieldValues>; fieldId: string }) =>
  (value: DatePickerFieldValue) => {
    if (!value) return;

    if (value.length === 10 && value.includes('-')) return;

    const date = new Date(value);

    if (!isValid(date)) return;

    form.setValue(fieldId, format(date, 'yyyy-MM-dd'), {
      shouldDirty: true,
      shouldValidate: !form.formState.isValid,
    });
  };
