import { ICard } from '@/types/card';
import { showInfoNotification } from '@/utils/notifications';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const useModelsChangeNotifications = ({ cardData }: { cardData: ICard | null }) => {
  const { t } = useTranslation();
  const prevFaceModel = useRef(cardData?.faceModel);
  const prevVoiceModel = useRef(cardData?.voiceModel);
  const prevCardId = useRef(cardData?.id);

  useEffect(() => {
    if (prevFaceModel.current?.id === cardData?.faceModel?.id) return;
    if (cardData?.id !== prevCardId.current) return;

    if (prevFaceModel.current?.id && !cardData?.faceModel?.id) {
      showInfoNotification({ header: t('messages.faceModelDeleted') });

      return;
    }

    showInfoNotification({ header: t('messages.faceModelEdit') });
  }, [cardData?.faceModel?.id, t, cardData?.id]);

  useEffect(() => {
    if (prevVoiceModel.current?.id === cardData?.voiceModel?.id) return;
    if (cardData?.id !== prevCardId.current) return;

    if (prevVoiceModel.current?.id && !cardData?.voiceModel?.id) {
      showInfoNotification({ header: t('messages.audioModelDeleted') });

      return;
    }

    showInfoNotification({ header: t('messages.audioModelEdit') });
  }, [cardData?.voiceModel?.id, t, cardData?.id]);

  useEffect(() => {
    prevFaceModel.current = cardData?.faceModel;
  }, [cardData?.faceModel]);

  useEffect(() => {
    prevVoiceModel.current = cardData?.voiceModel;
  }, [cardData?.voiceModel]);

  useEffect(() => {
    prevCardId.current = cardData?.id;
  }, [cardData?.id]);
};
