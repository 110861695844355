import Slider, { Settings } from 'react-slick';
import { default as NextArrowSvg } from 'assets/icons/carousel/next.svg';
import clsx from 'clsx';
import { makeStyles } from '@/hooks/makeStyles';
import React, { PropsWithChildren, useState } from 'react';
import { appColors } from '@/Providers/ThemeProvider/colors';

const useStyles = makeStyles()(() => ({
  sliderRoot: {
    height: '100% !important',
    position: 'relative',
    '& .slick-track': {
      height: '100%',
    },
    '& .slick-list': {
      height: '100% !important',
    },
    '& .slick-slide': {
      height: '100%',
      '> div:first-of-type': {
        height: '100%',
      },
    },
    '& .slick-arrow.slick-next, & .slick-arrow.slick-prev': {
      display: 'none !important',
    },
  },
  arrowContainer: {
    position: 'absolute',
    bottom: '16px',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    zIndex: 3,
  },
  arrow: {
    height: '24px',
    width: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:before': {
      fontSize: 'initial',
      content: `url(${NextArrowSvg})`,
    },
  },
  nextArrow: {
    transform: 'rotate(0deg)',
    color: appColors.grey200,
  },
  prevArrow: {
    transform: 'rotate(180deg)',
  },
  currentIndex: {
    backgroundColor: appColors.grey800,
    borderRadius: '2px',
    padding: '2px 4px',
    color: appColors.white,
  },
}));

interface Props extends PropsWithChildren {
  sliderProps?: Settings;
  sliderRef: React.RefObject<Slider>;
}

const Arrow = ({
  className,
  onClick,
  direction,
}: {
  className?: string;
  onClick?: () => void;
  direction: 'next' | 'prev';
}) => {
  const { classes } = useStyles();

  return (
    <div
      className={clsx(
        classes.arrow,
        {
          [classes.nextArrow]: direction === 'next',
          [classes.prevArrow]: direction === 'prev',
        },
        className
      )}
      onClick={onClick}
    />
  );
};

export const StyledCarousel: React.FC<Props> = ({ children, sliderProps, sliderRef }) => {
  const { classes } = useStyles();
  const [currentSlide, setCurrentSlide] = useState(sliderProps?.initialSlide || 0);

  const handlePrevClick = () => {
    if (!sliderRef) return;
    sliderRef.current?.slickPrev();
  };

  const handleNextClick = () => {
    if (!sliderRef) return;
    sliderRef.current?.slickNext();
  };

  const settings: Settings = {
    ...sliderProps,
    beforeChange: (_, next) => setCurrentSlide(next),
  };

  return (
    <div className={classes.sliderRoot}>
      <Slider
        ref={sliderRef}
        {...settings}
        dots={false}
        infinite={false}
        slidesToShow={1}
        slidesToScroll={1}
        adaptiveHeight
        swipeToSlide={false}
        draggable={false}
        speed={0}
        nextArrow={<Arrow direction='next' />}
        prevArrow={<Arrow direction='prev' />}
        className={clsx(classes.sliderRoot, sliderProps?.className)}
      >
        {children}
      </Slider>
      <div className={classes.arrowContainer}>
        <Arrow direction='prev' onClick={handlePrevClick} />
        <span className={classes.currentIndex}>{`${currentSlide + 1}/${React.Children.count(
          children
        )}`}</span>
        <Arrow direction='next' onClick={handleNextClick} />
      </div>
    </div>
  );
};
