import { ModelQuality } from '@/types/dictor';

export const getModelQuality = (qualityNumber: number): ModelQuality => {
  let quality: ModelQuality = 'GOOD';

  if (qualityNumber <= 0.8) {
    quality = 'NORMAL';
  }

  if (qualityNumber < 0.5) {
    quality = 'BAD';
  }

  return quality;
};
