import { CustomTypeFieldDescriptor, FieldDescriptor } from '@/types/cardTemplate';
import { ConditionTextField } from 'stc-ui-kit';
import { StringFieldConditions } from '@/modules/multipass/FilingCabinet/components/SideFilter/constants';

export const renderStringFilterField = (
  field: FieldDescriptor | CustomTypeFieldDescriptor,
  label: string,
  placeholder: string
) => {
  return (
    <ConditionTextField
      key={field.id}
      id={field.id}
      label={label}
      conditions={StringFieldConditions}
      isClearable
      placeholder={placeholder}
      data-testid=''
    />
  );
};
