import { RadioGroupField } from 'stc-ui-kit';
import { t } from 'i18next';
import { isInHotlistId } from '../constants';

export const renderHotListFilterField = () => {
  return (
    <RadioGroupField
      isEmptyStateEnabled
      data-testid=''
      id={isInHotlistId}
      label={t('multipass.isInHotList')}
      items={[
        { id: 'true', label: t('yes') },
        { id: 'false', label: t('no') },
      ]}
      className='radioGroup'
    />
  );
};
