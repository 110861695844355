import { useNavigate } from 'react-router-dom';
import { createCardEditLink } from '@/modules/multipass/utils/cardCreationLinks';
import { ROUTES } from '@/types';
import { cardsService } from '@/services/cards.service';

export const useCreateNewEntryFromFace = () => {
  const navigate = useNavigate();

  const createNewEntry = async ({
    modelId,
    fileProcessId,
  }: {
    modelId: number;
    fileProcessId: number;
  }) => {
    const { res } = await cardsService.createCard({
      entryTemplateId: null,
      data: {},
      modelId: modelId,
      fileProcessId: fileProcessId,
    });

    if (!res) return;

    navigate(createCardEditLink({ id: res.id, from: ROUTES.MULTIPASS_FILLING_CABINET }));
  };

  return { createNewEntry };
};
