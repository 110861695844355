import { EFileProcessStatus } from '@/types/file';
import { getStatusIcon } from './utils/getStatusIcon';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ECardExportStatus } from '@/types/card';

export function StatusWithIcon({
  status,
  isCard = false,
}: {
  status: EFileProcessStatus | ECardExportStatus;
  isCard?: boolean;
}) {
  const { t } = useTranslation();

  const statusText = useMemo(() => {
    return isCard
      ? t([`cards.exportStatus.${status as ECardExportStatus}`, 'cards.exportStatus.unknown'])
      : t([`attachedFileStatus.${status as EFileProcessStatus}`, 'attachedFileStatus.unknown']);
  }, [status, t, isCard]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {getStatusIcon({ status, isCard })}
      <span style={{ whiteSpace: 'nowrap' }}>{statusText}</span>
    </div>
  );
}
